@import '../../../../../../src/ui/style/colors';

.EditableCard {
  display: flex;

  button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $color-background-mid;
    cursor: pointer;
    font-size: 1rem;
    border: none;
    outline: none;
    padding: 1rem;
    color: $color-black-100;

    &:hover {
      background: $color-background-dark;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $color-black-100;
    }

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.mainButton {
  display: flex;
  flex: 1;
  text-align: left;
  position: relative;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font-size: inherit;
  outline: none;
}

.icon {
  display: flex;
  align-items: center;
}
