.masthead-dashboard-options {
  margin: auto 1rem;

  .masthead-dashboard-settings {
    @include animateDropMenu(top);

    ul {
      top: calc(100% + 1.5rem);
      min-width: 160px;
    }
  }

  .masthead-dashboard-sharing {
    @include animateDropMenu(top left);
  }
}
