@import '../../../style/spacing', '../../../style/colours',
  '../../../style/widget';

$numberTextLineHeight: 1.2em;

.wrapper {
  &::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: var(--widget-bottom-border, none);
  }

  display: flex;

  @include variable(color, --text-color, $text-colour);

  .primaryNumber {
    white-space: nowrap;
  }

  .container {
    box-sizing: border-box;
  }

  .primaryContainer {
    display: flex;
    align-items: center;
  }

  .primaryAlignment {
    // offset the widget title to make the primary
    // visualisation vertically centered
    padding-bottom: $widget-header-height;
    width: 100%;
  }

  .primaryContainer {
    flex: 1.5;
    padding: 0;
    @include widgetSpacing(padding-left padding-right);
    position: relative;
  }

  .primaryStat {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    line-height: 1;
  }

  .label,
  .comparisonLabel {
    padding-top: 0.3rem;
    height: $numberTextLineHeight * 2;
    line-height: $numberTextLineHeight;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    @include widgetSpacing(left right);
  }

  .labelInput,
  .comparisonLabelInput {
    opacity: 0;
  }

  .primaryAlignment:hover .labelInput,
  .secondaryStat:hover .comparisonLabelInput {
    opacity: 0.5;
  }

  .sparklineValue {
    margin-left: 1rem;
  }

  .goalContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: var(--goal-display, block);
  }

  &.previewLayout {
    flex: 1 1 0;
    height: 100%;
    position: relative;

    .primaryContainer {
      align-items: flex-start;
    }

    &.horizontal {
      &.hasGoalComparison .container {
        align-self: center !important;
      }

      .container {
        min-height: 170px;
      }
    }
  }

  &.hasComparison {
    .primaryContainer {
      align-items: flex-start;
    }
  }

  &.horizontal {
    .container {
      display: flex;
      flex-direction: row;
      align-self: center;
      min-height: 0;
      min-width: 100%;
    }

    .primaryStat {
      overflow: hidden;
    }

    .comparisonValue {
      flex-direction: row;
      align-items: center;

      padding: 0;
      @include widgetSpacing(padding-left padding-right);
    }

    .sparkline {
      align-self: stretch;
      flex-direction: row;
      align-items: center;
      flex: 1;

      padding: 0;
      @include widgetSpacing(padding-left padding-right);
      overflow: hidden;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .previewLayout .comparisonValue {
      max-width: 620px;
    }

    &.hasAnyText {
      .sparkline {
        padding-bottom: $numberTextLineHeight * 2;
        padding-top: $numberTextLineHeight;
      }

      .label {
        position: static;
      }
    }

    &.slimline {
      .container {
        height: 100%;
        margin-top: 0;
      }

      .primaryAlignment {
        display: flex;
        align-items: flex-end;
        padding-bottom: 65px;
      }

      .label {
        height: auto;
        margin-left: 1em;
        position: static;
      }

      &.hasComparison {
        .primaryContainer {
          align-items: center;
        }
      }
    }

    // if we have a goal we don't show the comparison
    // (even though the .hasComparison class is applied).
    &.hasComparison:not(.hasGoalComparison) {
      padding-bottom: 56px; // to make it appear centered

      // Ensure that the first line of text appears centered
      &.hasAnyText .container {
        margin-top: $numberTextLineHeight;
      }

      &.small .primaryAlignment {
        width: 230px;
      }

      &.medium .primaryAlignment {
        width: 470px;
      }

      &.large .primaryAlignment {
        width: 700px;
      }

      .container {
        margin-top: 1rem;
      }

      .primaryContainer {
        flex: 0 0 auto;
        display: flex;
        align-items: flex-end;
        box-sizing: border-box;
        margin-right: 10px; // compensate for gutter
        padding: 0;
        @include widgetSpacing(padding-left);
      }

      .secondaryStat {
        align-items: flex-end;
      }

      .primaryAlignment {
        padding-bottom: 0;
      }
    }

    &.hasGoalComparison:not(.slimline) {
      .container {
        align-self: flex-start;
        margin-top: 0;
      }
    }

    &.hasHorizontalLabelSupport {
      flex-direction: row;

      .primaryAlignment {
        display: flex;
        align-items: flex-end;
      }

      .label {
        margin-bottom: 0.1em;
        margin-left: 1em;

        // Ensure the label is base aligned
        // with the primary value
        height: auto;
        max-height: $numberTextLineHeight * 2;
      }
    }

    &.hasHorizontalLabelSupport,
    &.hasAnyText {
      .label,
      .comparisonLabel {
        position: initial;
      }
    }
  }

  &.vertical {
    .container {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      min-width: 100%;
    }

    .hasAnyText {
      .label,
      .comparisonLabel {
        position: initial;
      }
    }

    .secondaryStat {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
    }

    .comparisonValue {
      $sidePadding: calc(2px + #{var(--widget-side-padding)});

      padding: 0 16px 14px 16px;
      padding-right: $sidePadding;
      padding-left: $sidePadding;
      padding-bottom: var(--widget-side-padding);
    }

    .sparkline {
      padding-top: 0;
      @include widgetSpacing(padding-left padding-right padding-bottom);

      flex-direction: row;
      align-items: center;
      flex: 1;
    }

    &.hasAnyText {
      .label {
        position: static;
      }
    }
  }
}

.noDataLabel {
  color: var(--input-placeholder-color);
}

.secondaryStat {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.comparisonValue {
  composes: secondaryStat;
}

.sparkline {
  composes: secondaryStat;
}
