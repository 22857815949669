@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.toastInner {
  display: flex;
  padding: 9px 4px 4px 2px;
}

.toastWrapper {
  @include fontMedium;
  cursor: pointer;
  position: relative;
  border: 1px solid $color-grey-40;
  border-radius: 6px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}

.toastText {
  display: flex;
  margin-left: 8px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.toastWrapper {
  cursor: pointer;
}

.statusText {
  font-size: 1rem;
}

.progressText {
  color: $color-grey-80;
  font-size: 0.75rem;
  line-height: 1.5;
}

.toastProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}
