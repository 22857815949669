@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';

.header {
  @include fontMedium;
  color: $color-black-100;
  width: 100%;
  display: flex;
}

.headerIcon {
  margin-right: 0.5rem;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-black-70;
}
