@import '../../components/menu/drop-menu/animation', '../../../style/colours',
  '../../../src/ui/style/typography', '../../../style/menu/variables';

.userInitials {
  @include fontBold;
  width: 32px;
  height: 32px;

  background-color: rgba(255, 255, 255, 0.4);
  color: #fff;
  border-radius: 16px;

  text-transform: uppercase;
  text-align: center;

  line-height: 32px;

  &:focus,
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.accountMenu {
  @include animateDropMenu(top right);
  display: inline-block;
  vertical-align: middle;
  margin-right: -1rem;
  padding-right: 1rem;
  position: relative;

  &.notification::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: -0.1rem;
    right: 0.8rem;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $error-red;
    animation: scale-in 0.6s cubic-bezier(0.695, -0.05, 0.265, 1.55);
  }
}

.button {
  padding: 0;
  margin: 0;
  flex: 1;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  max-width: 100%;
}

.list {
  @include fontMedium;
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: 150px;
  visibility: hidden;
  overflow: hidden;
  transform: scale(0.6);
  transform-origin: top;
  transition: $shortFade transform $easeOutQuint;
  height: auto;
  border: 1px solid $drop-menu-border-colour;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &.open {
    visibility: visible;
    transform: scale(1);
  }
}

.link {
  display: block;
  margin: 0;
  margin: -0.5rem -1rem;
  padding: 1rem;

  border: none;
  background-color: transparent;
  color: inherit !important;
  cursor: pointer;

  font: inherit;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus,
  &:visited,
  &:visited:hover {
    background: #fff;
    color: inherit;
  }
}

.logOutForm {
  margin: -0.5rem -1rem;

  button {
    display: block;
    padding: 1rem;
    width: 120%;

    border: none;
    background-color: transparent;
    color: inherit !important;
    cursor: pointer;

    font: inherit;
    text-decoration: none;
    outline: none;
    text-align: left;

    &:hover,
    &:focus,
    &:visited,
    &:visited:hover {
      background: #fff;
      color: inherit;
    }
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
