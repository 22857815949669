@import '../../../style/colours';
@import '../../../src/ui/style/colors';
@import '../../../style/shared/animations';
@import '../../../style/vendor/font-awesome-variables';

.logo {
  position: relative;
  z-index: 10000; // above dashboard picker
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  border: none;
  overflow: hidden;
  outline: none;
  transition: $buttonFade opacity $easeOutQuint;

  img {
    width: 32px;
    height: 32px;
    border-radius: 3px;
  }

  &.link {
    &:link {
      border: none;
    }

    &:focus,
    &:hover {
      opacity: 0.9;
      border: none;
      background-color: $color-geckoboard-green;

      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        color: $color-black-100;
        @include fontAwesome;
        content: fa-content($fa-var-angle-left);
        font-size: 18px;
        font-weight: 800;
      }

      img {
        display: none; // hide the logo on hover and show the "<" instead
      }
    }
  }
}
