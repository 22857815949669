.sprv-wrapper {
  background-color: white;
  // prevent the viewer as appearing "selected" by the browser
  user-select: none;

  .sprv-grid-container {
    position: relative;
    clear: both;
    overflow: hidden;
    cursor: default;
  }

  .sprv-scrollable-selection {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }

  .sprv-headers {
    .sprv-header {
      position: absolute;
      background-color: $lightest-day-grey;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      &.sprv-header-col {
        height: 28px;
      }

      &.sprv-header-row {
        width: 40px;
      }
    }
  }

  .sprv-row-selection, .sprv-col-selection {
    position: absolute;
    background-color: $mid-morning-grey;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    z-index: 3;
  }

  .sprv-grid-selection {
    position: absolute;
    background-color: rgba(141, 198, 63, 0.15);
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    &.reference-selection {
      background-color: rgba(227, 228, 229, 0.4);
    }

    &.has-border {
      border: 1px solid #8dc63f;
      margin-right: 1px;
      z-index: 4;
    }

    &.highlight {
      background-color: lighten($visualisation-green, 10);
    }
  }
  .sprv-native-scroll {
    // fix IE10 not rendering empty, transparent divs
    background: -ms-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0));
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    cursor: default;
    z-index: 5;
    // this allows user to "mouse down" inside this element
    user-select: auto;
  }
  .sprv-canvas {
    position: relative;
    z-index: 3;
  }
  /** http://stackoverflow.com/a/14150577/2259520 **/
  .sprv-native-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  .sprv-native-scroll::-webkit-scrollbar-track {
    background-color: lighten($lightest-day-grey, 2%);
  }

  /* Handle */
  .sprv-native-scroll::-webkit-scrollbar-thumb {
    background-color: $mid-morning-grey;
  }
  .sprv-native-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(194, 194, 194, 1);
  }
}
