@import '../../../../../../../../style/colours',
  '../../../../../../../../style/shared/animations',
  '../../../../../../../../style/vendor/font-awesome-variables';

.item {
  transition: 0.3s all;
  width: 48px;
  height: 48px;
  opacity: 0.4;
  list-style-type: none;

  img {
    border-radius: 10px;
    width: 100%;
    display: block;
  }

  &:hover {
    opacity: 1;
  }

  &:global(.selected) {
    opacity: 1;
  }
}

.extendedItem {
  display: flex;
  flex-direction: row;

  transition: 0.3s all;
  opacity: 1;
  border-bottom: 1px solid $pinstripe-grey;

  img {
    width: 48px;
    height: 48px;
  }
}

.imgWrapper {
  position: relative;
  display: inline-block;

  img {
    display: block;
  }
}

.notConnected {
  &:hover {
    .imgWrapper {
      &::before {
        position: absolute;
        color: #fff;
        @include fontAwesome;
        content: fa-content($fa-var-plus);
        left: 17px;
        top: 15px;
      }

      position: relative;
      background: $ui-green;
      border-radius: 10px;
      opacity: 1;

      img {
        opacity: 0;
      }
    }

    .connectBtn {
      opacity: 1;
    }
  }

  .connectBtn {
    opacity: 0;
  }
}

.detail {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.serviceName {
  flex: 1;
  padding-left: 1rem;
}

.connectBtn {
  @include animateButtonHover;

  padding: 0.5rem 1rem;

  color: white;
  background-color: $ui-green;
  border: none;
  border-radius: 3px;

  cursor: pointer;

  &:hover {
    background-color: darken($ui-green, 10%);
  }
}
