@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';

.InputWithLabel {
  display: flex;
  position: relative;
  flex: 1;
  font-size: 16px;
  line-height: 1.8;
  border-radius: 6px;

  label {
    @include fontMedium;

    align-items: center;
    display: flex !important;
    height: 41px;
    padding: 0 12px;
    float: left;
    min-width: 40%;
    color: $color-grey-100;
  }

  .field {
    display: flex;
    flex: 1;

    input {
      @include fontLight;

      font-size: 1rem;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid $color-background-dark;
      border-radius: 6px;
      margin-left: 1px;
      padding: 9px 0px 9px 15px;
      background-color: #fff;

      &:focus {
        outline: none;
        border-color: $color-black-100 !important;
      }
    }
  }
}
