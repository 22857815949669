.ReverseComparisonControl {
  display: flex;
  align-items: center;

  .label {
    flex: 2;
    font-size: 0.875rem;
    margin-left: 0.75rem;
  }

  .icon {
    padding: 0 4px;
  }
}
