.non-graphql-dashboard {
  .polymorphic-widget.widget-body {
    padding: 0;

    .chart, .barchart, .leaderboard {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .widget-message {
      padding: 16px;
    }
  }
}
