@import '../style/colors';

:local(.list) {
  list-style: none;
  padding: 0;
  margin: 0;

  width: 100%;
  box-sizing: border-box;

  border-radius: 6px;
  border: 1px solid $color-black-20;
}

:local(.scrollable) {
  max-height: 100%;
  overflow-y: auto;
}
