.titleContainer {
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-between;
   align-items: baseline;
}
.aggregates {
  min-width: 114px;
}

.dropdown {
  width: 100%;
  overflow: hidden;
}
