.minMax {
  display: flex;
}

.min {
  margin-right: 10px;
}

.min,
.max {
  flex: 1;
}
