.container {
  legend {
    display: none;
  }

  fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.row {
  width: 48%;
}

