@import '../../style/colors';

:local(.TreeSelect) {
  display: inline-flex;
  overflow: hidden;
  width: 100%;
}

:local(.noResults) {
  padding: 16px 20px;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  position: relative;
  line-height: 1.3;
  color: $color-grey-100;
}
