@import '../../../../style/colours', '../../../../style/shared/animations',
  '../../../../style/menu/variables', '../../../../src/ui/style/colors',
  '../../../../src/ui/style/typography';

.dropMenu {
  @include fontLight;
  align-self: stretch;
  position: relative;
  display: inline-block;
  outline: none;
  display: flex;

  &:focus {
    border: solid 1px $color-black-100;
  }
}

.button {
  padding: 0;
  margin: 0;
  flex: 1;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;

  font: inherit;
  text-decoration: none;

  max-width: 100%;

  &:focus,
  &:hover {
    outline: none;
    opacity: 0.5;
  }
}

.noHover {
  &:focus,
  &:hover {
    opacity: 1;
  }
}

.list {
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 0;
  left: 0;
  overflow: hidden;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.animate {
    transform: scale(0.6);
    transform-origin: top;
    transition: $shortFade transform $easeOutQuint;
  }

  &.open {
    height: auto;
    z-index: 9999;
    pointer-events: initial;

    visibility: visible;
    opacity: 1;
    transform: scale(1);

    border-width: 1px;
    border-style: solid;
    border-color: $drop-menu-border-colour;
    border-radius: 3px;

    &.anchored {
      border-width: 0 1px 1px;
      border-radius: 0 0 3px 3px;
    }

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &.topRight {
      border-width: 1px;
      transform-origin: top left;

      &.anchored {
        border-width: 1px 1px 1px 0;
        border-radius: 0 3px 3px 0;
      }
    }
  }

  &.bottomLeft {
    transform-origin: top right;
    top: 100%;
    left: 100%;
    transform: translateX(-100%) scale(0.6);

    &.open {
      transform: translateX(-100%) scale(1);
    }
  }

  &.topLeft {
    transform-origin: top right;
    right: 100%;
    left: auto;
    transform: scale(0.6);

    &.open {
      transform: scale(1);
    }
  }

  &.auto {
    max-height: 256px;
  }
}
