@import '../../../src/ui/style/colors';

.validationMessage {
  font-size: 14px;
  display: block;
  clear: both;
  padding: 4px 0 18px;
  color: $color-negative;
}

.options {
  background: white;
  padding: 6px;

  > div {
    display: inline-block;
    margin-left: 15px;
  }
}
