@import '../../../style/colours';

.addDashboardModal {
  width: 560px;
  max-width: 100%;

  h3 {
    margin-bottom: 1rem;
  }
}

.groupSection {
  margin: 1rem 0;

  h3 {
    margin-bottom: 1rem;
  }
}

.toggleSection {
  background: $light-grey;
  padding: 1em;
  border-radius: 3px;
  position: relative;
}

.toggle {
  margin: 1em 0 0;
}

.illustration {
  display: block;
  margin: -2rem auto 2rem;
}

.betaTag {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  /*
   * The status label component adds
   * unusual positioning to itself
   * This removes all of that
   */
  span {
    margin-right: 0px;
    transform: none;
    display: block;
  }
}

.submitLabel {
  whitespace: nowrap;
}

.submitSpinner {
  float: left;
  margin-right: 0.5rem;
  margin-top: 3px;
}
