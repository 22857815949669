@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.datasetsConnector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.footer {
  flex: 0 0 auto;

  text-align: left;
  line-height: 1.25;
  background-color: #fff;

  color: $color-black-100;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    @include fontBold;

    color: $color-black-100;
    text-decoration: underline;
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  padding: 1rem;
}
