@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';
@import '../../../../style/vendor/font-awesome-variables';

.InviteView {
  color: $color-black-100;
  background-color: $color-background-mid;
  position: relative;
}

.featureGate {
  color: $color-black-100;
  padding: 0 1rem 1rem 1rem;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
  }

  a {
    @include fontMedium;
    color: $color-black-100;
    cursor: pointer;
    text-decoration: underline;
  }
}

.featureGateTitle {
  @include fontBold;
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.featureGateContent {
  @include fontLight;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

.featureGateAction {
  margin: 1rem auto 0 auto;
}

.featureGateSupport {
  margin-top: 1.5rem;
}

.muted {
  @include fontLight;
}

.error {
  color: $color-negative;
}

.noInvitesSentError {
  margin: 20px;

  h3 {
    &::before {
      @include fontAwesome;
      content: '\f071';
      font-size: 1rem;
      font-weight: $fa-weight-solid;
      margin-left: -25px;
      position: absolute;
      color: $color-negative;
    }

    color: $color-negative;
  }

  p {
    margin-top: 5px;
  }
}

.emailError {
  font-weight: bold;
}
