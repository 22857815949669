@import '../../../../../../style/colours';

.addSeriesButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.label {
  display: inline-block;
  color: $color-grey-80;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}
