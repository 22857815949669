@import '../../../style/colours';
@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/shared/link';

.defaultDataSourceConnector {
  @include fontLight;

  display: flex;
  flex-direction: column;
  height: 100%;

  a {
    @include fontBold;

    color: $color-black-100;
    font-size: 1rem;
    text-decoration: underline;
  }

  fieldset {
    margin-bottom: 0;
  }

  legend {
    font-size: 0.825rem;
    font-weight: 500;
    margin-bottom: 8px;
  }

  :global .form-item {
    min-height: 0;
    margin-bottom: 1rem;

    &.invalid {
      margin-bottom: 0px;
    }
  }
}

.header {
  flex-shrink: 0;

  h3 {
    @include fontMedium;

    font-size: 1.25rem;
  }
}

.content {
  flex: 1;
  overflow: scroll;
  padding-bottom: 2rem;
}

.footer {
  flex-shrink: 0;
}

.protection {
  text-align: left;
}

.protectionMessage {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;

  a {
    border: none !important;
    text-decoration: underline;

    &:after {
      display: none !important;
    }
  }
}

.protectionIcon {
  position: absolute;
  font-size: 1.2rem;
  left: 0;
  top: -3px;

  &.lock {
    left: 3px;
  }
}

.error {
  padding: 1rem 4rem 0;
}
