@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';

.visualiseWrapper {
  position: relative;
  overflow: hidden;

  &:before {
    display: none;
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba($color-background-mid, 0.4);
    z-index: 3;
  }

  &:hover {
    .visualiseWrapperMessage {
      opacity: 1;
    }
  }
}

.visualiseWrapperMessage {
  display: none;
  opacity: 0;

  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 20px;
  text-align: center;
  line-height: 1.4;
  height: 190px;
  width: 190px;

  transition: opacity 0.2s;

  @include fontBold;

  background-color: rgba($color-background-mid, 0.8);
  z-index: 4;
}

.locked {
  &:before {
    display: block;
  }

  .visualiseWrapperMessage {
    display: block;
  }
}

.retrievingWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.retrieving {
  font-size: 1.6rem;
  margin: 0 auto;
  text-align: center;
  width: 55%;
}

.retrievingMessage {
  display: inline-block;
  line-height: 1.3;
  margin-bottom: 3rem;
}
