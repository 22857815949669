@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../src/ui/style/colors';

.dropdownHint {
  position: relative;
  width: 100%;
  padding-right: 24px;
  box-sizing: border-box;

  .caret {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 24px;
    padding: 0 0 8px 0;
    margin: 0;
    display: block;
    box-shadow: 0px -1px $color-grey-40 inset;
    text-align: center;
    box-sizing: content-box;
    cursor: pointer;
    color: $color-black-70;
    font-size: 12px;
    appearance: none;
    border: none;
    outline: none !important;
    background: none;
  }

  & input[type='text'] {
    &:hover {
      & + .caret {
        box-shadow: 0px -1px $color-black-100 inset;
        color: $color-black-100;
      }
    }

    &:focus {
      & + .caret {
        box-shadow: 0px -2px $color-black-100 inset;
        color: $color-black-100;
      }
    }
  }
}
