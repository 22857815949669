@import "../../../../../style/colours";

.sparkline {
  width: 100%;
  height: 100%;

  fill: none;
  @include variable(stroke, --primary-visualisation-color, $blue-summer-sky);
  stroke-linecap: round;
  stroke-linejoin: round;
}
