@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.loading {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BauhausConfigForm {
  @include fontLight;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.panelContent {
  height: calc(100% - var(--footer-height));
  overflow-y: scroll;
  overscroll-behavior: none;
}

.saveCancelButtons {
  min-width: 280px;

  button {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  z-index: 1;
  border-bottom: 1px solid $color-black-20;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.header,
.footer,
.contentInner {
  position: relative;
}

.content {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;

  padding-bottom: 0px;
  transition: padding 0s linear;
  transition-delay: 500ms;

  .focused & {
    padding-bottom: 300px;
    transition-delay: 0ms;
  }
}

.primaryControls {
  padding: 4px 28px 14px;
  background: $color-grey-5;
  border-bottom: 1px solid $color-black-20;
}

.secondaryControls {
  padding: 14px 28px 28px;
}

.blurOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: background-color 0.2s ease;
  pointer-events: none;

  .focused & {
    background-color: rgba($color-black-100, 0.7);
  }
}

.clickPrevent {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
