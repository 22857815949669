.l-widget-type-list {
  max-width: $wide;
  min-width: $mobile;
  margin: 0 auto;

  .service-header {
    margin: 20px;
  }

  .widget-type {
    margin: 20px;

    .description {
      padding-right: 15px;
    }
  }

  @include mq($from: desktop) {
    .widget-type {
      padding: 15px 20px;
      margin: 0;
      width: 50%;
      float: left;
    }

    .request-integration {
      clear: both;
      padding: 20px 30px;
    }
  }

  @include mq($from: wide) {
    .widget-type {
      width: 33.33%;
    }
  }
}
