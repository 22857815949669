.overflowContent {
  overflow-y: auto;

  padding: 1rem 1rem 0;
}

.maxHeight {
  max-height: 100%;
  overflow: auto;
}

.flex {
  flex: 1;
  display: flex;
  overflow: auto;
}

.visualizationTypeLabel {
  margin-bottom: 14%;
}
