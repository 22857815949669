// Duplicated in users-list-item-component
.usersListItemName {
  // This aims to be wide enough to show names without truncation.
  width: 25vw;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4; // required to avoid decenders being cut off.
}

.lastColumn {
  width: 180px;
}
