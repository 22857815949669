.icon__percentage,
.icon__number {
  font-size: 0.6875rem;

  .icon__sort {
    margin-left: 2px;
  }
}

.icon__default {
  font-size: 0.875rem;
}
