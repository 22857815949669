.modal {
  max-width: 430px;
  min-width: 350px;
}

.heading {
  max-width: 260px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 2rem 0 2rem;
}

.serviceIcon {
  margin-bottom: 1rem;
}

.complete {
  text-align: center;
}

.okButton {
  margin-top: 2rem;
}

.progress {
  width: 80%;
  margin: 2rem auto 0 auto;
}

.progressText {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 1rem auto 0 auto;
}

.sectionError {
  text-align: center;
}
