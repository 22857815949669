@import "../../../../style/colours";

.innerModal {
  padding: 20px 34px;
  background-color: $light-grey;
}

.table {
  margin: 0 -34px;
}

.header {
  display: flex;
  align-items: baseline;
  margin: 8px 0 18px;

  > * {
    margin-right: 2ex;
  }
}

.metadata {
  font-size: 14px;
  color: $mid-grey;
}

.heading {
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 500;
}
