.monitoring-visualisation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .status {
    text-transform: capitalize;
    padding-top: 5px;
    margin-bottom: 24px;
  }

  .unreported {
    font-size: 36px;
    margin-bottom: 36px;
  }

  .server-times {
    overflow: hidden;
    display: flex;
  }

  .server-time {
    &:first-of-type {
      padding-right: 1em;
    }

    .label {
      margin: 0 0 6px;
    }

    .t-main {
      margin-bottom: 1em;
    }
  }

  .isLargeTemplate {
    .status {
      margin-bottom: 48px;
    }

    .unreported {
      font-size: 75px;
      margin-bottom: 75px;
    }

    .server-time {
      width: 50%;
    }
  }
}

