.workflowList {
  text-align: left;
  padding: 20px;
}

.header {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
  opacity: 0.5;
}
