@import '../../../style/spacing';

.container {
  @include widgetSpacing(padding);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tablePositioner {
  height: 100%;
}

.labelContainer {
  flex: 1;
  padding-right: 14px;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.table {
  width: 100%;
  height: 100%;

  tr {
    &:last-of-type {
      .funnel,
      .values {
        border-bottom: none;
      }
    }
  }

  td {
    color: var(--text-color);
  }

  :global(.tv-mode) & {
    font-size: 1.32rem;
  }
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  line-height: 1.2;
}

.bar {
  background: #13699c;
  float: right;
}

.funnelCell {
  width: 100%;
  position: relative;
}

.funnel {
  border-bottom: 1px solid var(--chart-axis-color);

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
}

.values {
  border-bottom: 1px solid var(--chart-axis-color);
  display: flex;
  align-items: center;
  height: 100%;
}

.value,
.percent {
  flex: 1;
  padding-left: 14px;
  white-space: nowrap;
  text-align: right;
}

.percent {
  opacity: 0.6;
  font-size: 1.125rem;
}

.small {
  .values {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .value,
  .percent {
    flex: none;
    font-size: 1rem;

    :global(.tv-mode) & {
      font-size: 1.125rem;
    }
  }

  .bar {
    :global(.tv-mode) & {
      display: none;
    }
  }
}
