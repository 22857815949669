.previewItem {
  width: 100%;
  opacity: 0.8;
  transform: rotate(2deg) translateZ(0); // trigger 3d transforms to avoid some weird aliasing artefacts in Chrome
}

:global(.isDragging) .sortableInner {
  opacity: 0.3;
}

.handle {
  display: inline-flex;
  margin-right: 0.6em;
  margin-left: -4px;
  padding-top: 5px;
}
