.frame, .fullSize, .scaled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.frame {
  overflow: hidden,
}

.scaled {
  transform-origin: top left;
}
