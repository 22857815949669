@import '../../../../../../../src/ui/style/colors';
@import '../../../../../../../src/ui/style/typography';

.ComparisonOption {
  border-radius: 6px;
  border: 2px solid $color-grey-40;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .radioHeader {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0 0.75rem 0 0;
    width: 4px;
    height: 4px;
    border: 5px solid #ffffff;
    background: #ffffff;
    border-radius: 50%;
    box-sizing: content-box !important;
    outline: none !important;

    &:checked {
      border-color: $color-black-100;
    }
  }

  .radioLabel {
    flex: 1;
    line-height: 1;
  }

  .radioIcon {
    color: $color-black-70;
  }

  .radioContent {
    padding: 0.5rem 1rem 1rem;
  }

  &.open {
    background: #ffffff;

    .radioHeader {
      cursor: default;
    }

    .radioLabel {
      @include fontMedium;
    }
  }
}
