@import '../../ui/style/colors';
@import '../../ui/style/typography';

.panel {
  background: #ffffff;
  border-top: 1px solid $color-background-dark;
}

.cta {
  @include fontLight;

  color: $color-black-100;

  p {
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }

  span {
    height: 14px;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    color: $color-black-70 !important;
  }
}

.title {
  @include fontLight;

  font-weight: 600;
  font-size: 16px;
}

.secondaryText {
  @include fontLight;

  color: $color-black-70;
  font-size: 12.8px;
  height: 16px;
}

.images {
  img {
    height: 24px;
    width: 24px;
    object-fit: cover;
    border-radius: 100px;
    border: 2px solid #ffffff;
    margin-right: -8px;
  }
}
