@import "../../../style/colours";
@import "../../../style/vendor/_font-awesome-variables.scss";

.monitoringVisualisation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.status {
  text-transform: capitalize;
  padding-top: 5px;
  margin-bottom: 24px;
  font-size: 48px;
}

.positive {
  color: var(--positive-color, $color-geckoboard-green);
}

.negative {
  color: var(--negative-color, $error-red);
}

.arrow {
  &:before {
    display: inline-block;
    @include fontAwesome;
    content: fa-content($fa-var-caret-right);
    font-weight: $fa-weight-solid;
    margin-right: 15px;
  }
}

.arrowUp {
  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-up);
    font-weight: $fa-weight-solid;
  }
}

.arrowDown {
  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
  }
}

.unreported {
  font-size: 36px;
  margin-bottom: 36px;
}

.serverTimes {
  overflow: hidden;
  display: flex;
}

.serverTime {
  &:first-of-type {
    padding-right: 1em;
  }
}

.label {
  margin: 0 0 6px;
}

.secondaryStat {
  margin-bottom: 1em;
  font-size: 20px;
}

.isLargeTemplate {
  .status {
    margin-bottom: 48px;
    font-size: 100px;
  }

  .label {
    font-size: 24px;
  }

  .secondaryStat {
    font-size: 36px;
  }

  .unreported {
    font-size: 75px;
    margin-bottom: 75px;
  }

  .serverTime {
    width: 50%;
  }
}
