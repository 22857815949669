@import '../../../style/colors';
@import '../../../style/typography';

.searchBar {
  position: relative;
  border-bottom: 1px solid $color-grey-10;
}
.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: $color-grey-60;
}

.searchIcon {
  color: $color-black-100;
  left: 16px;
}

.deleteIcon {
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  right: 0;
  font-size: 1rem;
  padding: 0 16px;
  height: 100%;
  color: $color-grey-80;

  &:focus {
    outline: none;
    background: $color-grey-5;
  }

  &:hover,
  &:focus {
    color: $color-grey-100;
  }
}

.deleteText {
  display: none;
}

.input {
  @include fontLight;
  display: block;
  appearance: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  height: 48px;
  outline: none;
  margin-left: 46px;
  width: calc(100% - 46px);
  box-sizing: border-box;
  padding-right: 38px;

  ::placeholder {
    color: $color-grey-60;
  }

  &:focus {
    color: $color-grey-100;
  }

  ::required {
    display: none;
  }
  ::invalid {
    display: none;
  }

  // Removes the default searchbox styling from Webkit browsers
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  // Removes the default searchbox styling from IE
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}
