@import '../../src/ui/style/colors';

.form-item-help {
  outline: none;
  position: relative;

  .help-text {
    transform: translate(20px, -25%);

    position: absolute;
    min-width: 300px;
    z-index: 2;
    top: 6px;
    padding: 15px;

    background-color: $color-black-100;
    color: #ffffff;
    border-radius: 4px;

    line-height: 1.2;

    a {
      color: #ffffff;
      text-decoration: underline;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .help-icon {
    position: absolute;
    display: inline-block;
    width: 1em;
    top: 1px;
    height: 32px;

    border: none;
    color: $color-black-100;

    text-indent: -999em;
    overflow: hidden;

    cursor: help;
    outline: none;

    &::after {
      position: absolute;
      display: block;
      @include fontAwesome;
      content: fa-content($fa-var-question-circle);
      text-indent: 0;
    }
  }
}

.form-item-help-active {
  .help-icon {
    color: $field-help-icon-selected-colour;
  }
}
