.non-graphql-dashboard {
  .visualisation-positioner {
    position: absolute;
    top: $widget-header-height;
    left: 0;
    bottom: 0;
    right: 0;

    &.widget-body {
      z-index: initial;
    }

    // While widget rendering is initiated in gecko-js we need a couple of
    // elements to first render our react component into and then give
    // over to gecko-js. In order for the visualisation to fill the available space
    // these must have their height set to 100%
    .widget-canvas, .visualisation-container, .gecko-js-bridge {
      height: 100%;
    }
  }
}
