@import "../../../style/shared/animations",
"../../../style/colours",
"../../../style/vendor/font-awesome-variables";

.loader {
  position: relative;
  width: 100%;
  height: 34px;
  padding: 8px 0 0 30px;

  // Style matching auto input field
  color: $text-grey;
  background: #fff;
  border-radius: 3px;
  font-size: 0.875rem;
  opacity: 0.5;

  &::before {
    animation: spin 1s infinite linear;

    font-weight: normal;
    font-size: 14px;

    transform-origin: center center;

    position: absolute;
    left: 8px;
    top: 8px;
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 10px;

    line-height: 14px;

    @include fontAwesome;
    content: fa-content($fa-var-spinner);
  }
}
