@import '../../style/colours';
@import '../../src/ui/style/typography';

.container {
  position: fixed;
  top: 136px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 136px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  @include variable(color, --text-color, $light-grey);
}

.content {
  @include fontMedium;

  padding: 4rem 8rem;
  max-width: 775px;
  border-radius: 6px;
  margin: 0 auto;
  margin-bottom: 4rem;
  text-align: center;
  @include variable(background-color, --widget-background-color, $dark-grey);

  p {
    font-size: 1.25rem;
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
  }
}

a.link {
  @include fontLight;

  @include variable(color, --dashboard-text-color, #ffffff);
  text-decoration: none;
  border-bottom: none;

  &:focus,
  &:hover {
    @include variable(color, --dashboard-text-color, #ffffff);
    border-bottom: none;
  }
}

.heading {
  i {
    margin-right: 0.5rem;
  }
}

.angleRight {
  font-weight: 300;
  margin-left: 0.5rem;
}
