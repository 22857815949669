.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input {
  color: var(--input-placeholder-color, #82839D);
  opacity: 0;
  transition: opacity 0.1s linear;

  &:hover {
    opacity: 1;
    border-color: var(--container-hover-color);
  }

  &.hasValue, &:focus {
    color: var(--secondary-text-color, #C0C9DE);
    opacity: 1;
  }
}
