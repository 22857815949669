.InstrumentPreview {
  background-color: var(--container-background-color);
  background-image: var(--widget-background-image);
  border-radius: 10px;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-content: center;
  box-sizing: border-box;
  height: 100%;
}

.title {
  height: 42px;
  padding: calc(0.6rem * var(--scale-factor));
  padding-bottom: 0;
  overflow: hidden;
}

.visualisation {
  position: relative;
  transform-origin: top left;
  padding: calc(0.9rem * var(--scale-factor));
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > div {
    position: relative;
  }
}

.visualisationInner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: top left;

  // TODO: Fix our visualisations to fill their containiers
  // instead of forcing them to do it from the parent's CSS
  :global(.barchart),
  :global(.chart),
  :global(.geckometer),
  :global(.number-widget),
  :global(.text-visualisation) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  :global(.leaderboard) {
    max-width: none !important;
  }
}

.visualisationScaler {
  transform-origin: top left;
}
