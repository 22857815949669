@import "../../../style/colours",
  "../../../style/shared/animations";

.link {
  @include animateProperty(opacity);

  color: $ui-green !important; // overwrite the cascade
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}
