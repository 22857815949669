@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/shared/animations';

.label {
  i {
    margin-right: 6px;
  }
}

.menu {
  position: relative;
}

.toggleButton {
  @include animateButtonFade;

  border: 0;
  background: none;
  font-size: 1rem;
  padding: 6px 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  line-height: 26px;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.legacyMastheadToggleButton {
  padding: 0;
}

.container {
  position: relative;
}

.panel {
  @include fontMedium;

  position: absolute;
  top: calc(100% + 1rem);
  left: 20px;
  border-radius: 6px;
  background-color: white;
  color: $color-black-100;
  width: 450px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  border: 1px solid $color-grey-10;
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.3);

  transform: scale(0.6);
  transform-origin: top left;
  transition: $shortFade all $easeOutQuint;

  &.open {
    height: auto;
    overflow: visible;

    opacity: 1;
    transform: scale(1);
  }
}

.legacyMastheadPanel {
  top: calc(100% + 1.5rem);
  left: unset;
}

.toggle {
  padding-top: 1rem;

  li[role='menuitem'] {
    cursor: pointer;
  }
}

.info {
  @include fontLight;
  text-align: center;
}

.error {
  color: $color-negative;
}

.muted {
  @include fontLight;
}

.wrapperTabs {
  background-color: #fff;
  margin-top: 1rem;
}

.layoutTabList {
  margin: 0 1rem 0.5rem 1rem;
}

.tabTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  column-gap: 0.5rem;
}

.tabIconContainer {
  display: inline-flex;
  align-items: center;
}

.iconContainer {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
}

.slackView {
  padding: 0 1rem 1rem 1rem;
}

.emailSnapshotView {
  padding: 0 1rem 1rem 1rem;
}

.titleBox {
  display: flex;
}

.title {
  margin: 0 0.5rem 0.5rem 0;
}
