.emptyState {
  flex: 0 0 auto;
  text-align: center;
  font-size: 14px;

  color: #FFFFFF;
}

.placeholder {
  height: 80%;

  h3 {
    margin: 22px 0 10px;

    font-size: 21px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.image {
  top: 50%;
  position: relative;
  padding-top: 10px;
  transform: translateY(-50%);
}

.icon {
  font-size: 31px;
}

.text {
  height: 20%;
}

.lLoading {
  margin-right: 0.5rem;
}
