@import '../../../../../../style/colours';
@import '../../../../../../src/ui/style/colors';

.connection {
  padding: 0;

  &:first-child {
    .deleteButton {
      border-top-right-radius: 6px;
    }

    .connectionName {
      border-top-left-radius: 6px;
    }
  }

  &:last-child {
    .deleteButton {
      border-bottom-right-radius: 6px;
    }

    .connectionName {
      border-bottom-left-radius: 6px;
    }
  }
}

.connectionIcon {
  margin: 0 1rem 0 0.5rem;
}

.connectionName {
  appearance: none;
  outline: none;
  border: none;
  background: #ffffff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0.2rem;
  height: 52px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background: $color-background-light;
  }
}

.connectionDetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.configDetail {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;
}

.iconWithInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: $color-grey-80;
  font-size: 13px;
  width: 150px;
  padding-right: 10px;
  gap: 5px;
}

.dbInfo {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2px;
}

.deleteButton {
  background: white;
  border: none;
  bottom: 0;
  color: $color-grey-100;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  width: 50px;
  align-self: stretch;

  &:hover,
  &:focus {
    outline: none;
    background: $color-background-light;
  }
}
