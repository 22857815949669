@import 'variables', '../vendor/chosen', '../vendor/mq', 'fields',
  '../typography', '../mq', '../../src/ui/style/colors';

body.integration-list-page,
.spreadsheet-config-page {
  background-color: $color-background-light;
  color: $color-black-100;

  * {
    box-sizing: border-box;
  }

  @import 'utils', 'modules/notification', 'modules/btn', 'modules/form-actions',
    'modules/form-field', 'modules/field-help', 'modules/service-header',
    'modules/loadable', 'modules/connect-account-icon';

  @import 'layouts/widget-type-list', 'layouts/configure-widget',
    'layouts/connect-service-account';
}
