@import '../../../../../src/ui/style/typography';
@import '../../../../../style/colours';

.verticalNumberContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;

  // This will make it fill the remaining space
  bottom: 0;

  &:hover {
    .label {
      opacity: 1;
    }
  }
}

.primaryNumberLayout {
  flex: 0 0 auto;
}

.primaryNumber {
  @include fontMedium;

  display: inline-block;
}

.labelLayout {
  display: block;
  flex: 0 0 auto;
  width: 100%;
}

.label {
  // Prevent the border radius of the instrument
  // cropping the first character of the text
  padding-left: 2px;
}

.noDataLabel {
  color: var(--text-color);
  opacity: 0.5;
}
