@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';

.sectionHeading {
  @include fontMedium;
  margin-bottom: 10px;
  color: $color-black-100;
}

.pip {
  border: 1px solid $color-black-100;
  display: inline-block;
  flex: none;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  border-radius: 50%;
}

.labelText {
  margin-left: 10px;
}
