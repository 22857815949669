/* This is a duplicate of a file that also exists in the UI kit,
 * so if changes are made, both must be updated
 */

/*
 * Gecko-Font - version 2.1
 * Loaded and defined in Gecko-JS
 */
@import "variables";

.gecko-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'GeckoFont' !important;
  line-height: 1;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gecko-bar:before { content: $gecko-var-bar; }
.gecko-column:before { content: $gecko-var-column; }
.gecko-funnel:before { content: $gecko-var-funnel; }
.gecko-geckometer:before { content: $gecko-var-geckometer; }
.gecko-leaderboard:before { content: $gecko-var-leaderboard; }
.gecko-line:before { content: $gecko-var-line; }
.gecko-number:before { content: $gecko-var-number; }
.gecko-rag:before { content: $gecko-var-rag; }
.gecko-text:before { content: $gecko-var-text; }
.gecko-table:before { content: $gecko-var-table; }
.gecko-feed:before { content: $gecko-var-feed; }

// These icons are being reused with different aliases
.gecko-list:before { content: $gecko-var-feed}
.gecko-ragcolumn:before { content: $gecko-var-rag; }

.gecko-about:before { content: $gecko-var-about; }
.gecko-filter:before { content: $gecko-var-filter; }
.gecko-format:before { content: $gecko-var-format; }
.gecko-goal:before { content: $gecko-var-goal; }
.gecko-split-by:before { content: $gecko-var-split-by; }
.gecko-split-column:before { content: $gecko-var-split-column; }
.gecko-split-line:before { content: $gecko-var-split-line; }
.gecko-clock:before { content: $gecko-var-clock; }

.gecko-sort-up:before { content: $gecko-var-sort-up; }
.gecko-sort-down:before { content: $gecko-var-sort-down; }
.gecko-sort:before { content: $gecko-var-sort; }
.gecko-more-decimals:before { content: $gecko-var-more-decimals; }
.gecko-less-decimals:before { content: $gecko-var-less-decimals; }

.gecko-map:before { content: $gecko-var-map; }

.gecko-monitoring:before { content: $gecko-var-monitoring; }
.gecko-bullet:before { content: $gecko-var-bullet; }
.gecko-highcharts:before { content: $gecko-var-highcharts; }
.gecko-pie:before { content: $gecko-var-pie; }

.gecko-slack:before { content: $gecko-var-slack; }
.gecko-star:before { content: $gecko-var-star; }
