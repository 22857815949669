@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';
@import '../../../../style/colours';
@import '../../../../style/vendor/font-awesome-variables';

.helpContainer {
  display: flex;
  flex-direction: row;
}

.helpButton {
  @include fontBold;

  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 20px;
  line-height: 32px;
  vertical-align: middle;
  padding: 0 1rem 0 0;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  outline: none;
  white-space: nowrap;

  transition: all 400ms;

  background-color: rgba(255, 255, 255, 0.4);
  color: #ffffff;
  border-radius: 3px;

  &:hover,
  &:focus {
    opacity: 0.6;
    color: #ffffff;
  }

  &::before {
    content: '?';
    display: inline-block;
    background-color: $secondary-grey;
    color: $body-background;
    width: 32px;
    text-align: center;
    font-weight: bold;
    margin-right: 1rem;

    border-radius: 3px 0 0 3px;
  }
}
