@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';
@import '../../../../style/colours';
@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../style/shared/animations';

.BaseMasthead {
  height: 48px;
  background-color: #16113a;
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
}

.logo {
  display: inline-block;
  padding: 0.5rem 1rem;
}

.baseMastheadMenu {
  top: 0.5rem;
  display: flex;
  align-items: baseline;
  margin-left: auto;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}

.dashboardNavList {
  display: flex;
  align-items: center;
  list-style: none;
}

.mastheadLink {
  @include fontBold;
  @include animateButtonFade;

  border: 0;
  background: none;
  padding: 6px 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  line-height: 26px;
  color: #fff;
  font-size: 1rem;

  &:hover,
  &:focus,
  &:visited:hover {
    color: #fff;
    opacity: 0.6;
  }
}
