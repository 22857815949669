@import '../style/colors';

.badge {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  padding-top: 8px;
  font-size: 16px/1;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.green-badge {
  composes: badge;
  background-color: $color-geckoboard-green;
  color: $color-black-100;
}

.grey-badge {
  composes: badge;
  background-color: $color-grey-10;
  color: $color-black-90;
}
