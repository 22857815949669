@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';

.goalCard {
  border-radius: 6px;
  border: 2px solid $color-grey-40;
  margin-bottom: 0.5rem;
  background: #ffffff;
  padding: 1rem;

  .inputWithLabel {
    display: flex;

    label {
      @include fontMedium;
      flex: 1;
      margin-top: 7px;
    }

    .input {
      flex: 2;
    }
  }

  .directionToggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1.3rem;
  }
}
