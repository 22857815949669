@import '../../../../../../src/ui/style/colors';

.minMaxText {
  color: $color-grey-100;
}

.renameSeriesInputsWrapper {
  border-bottom: 1px solid $color-grey-40;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
