@import "../../../style/vendor/font-awesome-variables";

.settingsCog {
    padding: 0;
    
    &::before {
        @include fontAwesome;
        content: fa-content($fa-var-cog);
        font-weight: $fa-weight-solid;
        margin-right: 0.5em;
    }
}
