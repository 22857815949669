@import '../compact-config-colors';
@import '../../../../src/ui/style/colors.scss';

.numberPicker {
  width: 126px;
  display: flex;

  .input {
    & input {
      height: 36px;
      border-radius: 0;
      border: 1px solid $color-background-dark;
      padding: 0;
      text-align: center;
    }

    & input::placeholder {
      color: $input-placeholder-colour;
    }
  }
}
