@mixin two-col-width ($item) {
  width: 213px;
  margin-right: 10px;

  &:nth-of-type(#{$item}) { margin-right: 0 }
}

#dashboard-config {
  .form-wrapper { margin-top: 30px }

  .form-field {
    width: 100%;
    float: left;

    &.col-2 { @include two-col-width(even) }
    &.css-upload { margin-right: 0 }
    input[type=radio] + label { padding: 19px }
  }

  .timezone-settings {
    float: left;
    width: 100%;

    .select-field {
      @include two-col-width(even);
      float: left;
      display: block;
    }
  }

  legend {
    font-size: 18px;
    color: #676767;
    margin: 0 0 10px;
  }

  .file-field {
    > label:after {
      top: 0;
      right: 0;
      border-left: solid 1px #cfcfcf;
      position: absolute;
      width: 44px;
      height: 52px;
      font-size: 24px;
      line-height: 48px;
      text-align: center;
      @include fontAwesome;
      content: fa-content($fa-var-cloud-upload-alt);
      color: #b2b2b2;
    }

    &:hover {
      > label:after { color: #3a3a3a }
    }

    &.with-delete { > label:after { display: none } }
  }

  .dashboard-template-inner {
    width: 700px;
  }
}
