@import '../../../../style/colours';
@import '../../../../src/ui/style/colors';
@import '../../../../style/vendor/_font-awesome-variables.scss';

.dropDown {
  margin-bottom: 14px;
}

.selectWrapper {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 0;
  bottom: 0;

  color: $color-black-100;

  &:after {
    pointer-events: none;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
    line-height: 14px;
  }

  select {
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    outline: 0;
    font: inherit;
    background-color: transparent;
    color: inherit;

    border: none;
    padding-left: 15px;
    padding-right: 10px + 8px + 5px; // leave room for caret
    width: 100%;
    height: 100%;
    border-radius: 0 6px 6px 0;

    option {
      background-color: #ffffff;
      color: inherit;

      padding: 10px 15px;
    }

    &:focus {
      box-shadow: 0 0 0 1px $color-black-100;
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      &,
      option {
        color: $field-disabled-colour;
        cursor: default;
      }
    }
  }
}
