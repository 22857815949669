@import '../../../style/colours';

.list {
  list-style: none;
}

.title {
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
}

.description {
  color: var(--secondary-text-color, $text-colour);
  font-size: 18px;
  padding-bottom: 10px;
}

.label {
  display: inline-block;
  padding: 4px;
  text-transform: uppercase;
  font-size: 0.85em;
  position: relative;
  margin-right: 4px;
  top: -2px;
}

.measure {
  position: absolute;
  list-style: none;
  line-height: 1.3;
  visibility: hidden;
  pointer-events: none;
}

.pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  button {
    @include variable(background-color, --text-color, $gray_athens);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    height: 12px;
    width: 12px;
    margin-right: 6px;
    opacity: 0.2;

    &:global(.active) {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
}
