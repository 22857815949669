@import '../../../../../src/ui/style/typography';

.wrapper {
  background-color: var(--container-background-color);
  color: var(--text-color);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.vizTypeInfoWrapper {
  padding-top: calc(10px * var(--scale-factor));
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    @include fontBold;
    margin: calc(22px * var(--scale-factor)) 0 calc(10px * var(--scale-factor));
    font-size: calc(36px * var(--scale-factor));
  }
}

.illustration {
  :global(.icon-gecko):before {
    padding: calc(4px * var(--scale-factor)) calc(10px * var(--scale-factor));
    font-size: calc(48px * var(--scale-factor));
  }
}

.text {
  font-size: calc(28px * var(--scale-factor));
  margin: calc(16px * var(--scale-factor));
  white-space: pre-line;
  line-height: 1.5;
}

.centeredText {
  font-size: calc(28px * var(--scale-factor));
}
