@import '../shared/link';
@import '../colours';
@import '../spacing';
@import '../../src/ui/style/colors';

$level_bg: #eca245;
$widget_bg: #2a2a2a;
$widget_text_colour: #e0e0e0;

.widget-positioning {
  position: absolute;

  &.focused {
    z-index: 10000000 !important;
    cursor: default !important;
  }

  &.resizing {
    z-index: 10000000 !important;
  }

  &.selected,
  &.isBeingDragged {
    opacity: 1 !important;
    box-shadow: 0 0 0 2px $gray-main;
    box-shadow: 0 0 0 2px var(--selected-widget-header-color);
  }

  &.selected {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }

  .dragging & {
    opacity: 0.6;
  }
}

.non-graphql-dashboard {
  .widget {
    @include variable(color, --text-color, $widget_text_colour);
    user-select: none;

    .widget-inner {
      @include variable(
        background-color,
        --widget-background-color,
        $widget_bg
      );

      overflow: hidden;
    }

    .widget-body {
      padding: 16px 0;
      @include widgetSpacing(padding-left padding-right);

      z-index: 1;

      sup,
      sub {
        font-size: 60%;
        vertical-align: baseline;
        position: relative;
        height: 0;
        line-height: 1;
      }

      sup {
        bottom: 0;
      }

      sub {
        top: 0.5ex;
      }

      a:link,
      a:visited {
        @include link;

        &.widget-anchor {
          @include variable(color, --text-color, $widget_text_colour);
          border-bottom: none;

          &:hover {
            border-bottom: 1px solid $widget_text_colour;
            @include variable(
              border-bottom-color,
              --text-color,
              $widget_text_colour
            );
          }
        }
      }
    }

    .widget-footer footer {
      z-index: 6;
    }

    aside {
      z-index: 2;
      display: none;
    }

    .widget-message {
      word-wrap: break-word;
      line-height: 1.3;

      .copyable {
        position: relative;
        margin-left: 24px;
        margin-top: 16px;
        display: inline-block;

        &:focus {
          outline: none;
        }

        &::before {
          left: -24px;
          position: absolute;
          @include fontAwesome;
          content: fa-content($fa-var-clipboard);
          border-bottom: none;
        }

        &.copied::before {
          @include fontAwesome;
          content: fa-content($fa-var-check);
        }
      }
    }

    &.loadable::after {
      height: 1px;
      position: absolute;
    }
  }
}

@keyframes spin {
  0% {
    transform: translateZ(0) rotate(0deg);
  }
  100% {
    transform: translateZ(0) rotate(360deg);
  }
}

.status-indicator {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 8px;
  right: 5px;
  z-index: 11;
  display: block;
  padding: 0;

  @include variable(color, --secondary-text-color, $gray_lavender!important);

  text-align: center;
  text-rendering: geometricPrecision;
  line-height: 1;
  cursor: default;
  border: none;
  border-radius: 100px;
  overflow: hidden;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    line-height: 25px;
    @include variable(background-color, --widget-background-color, $widget_bg);
  }
}

.loading {
  .status-indicator::before {
    animation: spin 1s infinite linear;
    @include fontAwesome;
    content: fa-content($fa-var-spinner);
  }
}

.failed {
  &.loadable::after {
    background-color: $error_red;
    width: 100%;
  }
}

.warning {
  &.loadable::after {
    background-color: $warning-yellow;
    width: 100%;
  }

  .status-indicator::before {
    transform: translateX(24px);
  }

  &:hover {
    .status-indicator::before {
      transition: 0.2s 0.4s transform;
      transform: translateX(0);
    }
  }
}

.warning,
.failed {
  .status-indicator {
    cursor: pointer;

    &::before {
      @include fontAwesome;
      content: fa-content($fa-var-sync);
    }

    &:active::before {
      transition: 0.2s transform;
      transform: translateX(0) rotate(120deg);
    }
  }
}

// Measure arrows
.arrow::before {
  display: inline-block;
  @include fontAwesome;
  content: fa-content($fa-var-caret-right);
}

.arrow-up::before {
  @include fontAwesome;
  content: fa-content($fa-var-caret-up);
}

.arrow-down::before {
  @include fontAwesome;
  content: fa-content($fa-var-caret-down);
}

// Text sizes
.t-size-x10 {
  font-size: 10px;
}
.t-size-x11 {
  font-size: 11px;
}
.t-size-x12 {
  font-size: 12px;
}
.t-size-x14 {
  font-size: 14px;
}
.t-size-x18 {
  font-size: 18px;
}
.t-size-x20 {
  font-size: 20px;
}
.t-size-x24 {
  font-size: 24px;
}
.t-size-x28 {
  font-size: 28px;
}
.t-size-x30 {
  font-size: 30px;
}
.t-size-x36 {
  font-size: 36px;
}
.t-size-x40 {
  font-size: 40px;
}
.t-size-x44 {
  font-size: 44px;
}
.t-size-x48 {
  font-size: 48px;
}
.t-size-x52 {
  font-size: 52px;
}
.t-size-x60 {
  font-size: 60px;
}
.t-size-x100 {
  font-size: 100px;
}
.t-size-x1_25rem {
  font-size: 1.25rem;
}
.t-size-x1_5rem {
  font-size: 1.5rem;
}
.t-size-x2_25rem {
  font-size: 2.25rem;
}
.t-size-x3_75rem {
  font-size: 3.75rem;
}
.t-size-x4_5rem {
  font-size: 4.5rem;
}
.t-size-x6_75rem {
  font-size: 6.75rem;
}

// Text colors
.t-bright {
  color: white;
}

// Measureable elements
.negative {
  @include variable(color, --negative-color, $color-negative);
}
.positive {
  @include variable(color, --positive-color, $color-geckoboard-green);
}
.level {
  @include variable(color, --caution-color, $level_bg);
}

.widget-rollover-message {
  transition: 2s opacity ease-out;
  position: absolute;
  top: 39px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  @include variable(background-color, --widget-background-color, $widget_bg);
  padding: 16px 13px;
  opacity: 0;
  font-size: 0.875rem;
  line-height: 1.3;
}

.widget:hover .widget-rollover-message {
  opacity: 1;
}

// individual widget template styles
@import 'widgets/number', 'widgets/rag', 'widgets/ragcolumn',
  // base styles for this are in rag,
  'widgets/geckometer',
  'widgets/google-charts', 'widgets/funnel', 'widgets/bullet',
  'widgets/highchart', 'widgets/map', 'widgets/list',
  // base styles for this in text,
  'widgets/clock',
  'widgets/image', 'widgets/monitoring';
