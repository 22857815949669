@import '../../../src/ui/style/colors';

@keyframes bounceAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.item {
  border-radius: 6px;
  padding: 0.5em;
  cursor: pointer;

  transition: transform 0.05s linear;
}

.content {
  opacity: 0.5;
}

.active {
  background-color: $color-background-light;
  animation: bounceAnimation 100ms;

  .content {
    opacity: 1;
  }
}
