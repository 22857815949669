@import '../../../../src/ui/style/colors';

.wrapper {
  font-size: 1.25rem;
  text-align: center;
}

.icon {
  color: $color-black-70;
}
