.wrapper {
  position: relative;
  margin: 5px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 250px;
}

.smallWidth {
  min-width: 230px;
  flex-basis: 0;
}

.smallHeight {
  min-height: 170px;

  // Truncate text widgets. This used to be a webkit
  // quirk but has been added to the standard:
  // https://drafts.csswg.org/css-overflow-3/#webkit-line-clamp
  :global(.slide) {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.largeHeight {
  min-height: 500px;
}
