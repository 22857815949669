@import '../../../style/colours';

.item {
  color: var(--text-color);
  padding: 1.25rem;
  break-inside: avoid;
  border-top: 1px solid var(--chart-axis-color);

  &:first-of-type {
    border-top: 0;
  }

  &.containerLayout {
    padding: 0.75rem 0;
  }
}

.iconWrapper {
  margin-right: 1.25rem;
  position: relative;
}

.icon {
  border-radius: 50%;
  width: 2.75rem;
  height: 2.75rem;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    position: absolute;

    // center img in case it's not square
    left: 50%;
    transform: translateX(-50%);
  }
}

.iconSecondary {
  width: 1.375rem;
  height: 1.375rem;
  position: absolute;
  top: 1.6875rem;
  right: -0.4375rem;
}

.mainImage {
  margin-bottom: 1rem;
  width: 100%;
}

.mainColumn {
  max-width: 100%;
}

.main {
  flex: 1 0 auto;
  padding-bottom: 8px;

  div:empty {
    height: 25px;
  }
}

.secondary {
  white-space: nowrap;
  color: var(--secondary-text-color);
}

.divider {
  content: '•';
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
}

.medium,
.large {
  .main {
    padding-bottom: 8px;
  }
}

.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.fallback {
  background-color: var(--neutral-color);
  width: 100%;
  position: absolute;
  inset: 0;
  border-radius: 9999px;
}
