@import "../../../../style/colours";
@import "../../../../style/vendor/font-awesome-variables";

.swatch {
  transition: 0.1s ease-out border-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;

  border-width: 2px;
  border-style: solid;

  &[aria-checked=true] {
    cursor: default;

    &::after {
      content: "";
      @include fontAwesome;
    }
  }

  &.hoverBorder:hover {
    border-color: $ui-green!important;
  }

  &.light {
    color: $text-grey;
  }

  &:hover {
    .hoverIcon {
      display: block;
    }
  }
}

.hoverIcon {
  display: none;
}
