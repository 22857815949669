@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../src/ui/style/colors';

.selectWrapper {
  display: inline-block;
  position: relative;
  height: 36px;
  width: 100%;

  /* These styles are copied from dropdown-input.styles.css to match
    the style of the DatasetDropdownInput. */
  &:after {
    pointer-events: none;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
    line-height: 14px;
  }

  select {
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    outline: 0;
    font: inherit;
    background-color: transparent;
    color: inherit;

    border: 1px solid #dfe4ee;
    padding-left: 15px;
    padding-right: 10px + 8px + 5px; // leave room for caret
    width: 100%;
    height: 100%;
    border-radius: 6px;

    &:focus {
      box-shadow: 0 0 0 1px $color-black-100;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
