@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';

.integrationDatasetSetup {
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
  border: solid 1px $color-grey-20;

  position: relative;

  z-index: 4;
}

.select {
  margin-bottom: 10px;
  position: inherit;
}

.selectHeading {
  margin-left: 2px;
  margin-bottom: 3px;
  font-size: 0.75rem;
  color: $color-grey-100;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.locked {
  display: flex;
  justify-content: space-between;
  padding: 13px 17px;
  background-color: $color-background-mid;
  border-radius: 6px;
  cursor: pointer;

  .iconContainer {
    display: none;
  }

  &:hover {
    background: $color-background-dark;

    .iconContainer {
      display: block;
    }
  }

  .parameterSelections {
    display: flex;
    flex-direction: column;

    div:last-child {
      margin-bottom: 0;
    }
  }

  .parameterSelection {
    margin-bottom: 1rem;
  }

  .parameterTitle {
    @include fontBold;
  }
}

.errorAlert {
  margin-top: 1rem;
}
