@import '../style/colors';
@import '../style/typography';

$arrow-size: 10px;

:local(.Tooltip) {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  z-index: 10005;

  transition: opacity 0.1s ease, transform 0.1s ease;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
    opacity: 1;
    margin-bottom: 0px;
    transform: translateY(0px) translateX(0px);
  }

  &.entering,
  &.exiting {
    pointer-events: none;
  }
}

:local(.TooltipContent) {
  @include fontLight;

  color: $color-grey-100;
  position: relative;
  z-index: 2;
  background: $color-background-light;
  border-radius: 6px;
  border: 1px solid $color-grey-20;
  box-sizing: border-box;
  padding: 0.5rem 0.7rem;
  font-size: 0.825rem;
  line-height: 1.2rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

:local(.arrow),
:local(.arrowShadow) {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
}

:local(.arrowInner) {
  width: $arrow-size;
  height: $arrow-size;
  box-sizing: content-box !important;
}

:local(.arrow) {
  overflow: hidden;
  z-index: 3;

  :local(.arrowInner) {
    width: $arrow-size;
    height: $arrow-size;
    background-color: $color-background-light;
    border: 1px solid $color-grey-20;
  }
}

:local(.arrowShadow) {
  z-index: 1;

  :local(.arrowInner) {
    width: $arrow-size;
    height: $arrow-size;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
  }
}

/**
 * DIRECTIONS
 */
:local(.top) {
  transform: translateY(10px);

  :local(.arrow),
  :local(.arrowShadow) {
    top: calc(100% - 1px);
  }

  :local(.arrowInner) {
    transform: translateY(0 - calc($arrow-size / 2 + 1px)) rotate(45deg);
  }
}

:local(.bottom) {
  transform: translateY(-10px);

  :local(.arrow),
  :local(.arrowShadow) {
    bottom: calc(100% - 1px);
  }

  :local(.arrowInner) {
    transform: translateY(calc($arrow-size / 2 + 1px)) rotate(45deg);
  }
}

:local(.left) {
  transform: translateX(10px);

  :local(.arrow),
  :local(.arrowShadow) {
    left: calc(100% - 1px);
    top: 0;
    height: 100%;
    width: $arrow-size + 2;
    align-items: center;
  }

  :local(.arrowInner) {
    transform: translateX(0 - calc($arrow-size / 2 + 1px)) rotate(45deg);
  }
}

:local(.right) {
  transform: translateX(-10px);

  :local(.arrow),
  :local(.arrowShadow) {
    right: calc(100% - 1px);
    top: 0;
    height: 100%;
    width: $arrow-size + 2;
    align-items: center;
  }

  :local(.arrowInner) {
    transform: translateX(calc($arrow-size / 2 + 1px)) rotate(45deg);
  }
}
