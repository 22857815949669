@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.dashboardSideBar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  background-color: $color-background-light;
  display: flex;
  flex-direction: column;
}

.sideBarFooter {
  border-top: 1px solid $color-background-dark;
  z-index: 50;
  background-color: $color-background-light;

  button {
    background-color: transparent;
    height: 32px;

    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-black-70;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.sideBarHeader {
  h4 {
    @include fontSmall;
    color: $color-black-70;
  }

  button {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    color: $color-black-70;
  }
}

.addNewDashboardButton {
  &:hover {
    button,
    span {
      color: $color-black-60;
      background: transparent;
    }
  }

  span {
    font-weight: 400;
    color: $color-black-70;
  }

  button {
    @include fontLight;
    color: $color-black-70;
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.dashboardsList {
  overflow-y: scroll;
  height: 100%;

  li {
    list-style: none;
  }
}

.DashboardsList__Item {
  position: relative;

  &:hover {
    .editTitleIcon {
      display: block;
    }

    .DashboardsList__Link__active {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    }
  }
}

.DashboardsList__Link {
  color: $color-black-100;
  position: relative;
  font-size: 1rem;
  display: flex;
  padding: 0.5rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:focus,
  &:hover,
  &:visited:hover {
    border-radius: 4px;
    background-color: $color-background-dark;
    color: $color-black-100;
  }
}

.DashboardsList__Link__active {
  position: relative;
  background-color: $color-background-dark;
  border-radius: 4px;

  &:hover {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  }

  textarea {
    color: $color-grey-100;
  }
}

.editTitleIcon {
  display: none;
  position: absolute;
  padding: 0;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-grey-100;
  background: none;
  border: none;
  cursor: pointer;
}
