@import '../../../../style/_colours.scss';
@import '../../../../src/ui/style/colors';

.inputOuter {
  display: flex;
  flex: 1;

  font-size: 16px;
  line-height: 1.8;
  border-radius: 5px;
  border: 1px solid $color-background-dark;
}

.disabled {
  .label {
    cursor: default;
  }

  .input {
    background-color: $color-background-mid;
  }
}

.invalid {
  .label {
    background-color: $color-negative;
  }

  .input {
    border-color: $color-negative !important;
  }
}

.label {
  display: flex;
  align-items: center;

  width: 40%;
  color: $color-black-100;
  min-height: 41px;
  padding-left: 12px;
  border-radius: 5px 0 0 5px;
  background-color: $color-background-mid;
  cursor: pointer;
}

.textLabel {
  flex: 1 1 auto;

  padding-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input {
  display: flex;
  flex: 1 1 auto;
  width: 60%;

  border-radius: 0 5px 5px 0;
  padding: 0;
  border: none;
  border-left: 1px solid $color-background-dark;
  background-color: #ffffff;
  color: $body-background;
  position: relative;
}

/* Equal width label and field (primarily for displaying two fields side by side) */
.tiny {
  .label,
  .input {
    width: 50%;
  }
}

/* Label is home to a collection of buttons */
.buttonGroup {
  .input {
    padding: 4px 8px 0;
    text-align: right;

    > * {
      margin-bottom: 4px;
    }
  }
}

.menuWrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;

  height: 100%;
}

.helpWrapper {
  margin-left: 1ex;
}

.light {
  .label {
    background-color: #ffffff;
    color: $text-grey;
  }
}

.inline {
  .label {
    background-color: transparent;
    padding-left: 0;
    color: $text-grey;
  }
}
