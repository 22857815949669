@import "../../../style/colours";

.table {
  border-collapse: collapse;
  min-width: 100%;

  color: $text-grey;
  background-color: white;

  font-size: 14px;

  td, th {
    padding: 10px 34px;
    white-space: nowrap;
  }

  th {
    font-weight: 500;
  }

  tbody tr:first-of-type td {
    padding-top: 12px;
  }

  tr:nth-child(2n) td, th {
    background-color: $light-grey;
  }
}

.description {
  display: block;
  margin-top: .5rem;

  color: $mid-grey;

  font-weight: normal;
}

.numeric {
  text-align: right;
}
