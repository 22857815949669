@import '../../style/colours';
@import '../../style/spacing';
@import '../../src/ui/style/colors';

.widgetTitle {
  flex: 1 1 auto;
  line-height: 1.25;
  font: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  $widget-title-font-size: 21px;
  $widget-title-padding-top: 5px;
  $widget-title-padding-bottom: 3px;

  --widget-title-font-size: #{$widget-title-font-size};
  --widget-title-padding-top: #{$widget-title-padding-top};
  --widget-title-padding-bottom: #{$widget-title-padding-bottom};

  margin-bottom: 0;
  font-size: $widget-title-font-size;
  font-size: calc(
    var(--widget-title-font-size) * var(--scale-factor, 1)
  ) !important;
  line-height: 1.475;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /*
    Provide default fallbacks for browsers that don't support custom properties
    These browsers will not be scaled but should still look ok.
  */
  padding-top: $widget-title-padding-top;
  padding-top: calc(
    var(--widget-title-padding-top) * var(--scale-factor, 1)
  ) !important;

  padding-bottom: $widget-title-padding-bottom;
  padding-bottom: calc(
    var(--widget-title-padding-bottom) * var(--scale-factor, 1)
  ) !important;

  padding-left: $default-widget-side-padding;
  padding-left: calc(
    var(--widget-side-padding) * var(--scale-factor, 1)
  ) !important;

  padding-right: $default-widget-side-padding;
  padding-right: calc(
    var(--widget-side-padding) * var(--scale-factor, 1)
  ) !important;

  position: relative;
  z-index: 5;
  color: $text-colour;
  color: var(--title-color, $text-colour);
}

.input {
  max-height: 100px;
  min-width: 100px;
  color: $text-colour;
  color: var(--title-color, $text-colour);

  &:hover {
    border-color: var(--container-hover-color, $color-grey-60) !important;
  }
}

.small {
  $widget-title-font-size: 14px;
  $widget-title-padding-top: 12px;
  $widget-title-padding-bottom: 14px;

  --widget-title-font-size: #{$widget-title-font-size};
  --widget-title-padding-top: #{$widget-title-padding-top};
  --widget-title-padding-bottom: #{$widget-title-padding-bottom};

  /*
    `!important`s on the base class .heading ensure that these will be ignored for browsers that
    don't recognise CSS custom properties
  */
  font-size: $widget-title-font-size;
  padding-top: $widget-title-padding-top;
  padding-bottom: $widget-title-padding-bottom;
  padding-left: $default-widget-side-padding;
  padding-right: $default-widget-side-padding;

  &.heading {
    white-space: pre-wrap;
  }
}

.tvMode {
  $widget-title-font-size: 1.75rem;

  --widget-title-font-size: #{$widget-title-font-size};

  /*
    `!important`s on the base class .heading ensures that these will be ignored for browsers that
    don't recognise CSS custom properties
  */
  font-size: $widget-title-font-size;
}
