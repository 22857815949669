@import '../../../../../../style/colours';
@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';
@import '../../../../../../style/vendor/font-awesome-variables';
@import '../../../../../../style/shared/link';

.rightSideWrapper {
  background: $color-background-mid;
  height: 100%;
  color: $color-grey-80;
}

.subtitle {
  display: block;
  margin-bottom: 1rem;
  color: $color-grey-100;
  font-size: 1.25rem;
}

.requirementsWrapper {
  border-bottom: 0.125rem solid white;
  padding: 1.625rem 2rem 2.25rem;
}

.requirements {
  margin-bottom: 0.625rem;

  li {
    line-height: 1.625rem;
    list-style-type: decimal;
    margin: 0 0 0.875rem 1.25rem;
  }
}

.ipAddressesList {
  font-family: 'Courier New', Courier, monospace;
  color: $color-black-100;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.125rem;
  row-gap: 0.313rem;
  padding: 0.313rem 0.938rem 0;
}

.helpChatIcons {
  display: flex;
  justify-content: space-between;
  padding: 1.875rem 1.875rem 0 0;
  cursor: pointer;

  > span,
  > a {
    @include fontMedium;
    text-decoration: none;
    color: $color-grey-100;

    span {
      margin-right: 1rem;
      color: $color-black-100;
    }
  }
}

.securityPolicyList {
  padding: 1.625rem 2.188rem;
  font-size: 1rem;
}

.securityPolicy {
  color: $color-grey-100;
  display: block;
  padding: 0 1rem 1.25rem 0;
  line-height: 1.625rem;
}

.icons {
  margin-right: 1.166rem;
}

.dataSecurity {
  margin-right: 0.8rem;
}

.viewIpList {
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: underline;
  color: $color-black-100;
  margin-left: 0.313rem;
  cursor: pointer;

  > span {
    margin-left: 0.188rem;
  }
}
