@import "../../../../../style/colours";
@import "../../../../../style/vendor//font-awesome-variables";

.dropzone {
  width: 100%;
  cursor: pointer;

  // Unfortunately we need to disable pointer events for react-dropzone
  // to correctly register onDragLeave events.
  * {
    pointer-events: none;
  }
}

.dropHere {
  width: 100%;
  background-color: #5C6B78;
  height: 36px;
  line-height: 36px;
  text-align: center;

  &::before {
    transition: 0.1s ease-out opacity;
    content: '\f0ee';
    font-size: 1rem;
    color: $ui-green;
    margin-right: 0.5rem;
    @include fontAwesome;
  }
}

.file {
  display: none;
}
