@import '../../../style/colours';

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 6px;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  font-size: 1rem;
  cursor: pointer;
  color: $color-black-100;

  &:hover {
    background: $color-background-mid;
  }
}
