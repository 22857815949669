@import "../../../../style/vendor/_font-awesome-variables.scss";

.dropItem {
  padding: 0.5em 0;
}

.tick {
  @include fontAwesome;
}

.metaInfo {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  opacity: 0.5;
}
