@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/vendor/font-awesome-variables';

.search {
  position: relative;

  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-search);
    color: $color-black-100;
    position: absolute;
    display: block;
    top: 0.9rem;
    left: 1rem;
  }

  input {
    @include fontLight;

    font-size: 1rem;
    border-radius: 43.5px;
    border: solid 2px $color-background-dark;
    padding: 10px 0 10px 40px;
    width: 100%;
    -webkit-appearance: textfield; // This is another fix for Safari (classic) where inputs with a type value of search do not honour styling

    &:focus {
      outline: none;
      border: solid 2px $color-black-100;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 10px;
    }
  }
}
