.non-graphql-dashboard {
  .widget-inner {
    .geckometer {
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .scale-svg {
        height: 100%;
        max-width: 100%;
        flex: 1 1 auto;
      }
    }
  }
}
