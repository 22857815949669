@import '../../../../src/ui/style/colors';

.panel {
  color: $color-grey-100;

  * {
    box-sizing: border-box;
  }
}

.panelHeader {
  position: relative;
  text-align: center;
  font-size: 1.125rem;
  padding: 10px 0 20px;
}

.panelFooter {
  margin-top: 10px;
}

.drilldownBack {
  position: absolute;
  left: -20px;
  top: -10px;
  height: 56px;
  width: 56px;
  font-size: 1rem;
  -webkit-appearance: none;
  border: none;
  outline: none;
  color: $color-grey-80;
  cursor: pointer;
  background: transparent;

  &:hover {
    color: $color-black-100;
  }
}
