@import "../../../style/vendor/mq";

.IntegrationsGrid {
  display: grid;
  column-gap: 3rem;
  row-gap: 1.5rem;
  padding-bottom: 1.5rem;

  // On small screens we'll start by only showing a single column
  // and then progressively add more columns on wider screens.
  grid-template-columns: 1fr;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: desktop) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq($from: wide) {
    grid-template-columns: repeat(4, 1fr);
  }
}
