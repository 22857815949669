@import '../../../src/ui/style/colors';

.wedge {
  padding: 0 0.5rem;
  height: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: $color-grey-10;
  background-color: $color-grey-80;
  text-transform: uppercase;
  border-radius: 4px;
}
