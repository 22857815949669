@import '../../../src/ui/style/typography';
@import '../../../src/ui/style/colors';
@import '../../../style/font/visuelt/include';

// add 1em so that we have a minimum padding
$outerPadding: calc(1em + 2.4vw);

.page {
  position: absolute;
  height: 100%;
  width: 100%;
}

.table {
  @include fontBold;

  width: 100%;
  height: 100%;

  color: white;
  background-color: $color-black-90;
  border-spacing: 0px;
}

:global(.faulty-text-rendering) .table {
  font-family: Arial, sans-serif;
}

.link {
  display: inline-block;
  padding: 50px 50px 0 50px; // fallback padding
  padding: $outerPadding $outerPadding 0 $outerPadding;
}

.logo {
  width: 320px; // fallback width
  width: calc(14em + 5vw); // have a minimum width of 14em
}

.error {
  background-color: $color-negative;

  padding: 40px; // fallback padding
  padding: 2vw;

  font-size: 40px; // fallback font size
  font-size: calc(1em + 1vw);
  text-align: center;
}

.content {
  @include fontBold;
  text-align: center;
  vertical-align: middle;
}

.descriptionTitle {
  margin-top: -40px; // fallback margin
  margin-top: calc(-1em - 2.4vw);

  margin-bottom: 30px; // fallback margin
  margin-bottom: 1vw;

  font-size: 70px; // fallback font size
  font-size: calc(2.5em + 2.5vw);

  font-weight: 500;
}

.descriptionText {
  font-size: 40px; // fallback font size
  font-size: calc(1em + 1vw);
  margin-top: 8vh;
}

.pinCode {
  position: relative;
  display: inline-block;
  padding: 0.2em 0.2em 0.15em 0.5em;
  border-radius: 0.15em;
  background-color: $color-black-100;
  overflow: hidden;

  margin-top: 80px; // fallback margin
  margin-top: 1vw;

  font-size: 110px; // fallback font size
  font-size: calc(3em + 4vw);

  font-weight: 500;
  letter-spacing: 0.3em;
}

.placeholderPin {
  opacity: 0;
  color: $color-black-100;
}
