@import '../ui/style/colors', '../ui/style/typography';

.heading {
  background-color: #fff;

  h2 {
    // Heading component has a bottom margin
    margin: 0;
  }
}

.panel {
  background-color: $color-background-light;
  height: 100%;

  >div {
    height: 100%;
  }
}

.footer {
  @include fontSmall;
  background-color: #fff;
  border-top: 1px solid $color-grey-20;
  color: $color-black-70;

  button {
    @include fontSmall;
    color: $color-black-70;
    padding: 0;
    margin: 0;
    height: auto;
  }
}
