.previewWrapper {
  flex: 1 0 auto;
  min-height: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: opacity .3s;

  background-image: var(--widget-background-image);
}

.title {
  padding: 0 var(--widget-side-padding, 14px);
}

.previewMode {
  &.previewWrapper {
    background-color: var(--container-background-color);
    background-image: var(--widget-background-image);
    border-radius: 10px;
    padding: 1rem;
    color: var(--text-color);
  }

  & .title {
    font-size: 1.25rem;
    padding-bottom: 1rem;
  }
}

.visualisationNotComplete {
  opacity: 0.5;
}
