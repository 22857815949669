@import "../../../style/vendor/mq";
@import "../../../style/colours";

.popover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 10001;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .8);

  .container {
    position: fixed;
    bottom: 0;
    z-index: 10002;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow-y: scroll;
    padding: 1rem;
    box-sizing: border-box;
  }

  @include mq($from: desktop) {
    &.sidePanel {
      .container {
        display: block;
        position: static;
      }

      .panel {
        display: flex;
        flex-direction: column;

        position: fixed;
        z-index: 1000;
        margin: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 630px;
        max-width: 100%;

        border-radius: 0;
      }
    }
  }

  .panel {
    z-index: 2;
    max-width: 640px;
    margin: 60px auto;

    background-color: $popover-background-colour;
    color: $color-black-100;
    border-radius: 4px;

    -webkit-overflow-scrolling: touch;

    cursor: default;
  }

  /*
   * TRANSITIONS
   */
  &:global(.popover-enter) {
    background-color: rgba(0, 0, 0, 0);

    .panel {
      transform: translateX(100%);
    }
  }

  &:global(.popover-enter-active) {
    background-color: rgba(0, 0, 0, 0.8);
    transition: background-color 0.2s ease-out;

    .panel {
      transform: translateX(0%);
      transition: transform 0.2s ease-out;
    }
  }

  &:global(.popover-exit) {
    background-color: rgba(0, 0, 0, 0.8);

    .panel {
      transform: translateX(0%);
    }
  }

  &:global(.popover-exit-active) {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s ease-out;

    .panel {
      transform: translateX(100%);
      transition: transform 0.2s ease-out;
    }
  }
}
