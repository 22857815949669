@import "../../../../style/vendor/_font-awesome-variables.scss";

.dropdownWrapper {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
}

.dropdownLabel {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  padding-top: 7px;
  padding-left: 15px;
  text-align: left;

  &:after {
    /* These styles are copied from dropdown-input.styles.css to match
     the style of the other dataset config dropdowns. */
    pointer-events: none;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
    line-height: 14px;
  }
}
