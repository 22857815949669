@import "../../../style/_colours.scss";

.tooltipWrapper {
  position: relative;
  cursor: pointer;
}

.messageWrapper {
  position: absolute;
}

.hoverTarget {
  position: relative;
  z-index: 2;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  z-index: 1;

  // Position relative to the right (rather than the left)
  // to avoid scrollbars in IE Edge
  // http://stackoverflow.com/questions/27000492/css3-transform-property-working-differently-in-internet-explorer
  right: 50%;
  transform: translateX(50%);

  white-space: pre;
  margin-bottom: 5px;
  padding: 6px 10px;
  text-align: center;
  justify-content: space-around;
  line-height: normal;
  font-size: 13px;
  z-index: 9999;
  background-color: $dark-grey;
  color: #fff;
  border-radius: 3px;

  /* Triangle above tooltip */
  &:after {
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid $dark-grey;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  &.isLight {
    background-color: $light-grey;
    color: #3e4553;

    &:after {
      border-top-color: $light-grey;
    }
  }
}
