@import "../../../style/vendor/font-awesome-variables",
  "../../../style/colours";

.wrapper {
  flex: 0 1 auto;

  overflow-y: scroll;
  z-index: 1;
  list-style: none;
  padding: 0;
  border-radius: 6px;
  border: 1px solid $pinstripe-grey;
  background-color: #FFFFFF;
}

.files {
  width: 100%;
  border-radius: 6px;
  background-color: #FFFFFF;
}

.noResult {
  text-align: center;
  cursor: default;
  padding: 1rem;
  background-color: $lightest-day-grey;
}
