.service-header {
  padding: 20px 0;

  img {
    width: 48px;
    height: 48px;
    float: left;

    border-radius: 4px;
  }

  .heading {
    display: inline-block;
    padding: 8px 15px;
  }
}
