#dashboard-selection {
  .form-field {
    padding: 0;

    > label {
      background-color: #d1d1d1;
      padding: 18px 12px;
      margin-bottom: 0;
    }

    .chosen-single {
      border-radius: 0 0 4px 4px;
    }
  }
}
