@import '../../../../../src/ui/style/typography';
@import '../../../../../style/colours';

.horizontalNumberContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  align-items: baseline;
  box-sizing: border-box;
  bottom: 0;
  overflow: hidden;

  &:hover {
    .label {
      opacity: 1;
    }
  }
}

.primaryNumberLayout {
  flex: 0 0 auto;
}

.primaryNumber {
  @include fontMedium;

  display: inline-block;
}

.labelLayout {
  display: block;
  flex: 0 0 auto;
  line-height: normal;
}

.secondaryVisualisationLayout {
  margin-left: auto;
}

.noDataLabel {
  color: var(--text-color);
  opacity: 0.5;
}
