@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

@mixin planFeaturesExceededWarningText {
  @include fontBold;

  display: inline-block;
  vertical-align: middle;
  padding: 0.25rem 1rem 0.25rem 1rem;
  margin-left: 12px;
  border-radius: 6px;
  background-color: rgba($color-background-mid, 0.2);
  border: none;
  transition: background-color 100ms;
  color: #ffffff;
  cursor: pointer;
}

.mastheadButton {
  @include planFeaturesExceededWarningText;

  &:hover {
    border: none;
    background-color: rgba($color-background-mid, 0.6);
    color: #ffffff !important; // overwrite global css
  }
}

.mastheadText {
  @include planFeaturesExceededWarningText;
}

.planFeaturesExceededModal {
  max-width: 520px;

  h3 {
    text-align: center;
  }

  ul {
    list-style-type: disc;
    padding-left: 25px;
  }
}

.buttons {
  text-align: center;
}

.support {
  font-size: 0.8rem;
  color: $color-black-70;
  text-align: center;

  button {
    font-size: 0.8rem;
    color: $color-black-70;
    padding: 0;
    height: auto;
  }
}
