@import '../../../../../style/colours';
@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.spreadsheetsConnector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  a {
    @include fontBold;
    color: $color-black-100;
    text-decoration: underline;
  }
}

.header {
  display: flex;
  flex-direction: row;
  text-align: left;
  line-height: 1.25;
  background-color: #fff;

  h3 {
    margin: 1.5rem 1rem 0.2rem;
    font-size: 1.25rem;
  }

  p {
    line-height: 1.25;
    font-weight: 300;
    padding-bottom: 1rem;
    margin: 0 1rem 0.5rem;
  }
}

.fileSelectionHeader {
  padding: 1rem 1rem 1rem 0;
  align-items: center;
  border-bottom: 1px solid $pinstripe-grey;

  h3 {
    @include fontMedium;
    margin: 0 0 0 1rem;
  }
}

.serviceIcon {
  margin: auto 0 auto 1rem;
}

.body {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;

  line-height: 1.25;
  text-align: left;
}

.lSidebar {
  background-color: $color-background-mid;
  padding: 1rem;

  &:global(.initialState) {
    background-color: transparent;
  }
}

.lSwitchingService {
  margin-top: 4rem;
  width: 100%;
}

.content {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  padding: 1rem;
  overflow: auto;

  > form {
    margin: 0 0 1rem;
  }
}

.errorWrapper {
  margin-bottom: 15px;
  max-width: 518px;
}
