@import '../../../../style/_colours.scss';
@import '../../../../style/vendor/_font-awesome-variables.scss';
@import '../../../../src/ui/style/colors';

$indent-width: 41px;
$form-element-margin: 14px;
$circle-size: 9px;

.nestedFormField {
  position: relative;
  padding-left: $indent-width;

  &::before {
    position: absolute;
    top: -#{$form-element-margin};
    left: floor(calc($indent-width / 2));

    line-height: 69px;
    height: calc(50% + #{$form-element-margin});
    width: 1px;

    background-color: $color-background-dark;
    color: $color-background-dark;

    font-size: $circle-size;
    font-weight: 900;

    @include fontAwesome;
    content: fa-content($fa-var-circle);
    text-indent: 0 - calc(($circle-size - 1px) / 2);
  }

  &.followingSibling::before {
    top: calc(-50% - #{$form-element-margin});
    line-height: 108px;
    height: calc(100% + #{$form-element-margin});
  }
}
