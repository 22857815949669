@import "../../../style/colours";
@import "../../../style/vendor/font-awesome-variables";

@mixin ribbon ($colour, $bg-colour) {
  border-style: solid;
  border-width: 0 49px 49px 0;
  border-color: transparent $bg-colour transparent transparent;
  &::after {
    position: absolute;
    left: 28px;
    top: 6px;
    font-size: 18px;
    @include fontAwesome;
    content: fa-content($fa-var-exclamation-circle);
    color: $colour;
  }
}

.dotItem {
  display: inline-block;
  font-size: initial;
  line-height: 0;

  &:first-of-type .dot {
    margin-left: 0;
  }
}

.dot {
  border: none;
  margin: 0;
  padding: 0;
  width: 12px;
  height: 12px;

  margin-left: 6px;
  margin-top: -1px;
  @include variable(background-color, --text-color, $gray_athens);
  border-radius: 50%;
  cursor: pointer;

  opacity: 0.2;

  transition: background-color 150ms ease-in-out;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px #434343;
  }
}

.activeDot {
  opacity: 1;
}

.pagination {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
}

.textPagination {
  font-size: 1.125rem;
  @include variable(color, --secondary-text-color, rgba(255, 255, 255, 0.55));
}

.alert, .info {
  position: absolute;
  top: 0;
  right: 0;
}

.alert {
  @include ribbon(white, $orange-beta);
}

.info {
  @include ribbon($secondary-grey, $mid-grey);
}
