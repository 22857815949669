@import '../../style/colors';

.Dropdown {
  display: block;
  position: relative;
}

.label {
  display: inline-block;
  color: $color-grey-80;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.button {
  position: relative;
  appearance: none;
  font-size: 1rem;
  color: $color-black-100;
  font-family: inherit;
  width: 100%;
  border: 1px solid $color-background-dark;
  border-radius: 6px;
  padding: 0.5rem 1.75rem 0.5rem 1rem;
  outline: none;
  box-sizing: border-box;
  text-align: left;
  background: white;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:focus-visible,
  &.isOpen {
    border-color: $color-black-100;
  }

  &:disabled {
    background-color: $color-background-light;
  }
}

.dropdownIcon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 12px;
  color: $color-black-70;
  padding: 0 12px;
  display: flex;
  align-items: center;
}

.placeholder {
  opacity: 0.5;
}

.boxPositioner {
  z-index: 9999;
}

.asterisk {
  font-size: 0.5rem;
  color: $color-black-100;
  position: absolute;
  top: 0;
  right: 1rem;
  bottom: 0;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
