@import '../../../style/colours';
@import '../../../style/shared/animations';
@import '../../../src/ui/style/typography';

.modal {
  display: inline-block;
  background-color: $light-grey;
  color: $color-black-100;

  border-radius: 4px;
  border: 1px solid $secondary-grey;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.heading {
  @include fontMedium;
  font-size: 1rem;
  background-color: #fff;
  padding: 0.8rem 1rem;
}

.content {
  font-size: 0.875rem;
  padding: 1rem;
  min-width: 250px;
}

.iconContainer {
  margin-right: 0.5rem;
}
