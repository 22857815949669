@import '../../style/colours';
@import '../../src/ui/style/colors';

.items {
  a {
    color: $color-black-100;
    border-bottom: 1px solid $color-black-100;
  }

  a:hover {
    color: $color-black-100;
    opacity: 0.6;
  }
}

.pubDate {
  opacity: 0.5;
}

.article {
  margin-bottom: 4rem;
}

.formatting {
  pre {
    font-family: monospace;
    background-color: $light-grey;
    padding: 0.5rem;
  }

  ol,
  ul {
    list-style: initial;
    padding-left: 1rem;
  }

  li {
    padding-bottom: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  img {
    max-width: 100%;
  }
}

.notificationPill {
  display: inline-block;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  background-color: $ui-green;
}
