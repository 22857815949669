@import '../../style/colors';
@import '../../style/typography';
@import '../../../../style/vendor/font-awesome-variables';

:local(.ruleBasedVisualisationPicker) {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

:local(.visualisationIcon) {
  background-color: $color-black-100;
  border-radius: 6px;
  border: none;
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 1.25rem;
}

button {
  &.visualisationIcon {
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    position: relative;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      background: $color-grey-20;
      color: $color-black-100;
    }

    &:not(:disabled) {
      &:hover {
        background: $color-positive;

        span {
          display: none;
        }

        &:before {
          @include fontMedium;
          @include fontAwesome;
          content: fa-content($fa-var-plus);
        }
      }
    }
  }
}

:local(.visualisation) {
  display: inline-block;
  list-style: none;
  padding: 0 5px;
  position: relative;
  z-index: 1;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  &:hover {
    :local(.tooltipContainer) {
      display: block;
    }
  }
}

:local(.tooltipContainer) {
  bottom: 100%;
  display: none;
  left: 50%;
  padding-bottom: 20px;
  position: absolute;
  transform: translateX(-50%);
}

:local(.tooltip) {
  background: white;
  border-radius: 6px;
  border: 1px solid $color-background-dark;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: $color-grey-100;
  font-size: 1rem;
  padding: 20px;
  position: relative;
  text-align: left;
  width: 300px;
}

:local(.tooltipDescription) {
  padding: 20px 0;
  margin-bottom: 0;

  b {
    font-weight: 500;
  }
}

:local(.tooltipPointer) {
  height: 20px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 30px;

  &::after {
    background: white;
    border: 1px solid $color-background-dark;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
    content: '';
    display: block;
    height: 15px;
    transform-origin: top-left;
    transform: translateX(7px) translateY(-50%) rotate(45deg);
    width: 15px;
  }
}

:local(.rule) {
  @include fontMedium;

  margin-bottom: 18px;
  padding-left: 30px;
  position: relative;
  line-height: 1.5;
}

:local(.ruleIndicator) {
  align-items: center;
  background: $color-negative;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 18px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 4px;
  width: 18px;
  transform: translateY(-2px);

  &.passing {
    background: $color-positive;
  }
}
