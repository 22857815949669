@mixin btn {
  appearance: none;
  transition: background-color 0.2s;

  display: inline-block;
  padding: 6px 12px;
  margin: 0;

  border: none;
  border-radius: 3px;
  background-color: transparent;
  color: inherit;
  font: inherit;
  font-weight: normal;
  text-transform: none;
  outline: none;

  cursor: pointer;

  &[disabled] {
    opacity: 0.5;

    cursor: default;
  }
}
