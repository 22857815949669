.sidebar {
  cursor: pointer;
}

.initialState {
  width: 100%;
}

.lItem {
  margin-bottom: 1rem;
}

.lItemInitial {
  padding: 1rem 0;

  &:first-of-type {
    padding-top: 0;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}
