@import '../../style/colors';
@import '../../style/typography';

.inputLabel {
  @include fontSmall;
  color: $color-grey-80;

  display: block;
  margin: 0 0 0.1rem 0;
}
