.passwordToggler {
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;

  .togglerLabelText, input {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }

  .togglerIcon {
    cursor: pointer;
    opacity: .5;
    font-size: 1rem;

    &:hover {
      opacity: 1;
    }
  }

  input:focus + span {
    opacity: 1;
  }
}

.passwordContainer {
  display: flex;
  position: relative;
}

.passwordInput {
  width: calc(100% - 25px);
}

.container {
  legend {
    display: none;
  }

  fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.row {
  width: 48%;
}

