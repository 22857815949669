@import "./animations",
  "../colours",
  "../typography",
  "../vendor/font-awesome-variables";

// TODO remove once we are using css modules for all buttons

@mixin bttn {
  .bttn {
    transition: 0.1s background-color;

    display: inline-block;
    padding: 6px 10px;

    box-sizing: border-box;

    background-color: transparent;
    border: none;
    border-radius: 3px;

    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    vertical-align: middle;

    &, &:visited, &:hover, &:link, &:focus {
      color: #fff;
      border: none;
    }

    &.fa::before {
      display: inline-block;
      margin-right: 6px;
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }

    &.bttn-bright {
      background-color: $ui-green;

      &:hover, &:focus {
        background-color: #648f2a;
      }
    }

    &.bttn-dull {
      background-color: $murky-grey;

      &:hover, &:focus {
        background-color: darken($murky-grey, 10%);
      }
    }

    &.bttn-submit {
      background-color: $btn-submit-background-colour;
      color: $btn-submit-colour;

      &:hover:not(:disabled) {
        background-color: $btn-submit-hover-background-colour;
        color: $btn-submit-colour;
      }

      &:disabled {
        cursor: default;
      }
    }

    &.bttn-secondary {
      background-color: $btn-secondary-background-colour;
      color: $btn-secondary-colour;

      &:hover {
        background-color: $btn-secondary-hover-background-colour;
        color: $btn-secondary-colour;
      }
    }

    &::before {
      font-weight: normal;
      font-size: 14px;
    }

    &.bttn-add {
      &::before {
        @include fontAwesome;
        content: fa-content($fa-var-plus);
        margin-right: 10px;
      }
    }
  }
}
