.non-graphql-dashboard {
  .highchart-widget .widget-canvas {
    text-shadow: none !important;
  }
}

.highandlinechart-visualisation, .highchart-visualisation {
  height: 100%;
}

.highandlinechart-visualisation .chart {
  position: absolute; // Fill the available space
}
