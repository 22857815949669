@import '../../style/colors';

:local(.SearchableSelect) {
  font-size: 1rem;
}

:local(.DropdownIndicator) {
  font-size: 12px;
  color: $color-black-70;
  padding: 0 12px;
}
