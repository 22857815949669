.DatasetConfigPage {
  position: absolute;
  margin-top: 96px;
  width: 100%;
  height: calc(100% - 96px);
  bottom: 0;
  top: 0;
}

.loading {
  display: flex;
  align-items: center;
  height: 100vh;
}
