@import '../../src/ui/style/colors';

.containerLayoutSharePage {
  width: 100vw;
  height: 100vh;
  position: relative;

  background-color: $color-black-100;
  background-color: var(--dashboard-background-color);
}

.isIdle {
  cursor: none;
}

.containerLayoutDashboardLayout {
  position: absolute;
  width: 100vw;
  height: calc(100vw * 9 / 16); // Maintains aspect ratio of 16:9

  max-height: 100vh;
  max-width: calc(100vh / 9 * 16); // Scale dashboard to fit height

  // Centre dashboard horizontally and vertically
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
