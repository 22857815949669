.funnel-visualisation {
  height: 100%;
  display: flex;
  align-items: center;

  ul {
    flex: 1;
  }

  .item {
    display: flex;
  }

  .indicator {
    overflow: hidden;
    flex: 1;

    .inner { float: right }
  }

  .value {
    width: 217px;
    padding: 5px 0 0 10px;

    .main {
      font-size: 18px;
      display: block;
    }

    .t-secondary {
      font-size: 12px;
    }
  }

  li {
    padding-bottom: 1px;
  }
}
