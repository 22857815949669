@import '../../../../src/ui/style/colors.scss';

.loadingPositioner {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
}

.mainError {
  color: $color-negative;
}

.retryBtn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
