@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';

.ipAddressSettings {
  margin: 20px 0 0 0;
}

.inputContainer {
  display: flex;
  margin: 9px 0 0 0;
}

.slackScheduledPostGate {
  @include fontLight;
  display: flex;
  align-items: center;
  color: $color-black-100;
  margin-top: 5px;
}

.slackScheduledPostGateText {
  margin-left: 0.25rem;
  font-size: 0.875rem;
}
