@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';
@import '../../../../style/shared/animations';

.SharingLinkView {
  color: $color-black-100;
  background-color: $color-background-mid;
  position: relative;
}

.button {
  display: flex;
  flex-direction: column;
}

.toggle {
  padding-top: 1rem;

  li[role='menuitem'] {
    cursor: pointer;
  }
}

.info {
  @include fontLight;
  text-align: center;
}

.error {
  color: $color-negative;
}

.shareIllustration {
  margin: 0 auto 1rem auto;
}

.iconContainerLink {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  color: $color-black-100;
}

.shareLinkText {
  @include fontLight;
}
