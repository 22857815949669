@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.integrationDatasetSideConfig {
  display: flex;
  flex-direction: column;

  background-color: $color-background-light;
  width: 480px;
}

.integrationDatasetError {
  background-color: white;
  padding: 10px;
  width: 480px;
}

.integrationDatasetSetupCard {
  margin: 11px 15px;
}

.paddingFlexLayout {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  padding: 1rem;
}

.filterbarFlexLayout {
  flex: 0 0 auto;
  margin: -0.5rem -2rem 0.5rem;
}

.previewContainer {
  flex: 1;
}

.previewPlaceholder {
  background: #272953;
  border-radius: 10px;
  height: 100%;
  padding: 1rem;
  color: var(--text-color);
  font-size: 1.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: block;
  }

  div {
    line-height: 1.5;
    margin: 0 auto;
    text-align: center;
    width: 65%;

    img {
      margin-top: 2rem;
    }
  }
}
