// Breakpoints
$wide: 1910px;
$desktop: 1000px;
$tablet: 800px;
$mobile: 340px;

$mq-breakpoints: (
  (mobile  $mobile)
  (tablet  $tablet)
  (desktop $desktop)
  (wide    $wide)
);

$mq-static-breakpoint: desktop;
