@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.l-configure-widget {
  @include fontLight;

  color: $color-black-100;

  .configure-widget-container {
    max-width: 1042px;
    min-width: $mobile;
    margin: 0 auto;
    padding: 0 20px;

    input[type='text'],
    input[type='search'],
    input[type='password'],
    textarea {
      display: inline-block;
      font-size: 1rem;
      font-family: inherit;
      margin: 0;
      padding: 9px 25px 9px 15px;
      color: $color-grey-100;
    }
  }

  .service-header {
    padding-top: 40px;
  }

  .account-form {
    position: relative;
  }

  .service-accounts {
    position: relative;
    z-index: 1;

    .select-field {
      position: relative;
      z-index: 2;
    }
  }

  .add-account {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    height: 0;
    padding-left: 80px;

    text-align: right;

    .btn {
      position: relative;
      z-index: 1;
      width: 40%;

      border-radius: 0 4px 0 0;

      line-height: 1.6;

      &:before {
        @include fontAwesome;
        content: fa-content($fa-var-plus);
        font-weight: normal;
        font-size: 14px;
        margin-right: 0.75rem;
      }
    }
  }

  .configuration {
    margin-bottom: 30px;
    width: 100%;
  }

  @include mq($from: tablet) {
    .service-accounts .select-field {
      width: 40%;
    }

    .add-account .btn {
      width: 20%;
      border-radius: 6px;
    }
  }

  @include mq($from: desktop) {
    .configuration {
      min-width: 620px;
      float: left;
      padding-right: 80px;
    }
  }

  @include mq($from: 1400px) {
    .service-accounts .select-field {
      width: 40%;
    }

    .add-account .btn {
      width: 20%;
    }
  }

  @include mq($from: wide) {
    .service-accounts .select-field {
      width: 40%;
    }

    .add-account {
      padding-left: 100px;
    }

    .add-account .btn {
      width: 20%;
    }
  }
}
