@import '../colours';
@import '../../src/ui/style/colors';

$masthead-height: 96px;
$dashboard_bg: #101214;

:root {
  --dashboard-footer-color: #101214;
  --background-color: #101214;
  --title-color: rgba(255, 255, 255, 0.92);
  --text-color: rgba(255, 255, 255, 0.86);
  --secondary-text-color: rgba(255, 255, 255, 0.77);
  --widget-background-color: #2a2a2a;
  --chart-axis-color: rgba(255, 255, 255, 0.13);
  --chart-axis-color-strong: rgba(255, 255, 255, 0.2);
  --selected-widget-header-color: #4d4f50;
  --dragger-color: rgba(
    255,
    255,
    255,
    0.05
  ); /* This is the guide colour when dragging a widget */
  --widget-loader-color: rgba(
    255,
    255,
    255,
    0.05
  ); /* Color of the loading indicator in the visualisation */
  --widget-shimmer-color: rgba(255, 255, 255, 0.09);

  /* Widget margins and paddings */
  --widget-side-padding: 14px;

  /* Geckometers */
  --gauge-needle-color: #e1e1e1;

  --chart-threshold-color: #39393b;
  --number-threshold-text-color: #2a2a2a;

  /* Map widget */
  --map-subunit-color: #4a4a4a;
  --map-single-point-color: #38cdff;
}

body {
  background-color: $color-background-light;

  &.classicboard {
    @include variable(background-color, --background-color, $dashboard_bg);
  }

  &.whiteBackground {
    background-color: white;
  }
}

.dashboard-footer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 1920px; // Width of a 1080p display
  transform-origin: bottom left;
  transform: scale(1); // Will be altered by gecko-js to fit footer to screen.
}

.is-fullscreen .dashboard-footer,
.l-sharing .dashboard-footer {
  display: flex;
}

#dashboard-wrapper {
  position: relative;
  z-index: 1;
}

#dashboard {
  position: relative;
  margin: 5px auto;
}

.is-fullscreen #dashboard {
  transform-origin: top;
}

#dashboard-wrapper.is-fullscreen,
.l-sharing #dashboard-wrapper {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  #dashboard-resizable-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: scroll;
    z-index: 1;
  }
}

#masthead {
  height: $masthead-height;
  position: relative;
  z-index: 2001; // 1 higher than the side panels

  .masthead {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 3;
  }
}

.is-mobile {
  font-size: 12px;

  #masthead {
    height: 0;
  }
}

.loading-overlay.dashboard {
  @include variable(background-color, --background-color, $dashboard_bg);
  text-align: center;

  p {
    margin: 350px auto;
    display: inline-block;
    border-radius: 8px;
    // TODO: Use css loader only
    background: url('https://d2s28ygc2k7998.cloudfront.net/polecat-css-icons/dashboard-loading-dark.gif')
      12px center no-repeat #313131;
    color: #fff;
    padding: 21px 12px 20px 58px;
    line-height: 1.3;
    text-align: left;
  }
}

// Hide Intercom when in mobile and sharing mode so Ben doesn't pop up on TV
// .intercom-lightweight-app is a class added by Intercom itself, which is loaded via
// Segment.io
.l-sharing .intercom-lightweight-app,
.is-mobile .intercom-lightweight-app {
  display: none;
}

@import 'widgets', 'widget-container', 'copy-widget', 'dashboard-config',
  'dashboard-copy-modal', 'no_dashboards', 'dragging', 'full-screen',
  'widgets-polymorphic/text', 'widgets/polymorphic', 'tv-mode';
