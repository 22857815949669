@import '../../../../../src/ui/style/colors';

.EditFiltersCard {
  border: solid 1px $color-grey-20;
  border-radius: 6px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 1rem;
  border-left: 6px solid $color-black-100;
}

.title {
  flex: 1;
  line-height: 1.2;
}

.filterIcon {
  margin-right: 0.5rem;
  position: relative;
  top: 1px;
}
