@import "../../../style/vendor/_font-awesome-variables.scss";
@import "../../../style/_colours.scss";

.refreshInterval {
  display: flex;
  margin-bottom: 16px + 60px; // to ensure this is visible when scrolling in IE.
  font-size: 14px;

  div div { // Text Dropdown
    &:hover {
      opacity: 0.6;
    }
  }
}
