@import '../../src/ui/style/colors';

.loading legend {
  &::after {
    @include fontAwesome;
    content: fa-content($fa-var-spinner);
    margin: 0 10px;
    display: inline-block;
  }
}

legend,
fieldset {
  margin-bottom: 20px;
}

fieldset:disabled {
  input[type='text'],
  input[type='search'],
  input[type='password'],
  textarea,
  .select-field .chosen-single,
  .select-field .chosen-choices,
  .selection-group {
    color: $field-disabled-colour;
    background-color: $color-background-mid;
    border-color: $color-background-mid !important;
  }
}
