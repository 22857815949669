.label {
  margin-left: 5px;
}

.connectionIcon {
  margin: 2px;
  height: 32px;
  width: 32px;
}

.editPanel {
  // We need to reset as the panel is in the list item
  // sets a font-size of 0.9rem which messes with the requirements
  font-size: initial;
}

.engineColumn{
  width: 180px;
}
