// TODO: when removing 'graphql' feature flag remove these global widget-body styles
.non-graphql-dashboard {
  .widget-body.bulletchart-widget {
    padding: 0 14px 14px 14px;
  }

  .tv-mode .widget-body.bulletchart-widget {
    padding: 0 26px 18px 18px;
  }
}

.bullet-visualisation {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .graph {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    &.horizontal {
      flex-direction: row;

      .graph-name {
        height: 30px;
        width: 175px;
        text-align: right;
        padding: 15px 10px;
      }

      .graph-ind {
        display: flex;
      }
      .ind { height: 59px; }
      .graph-bar { top: 23px; height: 14px; }
      .graph-line { width: 5px; height: 34px; top: 13px; }

      .graph-scale {
        ul {
          display: flex;

          li {
            flex: 1;
            text-align: center;
            background: url('https://d2s28ygc2k7998.cloudfront.net/polecat-css-icons/widgets/graph-scale-hor.gif') no-repeat center 0;
            padding-top: 10px;
          }
        }

        // Calculate the width of each scale item based on the number of items
        // present on the axis then shift the scale (the ul list) back half
        // the length of one item so that the center of the item is in line
        // with the front of the chart
        @for $i from 2 through 8 {
          $item-width: calc(100% / ($i - 1));

          .p-#{$i} {
            margin-left: 0 - calc($item-width / 2);
            width: calc(#{$item-width * $i} - 1px);
          }
        }
      }
    }

    &.vertical {
      $tick-offset: 4;
      float: left;
      max-width: 101px;

      .graph-body {
        display: flex;
        width: 100%;
        margin: 0 auto;
      }

      .graph-name {
        text-align: center;
        padding-bottom: 20px;
        height: 38px;
        overflow: hidden
      }

      .graph-ind {
        width: 60px;
      }

      .ind { border-top:0; }

      .graph-bar {
        width:14px;
        left:23px;

        .graph-measure {
          width: 14px;
          display: block;
          position: absolute;
          bottom: 0
        }
      }

      .graph-line {
        width: 34px;
        height: 5px;
        left: 13px;
      }

      .graph-scale {
        order: -1;
        position: relative;
        top: -#{$tick-offset}px;
        width: 41px;

        ul {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: absolute; /* For some reason Safari needs this for the flex container to fill vertically */
        }

        li {
          flex: 1;
          background: url('https://d2s28ygc2k7998.cloudfront.net/polecat-css-icons/widgets/graph-scale-ver.gif') no-repeat right #{$tick-offset}px;
          padding-right: 19px;
          text-align: right;
        }

        // Same principle as the horizontal but as this is positioned from the
        // top the scale is always offset to the same amount and the lines in
        // the axis items are set the same amount from the top of the list item
        // so that the text shows base aligned with the line image
        @for $i from 2 through 8 {
          $item-height: calc(100% / ($i - 1));

          .p-#{$i} {
            height: calc(#{$item-height * $i} - 1px);
          }
        }
      }
    }
  }

  .graph-name span { display: block }
  .graph-ind { position: relative }
  .ind-red {
    @include variable(background-color, --negative-color, $color-negative);
  }
  .ind-amber {
    @include variable(background-color, --caution-color, $level_bg);
  }
  .ind-green {
    @include variable(background-color, --positive-color, $color-geckoboard-green);
  }
  .graph-bar {position:absolute; background: rgba(255, 255, 255, 0.25); z-index:1; overflow:hidden; }
  .graph-measure {height:14px; background:#1a1c1d;display:block; float:right;}
  .graph-line {position:absolute; background:#1a1c1d; z-index:2;}
  .graph-scale ul {list-style:none;}
  .graph-scale li {font-size:11px;}
}
