@import '../../../style/colours', '../style/typography';

.buttonContainer {
  display: flex;
  flex-direction: row;

  &.addingtolist {
    flex-direction: row-reverse;
  }
}

.subtleActionButton {
  @include fontMedium;

  display: flex;
  gap: 8px;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  padding: 8px;
  cursor: pointer;
  color: $color-black-100;
  line-height: 1.2;

  &:hover {
    background: $color-background-mid;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-black-100;
  }
}
