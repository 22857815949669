.ragcolumn-visualisation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .column {
    width: 70px;
    float: left;
    height: 128px;

    .negative {
      @include variable(background-color, --negative-color, $color-negative);
    }
    .positive {
      @include variable(background-color, --positive-color, $color-geckoboard-green);
    }
    .level {
      @include variable(background-color, --caution-color, $level_bg);
    }

    &.isLarge {
      height: 260px;
      width: 150px;
    }
  }

  table {
    width: 118px;
    margin-left: 80px;
    display: block;

    tr {
      border-bottom: solid 1px #363636;
      @include variable(border-color, --neutral-color, #363636);

      &:last-child { border-bottom: none }
      &:first-child { td { padding-top: 0 } }
    }

    td {
      overflow: visible;
      padding: 8px 0;

      &.number {
        max-width: 86px;
      }

      &.t-secondary {
        max-width: 30px;
        font-size: 12px;
        padding-left: 12px;
      }
    }

    &.isLarge {
      width: 268px;
      margin-left: 170px;

      td {
        padding: 20px 0;

        &.number {
          max-width: 115px;
        }

        &.t-secondary {
          font-size: 24px;
          padding-left: 20px;
          max-width: 53px;
        }
      }
    }
  }
}
