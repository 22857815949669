.ProIntegrationsModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
}

.image {
  margin-bottom: 1rem;
}

.content {
  margin-bottom: 10px;
}
