@import "../../style/vendor/mq";

$space-scale: (0, "0") (0.25, "0-25") (0.5, "0-5") (0.75, "0-75") (1, "1") (1.25, "1-25") (1.5, "1-5") (1.75, "1-75") (2, "2") (2.25, "2-25") (2.5, "2-5") (2.75, "2-75") (3, "3") (3.25, "3-25") (3.5, "3-5") (3.75, "3-75") (4, "4");

.flex {
  display: flex;
}

.inlineFlex {
  display: inline-flex;
}

.flexAuto {
  flex: 1 1 auto;
}

.flexShrink {
  flex: 0 1 auto;
}

.flexNone {
  flex: 0 0 auto;
}

.flexFill {
  flex: 1 0 0;
}

.flexEqualWidth {
  flex: 1;
}

.directionColumn {
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

@each $align in stretch, center, baseline, 'flex-start', 'flex-end' {
  .align-#{$align} {
    align-items: #{$align};
  }
}

@each $justify in center, 'space-around', 'space-between', 'flex-start', 'flex-end' {
  .justify-#{$justify} {
    justify-content: #{$justify};
  }
}

// Box
@for $i from 1 through 12 {
  .col-#{$i} {
    width: percentage(calc($i / 12));
  }
}

@for $i from 1 through 12 {
  @include mq($from: tablet) {
    .sm-#{$i} {
      width: percentage(calc($i / 12));
    }
  }
}

@for $i from 1 through 12 {
  @include mq($from: desktop) {
    .md-#{$i} {
      width: percentage(calc($i / 12));
    }
  }
}

@for $i from 1 through 12 {
  @include mq($from: wide) {
    .lg-#{$i} {
      width: percentage(calc($i / 12));
    }
  }
}

// Margins and paddings
@each $spacing in margin, padding {
  @each $v, $suffix in $space-scale {
    .#{$spacing}-#{$suffix} {
      #{$spacing}: #{$v}rem;
    }
  }

  @each $v, $suffix in $space-scale {
    @each $pos in top right bottom left {
      .#{$spacing}-#{$pos}-#{$suffix} {
        #{$spacing}-#{$pos}: #{$v}rem;
      }
    }
  }
}

@each $v, $suffix in $space-scale {
  .gutter-#{$suffix} {
    margin-right: -#{calc($v / 2)}rem;
    margin-left: -#{calc($v / 2)}rem;
  }
}
