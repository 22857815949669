@import "../../../../style/vendor/font-awesome-variables",
  "../../../../style/_colours";

.dropItem {
  outline: none;

  background-color: $lightest-day-grey;

  font-size: 16px;
  font-weight: 400;

  .small {
    font-size: 14px;
  }
}

.innerWrapper {
  display: flex;
  transition: 0.1s background-color ease-in-out;

  padding: 0.5rem 1rem;
  white-space: nowrap;
  color: $text-grey;

  &.hasLeftIcon {
    padding-left: 0;
  }
}

.iconLeftWrapper {
  display: flex;
  flex: 0 0 auto;
  align-self: center;
  width: 0.5rem; // same as padding left from wrapper
  font-size: 12px;
}

.content {
  flex: 1 0 auto;// fill content

  // Set a max-width to make long labels
  // line break.
  max-width: 100%;
}

.selected {
  &::before {
    display: block;
    @include fontAwesome;
    content: fa-content($fa-var-check);
  }
}

.unpadded {
  padding: 0;
}

.dividerTop {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.dividerBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.dropItem:first-of-type .dividerTop {
  border-top: none;
}

.pointer {
  cursor: pointer;

  .innerWrapper {
    &:hover, &:focus {
      background-color: #fff;
    }

    &:active {
      background-color: darken($lightest-day-grey, 4%);
    }
  }
}

// ItemWithRightHint
.hint {
  font-size: 0.9em;
  opacity: 0.5;
}
