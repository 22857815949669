.previewLayout {
  flex: 1 1 0;
  position: relative;
  /* prevent children (linechart, barchart svg) from
   * growing bigger than the parent
   */
  display: flex;
}

:global(.chart).tvMode {
  :global(.axis .tick text), :global(.legend text) {
    font-size: 21px;
  }
}
