@import '../../../../../src/ui/style/colors';

.filterList {
  margin: 0 0 13px 0;
}

.filterListItem {
  background: $color-background-dark;
  margin: 12px 0 0 0;
  padding: 11px 18px 11px 15px;
  border-radius: 6px;
  border: solid 1px rgba(92, 88, 117, 0.1);
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: darken($color-background-dark, 10%);
  }
}

.icon {
  margin-top: 1px;
}

.filterLabel {
  flex-grow: 1;
  margin: 0 10px 0 20px;
  word-break: break-all;
}

.deleteButton {
  float: right;
  position: relative;
  left: 20px;
  top: -12px;
  width: 50px;
  height: 48px;
  margin-bottom: -24px;
  -webkit-transition: 60ms background-color;
  transition: 60ms background-color;
  border: 0;
  border-radius: 0 6px 6px 0;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: darken($color-background-dark, 20%);
  }
}
