@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.config-form-wrapper {
  @include typography;
  @include bttn;
  @include fontLight;

  color: $color-black-100;
  background-color: $color-background-light;

  $field-margin-bottom: 14px;
  $fieldset-margin-bottom: 16px;
  height: 100%;

  form {
    fieldset {
      // overwrite user agent styles because it had
      // min-width: -webkit-min-content on chrome
      min-width: inherit;
      margin-bottom: $fieldset-margin-bottom;
    }

    .col-2 {
      & > .form-field {
        width: calc(50% - 7px);
        display: inline-flex;
        margin-right: $field-margin-bottom;
      }

      & > .form-field:nth-child(2) {
        margin-right: 0px;
      }
    }

    legend {
      @include fontBold;

      width: 100%;
      font-size: 1.25rem;
      font-weight: 400; // overwrite gecko-js styles
      margin-bottom: $field-margin-bottom;

      label {
        font-size: 1.25rem;
      }

      &::after {
        content: '';
        visibility: hidden;
        margin: 0;
      }

      .loading {
        float: right;
        margin-left: 16px;
      }

      strong {
        font-weight: inherit;
      }
    }

    label.fieldset-header {
      font-size: 16px;
      display: block;
      margin-bottom: $field-margin-bottom;
    }

    .form-field {
      display: flex;
      position: relative;
      flex: 1;
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: $field-margin-bottom;
      border-radius: 6px;
      border: 1px solid $color-background-dark;
      background-color: #ffffff;

      &.focus {
        border-color: $color-black-100;
      }

      &.inline {
        margin-bottom: 0;
      }

      label,
      .field-label {
        float: left;
        width: 40%;
        position: relative;
        z-index: 1;
        display: block;
        min-height: 41px;
        padding-left: 12px;
        border-radius: 6px 0 0 6px;
        background-color: $color-background-mid;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.editable-field-label {
          align-items: center;
          display: flex;
          height: 41px;
          overflow: visible;
        }

        .selection-label {
          flex: 1;
          margin-top: 2px;
          line-height: 1.2;
          max-width: calc(100% - 13px); // 13px - Width of the pip

          label {
            width: calc(100% - 10px);
            padding-left: 4px;
            padding-bottom: 2px;
            border-radius: 0;
            min-height: 0;
            line-height: inherit;
            font-weight: normal;
          }

          textarea {
            width: calc(100% - 10px);
            color: #fff;
            padding: 0 4px 2px 4px;
            min-height: 0;
          }
        }
      }

      > input[type='text'] {
        border-radius: 0 6px 6px 0;
        float: left;
        width: 60%;
        position: relative;
        border: 1px solid white;
        padding: 9px 25px 9px 15px;
        background-color: #ffffff;
        color: #3a3a3a;
      }
    }

    .form-radio-type {
      label,
      .field-label {
        align-items: center;
        display: flex;
        width: 100%;
        padding-left: 56px;
        color: $text-grey;
        background-color: transparent;
        border-radius: 6px;
      }

      &.disabled {
        label,
        .field-label {
          cursor: default;
          color: #999;
        }
      }

      // hide default radio button
      input[type='radio'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }

    .pip {
      display: inline-block;
      flex: none;
      width: 10px;
      height: 10px;
      margin-right: 3px;
      border-radius: 50%;
    }
  }

  .bttn {
    font-size: 14px;
    margin-bottom: $field-margin-bottom;
  }
}
