@import "../colours";

.geckometer {
  font-size: 0.875rem;

  display: inline-block;
  @include variable(fill, --text-color, $gray_athens);

  .value {
    font-size: 2.125rem;
  }

  .range {
    font-size: 0.75rem;
  }

  .goal {
    font-size: 0.875rem;
  }
}
