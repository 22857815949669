@import '../style/colors';

:local(.item) {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  border-bottom: 1px solid $color-black-20;
  background: #ffffff;

  color: $color-black-90;
  font-size: 0.9rem;
  outline: none;
  list-style: none;

  &:first-child {
    border-top: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

:local(.layout) {
  display: flex;
  align-items: center;
  min-height: 36px;
}

:local(.size-medium) {
  padding: 0.5rem;
}

:local(.size-large) {
  padding: 1.25rem;
}

:local(.actionable) {
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $color-black-10;
  }
}

:local(.highlighted) {
  background-color: $color-black-10;
}
