@import '../../../ui/style/colors';

.wrapper {
  width: 50%;
  height: calc(90% * 0.76);

  @media only screen and (max-width: 1600px) {
    width: 70%;
  }

  @media only screen and (max-width: 1290px) {
    width: 80%;
  }

  position: relative;
}

.wrapperLarge {
  width: 90%;
  max-height: 80%;
}

.border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid $color-geckoboard-blue;
  border-radius: calc(7.5px * var(--scale-factor));
}
