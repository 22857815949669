@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.day {
  @include fontMedium;

  margin: 0 2px;
  padding: 0.35em 0.75em;

  background-color: transparent;
  border: 1px solid $color-black-20;
  border-radius: 1.2em;
  color: $color-black-100;

  outline: none;
  cursor: pointer;
  font-size: 0.9rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:focus {
    box-shadow: 0px 0px 1px 1px $color-black-40;
  }

  &:hover {
    background-color: $color-black-10;
  }
}

.selected {
  background-color: $color-black-100 !important;
  border-color: $color-black-100;
  color: #ffffff;
}
