@import '../../../../src/ui/style/typography';

.header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;

  text-align: left;
  line-height: 1.25;
  background-color: #fff;
  overflow: hidden;
  padding: 0.5rem 1rem;

  img {
    flex: none;
    margin: auto 1rem auto 0;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    font-size: 1.25rem;
  }

  p {
    line-height: 1.25;
    font-weight: 300;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}
