@import '../../../style/shared/form-elements', '../../../src/ui/style/colors';

.wrapper {
  @include selectCarret;
}

.select {
  appearance: none;
  -webkit-appearance: none; // needed for dev build

  &::-ms-expand {
    display: none;
  }

  outline: 0;
  border: none;
  background-color: #fff;
  border: 1px solid $color-background-dark;
  color: inherit;

  font-family: inherit;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 6px;
  min-width: 100%;
  padding: 0.5rem 2rem 0.5rem 1rem;

  cursor: pointer;

  option {
    background-color: white;
  }

  &:focus {
    border: 1px solid $color-black-100;
  }
}
