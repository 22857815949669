.extendedItem {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  transition: 0.3s all;
  opacity: 1;
  border-bottom: none;

  img {
    width: 48px;
    height: 48px;
  }
}

.fiWrapper {
  flex: 1;
  margin-left: 1rem;
  margin-top: 0.25rem;
}
