.non-graphql-dashboard {
  // only target number-widget on the dashboard, not in the preview
  .widget-inner .number-widget {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;

    &.v2 {
      top: 15px;
    }
  }
}

.old-and-new-number-visualisation {
  height: 100%;
}

.number-visualisation {
  display: flex;
  flex-direction: column;

  height: 100%;

  .main-stat {
    flex: 1.5;
  }

  .secondary-stat {
    flex: 1;
  }

  .sparkline-wrapper {
    position: relative;
    flex: 2;
    display: flex;
    align-items: center;
  }

  .sparkline-end-value {
    position: absolute;
    text-shadow: none;
    z-index: 2;
    top: 45%;
    right: 0;
  }

  .unit { font-size: .7em }

  .goal-stats {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .target-value {
      float: right;
      margin: 11px 16px 0 0;
      border-radius: 2px;
      background-color: $gray_main;
      color: $gray_jaguar;
      font-size: 18px;
      padding: 3px 6px 5px;

      sup {
        font-size: 100%;
      }

      &.isLarge {
        margin-top: 24px;
        font-size: 32px;
      }
    }

    .progress-value {
      margin-left: 16px;
      font-size: 40px;
      color: $blue_summer_sky;

      &::after {
        content: '%';
        font-size: 24px;
      }

      &.isLarge {
        font-size: 72px;
      }
    }

    .progress-bar-wrapper {
      width: 100%;
      margin-top: 16px;
      height: 8px;
      background-color: $gray_charcoal;
      .progress-bar {
        position: relative;
        height: 8px;
        background-color: $blue_summer_sky;
      }
    }

    &.completed {
      .progress-value {
        color: $color-geckoboard-green;
      }
      .target-value, .progress-bar {
        background-color: $color-geckoboard-green;
      }
    }
  }
}
