@import '../../../style/colours';

.pie {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.chart {
  display: flex;
  align-items: center;
}

.legend {
  box-sizing: border-box;
  padding-left: 14px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.legendList {
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.label {
  margin-bottom: 6px;
  margin-left: 20px;
  position: relative;
}

.pill {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 5px;
  margin-left: -20px;
}

.text {
  max-height: 2.4em; // Only display 2 text lines
  line-height: 1.2em;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: var(--text-color, $text-colour);
}

.arc {
  stroke: #fff;
}
