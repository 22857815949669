@import "../../../../style/vendor/font-awesome-variables",
  "../../../../style/colours";

.editableInput {
  // overwrite global textarea css
  border-radius: 0 !important;
  border: none !important;
  margin: 0 !important;

  vertical-align: top;

  display: inline-block;
  width: 100%;

  font: inherit;
  overflow: hidden;
  background: transparent;
  box-sizing: border-box;
  resize: none;

  border-bottom: 1px dotted !important;
  outline: 0px solid transparent;
  color: $text-colour;
  padding: 0 0 2px 0;

  transition: 80ms border-bottom ease-in-out;
  &::placeholder {
    @include variable(color, --input-placeholder-color, $gray-main);
  }
}

.input {
  white-space: nowrap;
}
