@import '../../../style/colours';

.ButtonGroup {
  display: inline-flex;

  &.spaced {
    flex-wrap: wrap;
    row-gap: 8px;

    :local(.item) {
      margin-right: 8px;

      &.last {
        margin-right: 0px;
      }
    }
  }

  &.notSpaced {
    button,
    a {
      border-radius: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }

    :local(.last) button,
    :local(.last) a {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: none;
    }

    :local(.first) button,
    :local(.first) a {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &.stretched {
    width: 100%;
    display: flex;

    :local(.item) {
      flex: 1;
    }

    button,
    a {
      width: 100%;
    }
  }
}

/*
 * Dark theme
*/
.ButtonGroup.dark {
  button,
  a {
    color: white;
    background: transparent;
    border: 1px solid $mid-grey;
  }

  button:hover,
  button:focus,
  a:hover,
  a:focus {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
  }

  :local(.checked) button,
  :local(.checked) a {
    background: $mid-grey;
  }

  button:disabled {
    opacity: 0.6;
    background: transparent;
  }

  &.notSpaced {
    button,
    a {
      border-right: none;
    }

    :local(.last) button,
    :local(.last) a {
      border-right: 1px solid $mid-grey;
    }
  }
}

/*
 * Gray theme
*/
.ButtonGroup.gray {
  button,
  a {
    color: $btn-secondary-colour;
    background: $light-grey;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  button:hover,
  button:focus,
  a:hover,
  a:focus {
    color: $btn-secondary-colour;
    background: #e4e6e7;
    opacity: 1;
  }

  :local(.checked) button,
  :local(.checked) a {
    background: white;
    color: $dark-grey;
  }

  button:disabled {
    opacity: 0.6;
    background: $light-grey;
  }

  &.notSpaced {
    button,
    a {
      border-right: none;
    }

    :local(.last) button,
    :local(.last) a {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

/*
 * Light theme
*/
.ButtonGroup.light {
  button,
  a {
    color: $color-black-100;
    background: #ffffff;
    border: 1px solid $color-background-dark;
  }

  button:hover,
  button:focus,
  a:hover,
  a:focus {
    color: $color-black-100;
    background: $color-background-mid;
    opacity: 1;
  }

  :local(.checked) button,
  :local(.checked) a {
    background: $color-black-100;
    color: #ffffff;
    border-color: $color-black-100;
  }

  button:disabled {
    opacity: 0.6;
    background: $light-grey;
  }

  &.notSpaced {
    button,
    a {
      border-right: none;
    }

    :local(.last) {
      button,
      a {
        border-right: 1px solid $color-background-mid;
      }

      &.checked {
        button,
        a {
          border-right: 1px solid $color-black-100;
        }
      }
    }
  }
}
