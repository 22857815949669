@import "../../../../style/mq";

.resizeMessageContainer {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-color);
  font-size: calc(1.25rem*var(--scale-factor));
  line-height: calc(1.5rem*var(--scale-factor));

  &.horizontal {
    flex-direction: row;

    .resizeText {
      margin-top: 0;
      margin-left: calc(10px*var(--scale-factor));
    }
  }
}

.resizeIcon {
  padding: calc(10px*var(--scale-factor));
  position: relative;

  .vizIcon {
    font-size: calc(2rem*var(--scale-factor));
    opacity: 0.5;
  }

  .expandIcon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  // Make icon smaller on short height frames
  &.shortHeight {
    padding: calc(2px*var(--scale-factor)) calc(7px*var(--scale-factor));

    .vizIcon {
      font-size: calc(1.3rem*var(--scale-factor));
    }
  }
}

.resizeText {
  display: block;
  margin-top: calc(10px*var(--scale-factor));
  text-align: center;
  text-overflow: ellipsis;
}
