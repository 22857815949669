@import '../../../src/ui/style/colors';

.initials {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-weight: 500;
  background-color: $color-black-70;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending {
  composes: initials;

  background-color: $color-black-20;
}
