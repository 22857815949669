.sideBySideContainer {
  display: flex;
}

.sideBySideContent {
  position: relative;
  width: 50%;
}

.spacer {
  margin-left: 10px;
  margin-right: 10px;
  border-left: 1px solid var(--secondary-text-color);
}
