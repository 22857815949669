@import '../../../../src/ui/style/typography';
@import '../../../../src/ui/style/colors';

.input {
  display: inline-block;
  flex: 0 0 auto;
  width: 100%;
  height: 2.25rem;
  padding: 0.5rem;
  border: 1px solid $color-background-dark;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;
  transition: border-color 100ms;
  font-family: inherit;

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    border-color: $color-black-100;
  }
}

.disabled {
  opacity: 0.5;
}

.small {
  padding: 7px 10px;
  font-size: 0.875rem;
}

.error {
  border-color: $color-negative;
  box-shadow: 0 0 0 2px rgba(227, 82, 79, 0.5);

  &:focus {
    border-color: $color-negative;
  }
}

.uppercase {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
