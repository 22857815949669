@import '../../../../src/ui/style/colors';

.page {
  background-color: $color-black-100;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.content {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  text-align: center;
  width: 100%;
}

.title {
  font-size: 3.7rem;
  font-weight: bold;
}

.header {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  padding: 25px 0 0 25px;
  width: 100%;
}
