// This is id defined in Gecko-JS:
// https://github.com/geckoboard/gecko-js/blob/7eac0c4a6c4d63e005fbce519945e732edf99062/src/v4/views/config-wrapper-view.js#L6

#spreadsheet-config-wrapper {
  position: absolute;
  margin-top: 96px;
  width: 100%;
  height: calc(100% - 96px);
  bottom: 0;
  top: 0;
}

.config-wrapper {
  display: flex;
  width: 100%;
  min-width: 840px;
  height: 100%;

  &, * {
    box-sizing: border-box;
  }

  @import "config-form-wrapper",
    "spreadsheet-viewer",
    "shared/index";
}
