@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';

.emailSnapshotInfo {
  @include fontLight;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: $color-background-dark;
  color: $color-black-100;
  padding: 10px 15px;
  margin-bottom: 0.5rem;
  line-height: normal;
}

.errorAlert {
  margin-bottom: 0.5rem;
}

.separator {
  margin-bottom: 12px;
  border: 1px solid $color-grey-20;
}

.sharingInfo {
  @include fontMedium;
  display: flex;
  align-items: center;
  color: $color-black-100;
}

.sharingInfoText {
  margin-left: 0.5rem;
}

.sharingLink {
  display: flex;
  align-items: center;
  color: $color-black-100;
  margin-top: 0.25rem;

  button {
    padding-left: 0;
    padding-right: 0.3em;
  }
}
