@import "../../../../style/vendor/font-awesome-variables";
@import "../compact-config-colors";


.treeSelectDropdown {
  width: 100%;
  position: absolute;
  max-height: none !important;
  padding: 0;
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  overflow: visible;
}

// We need to limit the max-wdith due to filter options
// being extremely very long in one such case with a customer
.border {
  max-width: 800px;
}

.treeSelect {
  // have to remain relative because the parent drop menu block needs a height
  // in order to do its magic bottom: 100% positioning
  position: relative;
  // this is a trick (not a hack) in order to make sure the container takes
  // 100% of its children else it does not and the offset we get from boundary
  // is just 0
  float: left;

  &.rightAligned {
    float: right;
  }
}
