@import '../../../../src/ui/style/colors';

.visualizationLabel {
  display: inline-block;
  color: $color-grey-80;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.labelPadding {
  padding: 0px 0px 8px 0px;
}

.checkboxPadding {
  padding: 16px;
  border-bottom: 4px solid #fff;
}

.containerPadding {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.vizDropdwonLabel {
  display: flex;
  align-items: center;

  span:first-child {
    margin-right: 10px;
    font-weight: bold;
  }
}

.alertWrapper {
  p {
    margin: 0;
    line-height: 1.2;
  }

  p:first-child {
    margin-bottom: 4px;
  }
}
