.iconButton{
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font-size: inherit;
  outline: none;
}

.iconContainer {
  margin-right: 0.5rem;
  width: 20px;
}
