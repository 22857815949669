@import "../../../../style/vendor/font-awesome-variables";

.section {
  padding-bottom: 3rem;
}

.buttonIconProvider {
  button {
    &::before {
      @include fontAwesome;
      content: fa-content($fa-var-user); 
      margin-right: 0.5rem;
    }
  }
}
