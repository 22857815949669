@import "../../../style/colours";

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.feedWrapper {
  --container-layout-pagination-height: 30px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  column-count: 1;
  text-overflow: ellipsis;
}

.content {
  &.containerLayout {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &.lastPage {
      justify-content: flex-start;
    }

    &.paginated {
      height: calc(100% - var(--container-layout-pagination-height));
    }
  }
}

.previewLayout {
  flex: 1 1 0;
  position: relative;
}

.hiddenRenderer {
  visibility: hidden;
}

.pagination {
  &.nonContainerLayout{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  }
  
  &.containerLayout {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: var(--container-layout-pagination-height);
    }

  button {
    @include variable(background-color, --text-color, $gray_athens);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    height: 12px;
    width: 12px;
    margin-right: 6px;
    opacity: 0.2;

    &:global(.active) {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
}
