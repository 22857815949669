@import '../../../src/ui/style/colors';

.IntegrationPill {
  .link {
    transition: 0.1s all ease-out;
    color: $color-black-100;
    border: solid 1px $color-background-dark;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    position: relative;

    &:hover,
    &:focus {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      transform: translateY(-1px);
    }

    &:focus {
      border: solid 1px $color-black-100;
      outline: none;
    }
  }

  .icon {
    margin-right: 0.5rem;
    display: inline-block;
  }

  .label {
    position: absolute;
    right: 1rem;
    top: -10px;
  }
}
