@import '../../../style/colours', '../../../src/ui/style/colors',
  '../../../src/ui/style/typography', '../../../style/shared/animations',
  '../../../style/vendor/font-awesome-variables';

.breadcrumbLink {
  @include fontBold;
  line-height: 48px;
  max-width: 500px;
  padding: 0 0 0 20px;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  color: inherit;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
  transition: 300ms color $easeOutQuint;

  &:hover {
    color: $color-grey-40 !important;
  }

  &:link {
    border: 0;
  }

  &:after {
    @include fontAwesome;
    content: fa-content($fa-var-angle-right);
    font-weight: normal;
    margin-left: 15px;
    color: $color-black-100;
  }
}
