@import '../../../../../../src/ui/style/colors';

.inputWithLabel {
  display: flex;

  label {
    flex: 1;
    margin-top: 7px;
  }

  .input {
    flex: 2;
  }
}
