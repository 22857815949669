@import "../../../../../../style/colours";
@import "../../../../../../style/layout";

.keyContainer {
  display: flex;
  margin: 0 0 24px;
  justify-content: space-between;
}

.key {
  font-size: 1.25rem;
  margin: 0 1em 0 0;
  padding: 10px 12px;
  line-height: 1.2;
  background: $color-background-mid;
  border-radius: 4px;

}

