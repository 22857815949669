@import '../../style/colours';
@import '../../src/ui/style/typography';

.mobileMenuPage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid $color-grey-20;
}

.heading {
  @include fontBold;

  color: $color-grey-100;
  padding: 0 12px;
  height: 60px;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  img {
    margin-right: 10px;
  }
}

.footerWrapper {
  position: fixed;
  bottom: 0;
  z-index: 10;
  height: 60px;
  width: 100%;
  filter: drop-shadow(0px -12px 14px rgba(0, 0, 0, 0.1));
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 15px;

  background-color: $color-black-100;
}

.closeButton {
  color: #ffffff;

  display: flex;
  align-content: center;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none !important;
    border: none;
    padding: 0;
    font-size: 3rem;
    position: absolute;
    bottom: 14px;
    right: 20px;
    cursor: pointer;
    background-color: inherit;
    color: inherit;
  }
}

.menuItem {
  cursor: pointer;
  padding: 0;

  a {
    background-color: #ffffff;
    border-bottom: 1px solid $color-black-10;
    color: $color-grey-100;
    cursor: pointer;
    font-size: 1.5rem;
    box-sizing: border-box;
    display: block;
    padding: 1.5rem 1rem;
    height: 100%;
    width: 100%;
  }

  a:hover {
    border-bottom: 1px solid $color-black-10;
    color: inherit;
  }
}

.menuItemActive {
  a {
    background-color: $color-black-10;
    border-left: 4px solid $color-black-100;
  }
}

.dashboardList {
  border-bottom: 2px solid $color-grey-20;
}

.scrollWrapper {
  flex: 0 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  padding-bottom: 100px;
}

.logoutLinkContainer {
  padding: 0.5rem 1rem;

  a {
    font-size: 1.3rem;
    padding: 0;
    height: auto;
  }
}

.logoutLink {
  display: block;
  padding: 1rem 0;
  color: inherit;
}
