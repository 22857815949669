// Fonts
$light-weight: 300;
$regular-weight: 400;
$medium-weight: 500;
$bold-weight: 700;

@mixin baseFont($font-size, $is-faulty-text-rendering: false) {
  @if $is-faulty-text-rendering == true {
    font: #{$font-size}/1 Arial, sans-serif;
  } @else {
    font: #{$font-size}/1 "visuelt-regular-pro", Arial, sans-serif;
  }
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  -moz-osx-font-smoothing: grayscale; // Fix Firefox OSX font rendering
}

@mixin typography {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.475;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

@mixin typographySmall {
  @include typography;
  font-size: 14px;
}

@mixin fontLegacy {
  font-family: "aktiv-grotesk", Arial, sans-serif;
}
