.preview {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  width: calc(100vw - 50%);
  min-width: calc(100vw - 500px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9001;
}
