@import '../../../../../src/ui/style/colors';

.scheduledPostTimezone {
  color: $color-black-70;
  font-size: 0.875rem;
  line-height: normal;
}

.changeTimezoneButton {
  button {
    color: $color-black-70;
    padding: 0;
    height: auto;
  }
}
