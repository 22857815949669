@import '../../../style/colours';
@import '../../../src/ui/style/colors';

$min-dashboard-height: 720px;
$headerHeight: 96px;

.fullscreenButton {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

body:fullscreen .fullscreenButton {
  display: none;
}

/*
 * Hide the cursor and fullscreen button when the mouse is idle
 */
.sharing {
  cursor: none !important;

  .fullscreenButton {
    opacity: 0;
  }

  &.pointerActive {
    cursor: default !important;

    .fullscreenButton {
      cursor: pointer !important;
      opacity: 1;
    }
  }
}

.featureGate {
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: $text-grey;
  padding: 3rem 3.75rem 1rem 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 300px;

  transform: translate(-50%, -50%);

  img {
    display: block;
    margin: 0 auto;
  }
}

.featureGateTitle {
  display: block;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 23px;
}

.featureGateContent {
  font-size: 1.1rem;
  line-height: 19px;
  margin-bottom: 1.5rem;
}

.featureGateAction {
  display: block;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}

.featureGateDesktopLink {
  cursor: pointer;
  font-size: 0.9rem;
  color: $color-grey-60;
  text-decoration: underline;
}
