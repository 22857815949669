@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

/*
A button for inline placement

:hover             - Subtle rollover
.btn.btn-large     - A large button to draw attention
.btn.btn-submit    - For form actions
.btn.btn-secondary - For secondary form actions
*/

@import '../mixins/btn';

.btn {
  @include btn;
  @include fontMedium;

  color: $color-black-100;
  border-radius: 6px;

  &:link {
    border: none;
  }

  .fa {
    margin-top: -4px;

    font-size: 20px;
    vertical-align: middle;
  }

  .fa-subset {
    margin-top: 0;
    font-size: 16px;
  }

  &:hover,
  &:focus {
    background-color: #1cb954;
  }

  &:focus {
    border: solid 1px $color-black-100;
    .fa-angle-left {
      transform: translateX(-3px);
    }
  }

  &.btn-text .fa {
    padding-right: 5px;
  }

  &.btn-medium {
    padding: 5px 30px;
    line-height: 24px;
  }

  &.btn-large {
    padding: 20px 30px;
    line-height: 16px;
  }

  &.btn-submit {
    background-color: $color-geckoboard-green;

    &:hover:not([disabled]),
    &:focus {
      background-color: #1cb954;
    }

    &:focus {
      border: solid 1px $color-black-100;
    }
  }

  &.btn-secondary {
    background-color: $color-background-dark;

    &:hover,
    &:focus {
      background-color: rgba(92, 88, 117, 0.5);
    }

    &:focus {
      border: solid 1px $color-black-100;
    }
  }

  &.btn-light {
    background-color: $btn-light-background-colour;
    color: $btn-light-colour;

    &:hover,
    &:focus {
      background-color: $btn-light-hover-background-colour;
      color: $btn-light-colour;
    }
  }

  &.btn-dark {
    background-color: $btn-dark-background-colour;
    color: $btn-dark-colour;

    &:hover,
    &:focus {
      background-color: $btn-hover-background-colour;
    }
  }

  &.btn-destructive {
    background-color: $btn-destructive-background-colour;
    color: $btn-destructive-colour;

    &:hover,
    &:focus {
      background-color: $btn-destructive-hover-background-colour;
    }
  }
}
