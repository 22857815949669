@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';
@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../style/colours';

.customizePanelContent {
  font-size: 16px;
  background-color: $color-background-light;
  color: $color-black-100;
  @include fontLight;
}

.container {
  max-height: calc(100vh - 95px); // Viewport - header
  position: absolute;
  top: 95px;
  width: 100%;

  // Remove absolute positioning when used in Storybook
  @at-root :global(.storybook) & {
    position: initial;
  }
}

.inner {
  padding: 1rem 1rem 0 1rem;
  max-height: calc(100vh - 175px); // Viewport - header - buttons
  overflow: auto;

  * {
    box-sizing: border-box;
  }

  input[type='text'] {
    font-size: 16px;
  }
}

.buttonPanel {
  padding: 1rem;
}

.footerCardTitle {
  @include fontMedium;
}

.controlImage {
  margin-right: 0.6em;
  opacity: 0.4;
}

.activeControlImage {
  margin-right: 0.6em;
}

.iconContainer {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
