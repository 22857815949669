@import "../../style/colours",
  "../../style/vendor/font-awesome-variables";

.account {
  display: flex;
}

.addBtn {
  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-plus);
    margin-right: 0.5rem;
  }

  line-height: 1.25rem;
}

.selectAccount {
  flex: 1;
  margin-right: 0.5rem;
}

.error {
  color: $error-red;
  font-size: 1rem;
  margin: 1rem 0;
}
