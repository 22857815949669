@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.infoText {
  @include fontLight;
  color: $color-black-70;
  text-align: left;
  word-break: normal;
}

.textWithChannelInput {
  @include fontLight;
  color: $color-black-70;
  text-align: left;
  word-break: break-all;
}

.addOption{
  max-width: 330px;

  // As the users input is displayed we want to
  // set the max width on the button and overflow
  button {
    max-width: 330px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
