@import '../../style/colors';
@import '../../style/typography';

:local(.OuterContainer) {
  display: inline-block;

  box-sizing: border-box;
  border-radius: 0.375rem;
  border: 1px solid $color-background-dark;

  overflow: hidden;

  &:focus-within {
    border-color: $color-black-100;
  }
}

:local(.ValidationError) {
  border-color: $color-negative;
}

:local(.InnerContainer) {
  @include fontLight;

  position: relative;
  overflow: hidden;
  cursor: text;

  width: 10rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  padding: 0;
  font-family: visuelt-regular-pro, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  color: $color-black-100;
}

:local(.Editable) {
  height: 100%;
  // Our content has no descenders, so we need to shift it down a bit to
  // make it look vertically centered
  padding: 0.085rem 0.5rem 0;

  white-space: pre;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  outline: none;

  &::-webkit-scrollbar {
    display: none;
  }

  * {
    display: inline-block;
    white-space: pre;
  }
}

:local(.Colon) {
  padding-left: 0.4rem;
  letter-spacing: 0.4rem;
  pointer-events: none;
}

:local(.Placeholder) {
  color: $color-black-40;
  &:hover {
    color: $color-black-60;
  }
  &:active {
    color: $color-black-90;
  }
}
