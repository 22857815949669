@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.header {
  padding: 0.9rem;
  flex-direction: row;
  align-items: center;
  background-color: $color-background-mid;
}

.title {
  @include fontMedium;
  color: $color-black-100;
  font-size: 1.25rem;
  line-height: 1.18;
}

.icon {
  margin-right: 1rem;
}

.iconContainer {
  margin-right: 0.5rem;
}
