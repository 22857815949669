@import "../../../../style/colours";
@import "../../../../style/spacing";

.widgetTitle {
  flex: 1 1 auto;
  font: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin-bottom: 0;
  font-size: 1.75rem;
  font-size: calc(1.75rem * var(--scale-factor, 1));
  line-height: 1.4;

  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-top: 2px;
  padding-top: calc(2px * var(--scale-factor, 1));

  position: relative;
  z-index: 5;
  color: $text-colour;
  color: var(--text-color, $text-colour);
}

.legacyTitle {
  font-family: aktiv-grotesk, Arial, sans-serif;
}

// Take up space, even if the title is empty
.noEdit::after {
  content: '.';
  visibility: hidden;
}

.input {
  min-width: 100px;
  padding-bottom: 0;

  color: $text-colour;
  color: var(--text-color, $text-colour);
}

.input {
  &:hover {
    border-color: var(--container-hover-color);
  }
}

.groupTitle .input {
  // These stylings make the hover state of the placeholder
  // always show, and not only when hovering
  &:global(.placeholder) {
    border-color: var(--title-placeholder-color);
    padding-right: 20px;

    &::after {
      display: block;
    }

    &:hover {
      border-color: var(--container-hover-color);
    }
  }
}
