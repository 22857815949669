@import "../../ui/style/colors";
@import "../../ui/style/typography";

.featureGate {
  background-color: white;
  border-radius: 6px;
  text-align: center;
  width: 500px;
}

.featureGateSupport {
  @include fontSmall;
  color: $color-black-70;

  button {
    @include fontSmall;
    color: $color-black-70;
    padding: 0;
    display: inline;
    height: auto;
  }
}
