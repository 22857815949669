@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../src/ui/style/gecko-font/variables';

.upgradeModalView {
  ul {
    margin-left: 20px;
    margin-top: 1rem;
    margin-bottom: 2rem;

    line-height: 1.8;

    li {
      &::before {
        font-family: 'GeckoFont';
        content: $gecko-var-star;
        font-size: 0.75rem;
        margin-left: -20px;
        margin-right: 10px;
      }
    }
  }
}

.image {
  display: block;
  margin: 0 auto;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.upgradeButton {
  display: flex;
  justify-content: center;
}

.notReadyToUpgradeSection {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 0.875rem;

  button {
    padding: 0; //Override UI Kit button padding
    height: auto; //Override UI Kit button height
  }
}
