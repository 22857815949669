@import '../../../../../ui/style/colors';

.fieldDropdownContainer {
  display: flex;
  position: relative;
  margin-bottom: 5px;
}

.dropdown {
  flex: 1;
  flex-grow: 1;
}

.iconContainer {
  flex-basis: 30px;
}

.icon {
  position: absolute;
  bottom: 5px;
  right: 5px;

  &:hover {
    cursor: pointer;
  }
}

.errorMessage {
  color: $color-negative;
  font-size: 0.8rem;
  margin-bottom: 5px;
}
