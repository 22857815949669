@import '../../../style/colours';
@import '../../../src/ui/style/colors';

.item {
  position: relative;
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
  border-bottom: 1px solid $color-grey-20;
  background: #ffffff;
  color: $text-grey;
  font-size: 0.9rem;
  outline: none;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

a.item {
  color: $color-grey-100;
  border-color: $color-grey-20;
}

.row {
  min-height: 36px;
}

.disabled {
  color: $color-grey-60;
}

.itemTypeSmall {
  background: $light-grey;

  .row {
    min-height: 20px;
  }

  .meta {
    opacity: 0.3;
  }
}

.hasHover {
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: lighten($color-grey-20, 8%);
  }
}

.hasIcon {
  padding-left: 44px;
}

.icon {
  position: absolute;
  top: 0;
  left: 1rem;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &::before {
    margin-top: 1px; // for optical centering
  }
}

.meta {
  opacity: 0.5;
}

.isHighlighted {
  background-color: lighten($color-grey-20, 8%);
}
