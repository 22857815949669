@import '../../style/colors';
@import '../../style/typography';
@import '../../../../style/vendor/font-awesome-variables';

:local(.inputWrapper) {
  position: relative;
  width: 100%;
}

:local(.Input) {
  @include fontLight;

  -webkit-appearance: none;
  display: block;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 1rem;
  color: $color-black-100;
  transition: border-color ease 100ms;
  border: 1px solid $color-background-dark;
  background: #ffffff;

  &::placeholder {
    color: $color-black-40;
  }

  &:focus {
    outline: none;
    border-color: $color-black-100;
  }

  &:disabled {
    background: $color-background-light;
  }
}

:local(.Input) {
  &[type='search'] {
    padding-left: 40px;

    // Hide the default Chrome "X" button
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }
}

:local(.searchIcon) {
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: $color-black-40;
}

:local(.size-small) {
  font-size: 0.875rem;
  height: 28px;
}

:local(.size-medium) {
  height: 36px;
}

:local(.size-large) {
  height: 40px;
}

:local(.validation-success) {
  border-color: $color-positive;
}

:local(.validation-warning) {
  border-color: $color-warning;
}

:local(.validation-error) {
  border-color: $color-negative;
}

:local(.display-as-required) {
  padding-right: 1.5rem;
}

:local(.asterisk) {
  font-size: 0.5rem;
  color: $color-black-100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}
