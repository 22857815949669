@import '../../../../style/vendor/font-awesome-variables',
  '../../../../src/ui/style/colors';

.editable {
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  min-width: 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  border-bottom: 1px dotted transparent;
  padding-bottom: 2px;

  vertical-align: top;
  white-space: pre;
  cursor: pointer;

  align-self: flex-start;

  transition: border-bottom 150ms, color 150ms;

  &::after {
    @include fontAwesome;
    content: fa-content($fa-var-pencil-alt);
    font-size: 0.875em;
    margin-left: -0.71em; // -10px at 100% scaling w/ 0.875em font size
    position: absolute;
    bottom: 0.29em; // 4px at 100% scaling w/ 0.875em font size
    right: 0;
    opacity: 0.5;
    display: none;
  }

  &.isDashboardTitle {
    padding-right: 1.25em;
  }

  &:hover {
    border-bottom: 1px dotted $color-black-100;
    padding-right: 1.25em;

    &::after {
      display: block;
    }
  }

  &.placeholder {
    color: $color-grey-60;
    color: var(--input-placeholder-color, $color-grey-60);
  }
}

.showPlaceholderOnHover {
  &.placeholder {
    visibility: hidden;

    &:hover {
      color: $color-grey-60;
      color: var(--input-placeholder-color, $color-grey-60);
      visibility: visible;
    }
  }
}

.multiLines {
  white-space: pre-wrap;
  word-wrap: break-word;
}
