@import '../../../../../style/colours';
@import '../../../../../style/layout';

.container {
  margin-bottom: 3rem;

  .pageHeader {
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    span:first-child {
      margin: 0 10px 0 0;
    }
  }
}

.tabsContainer {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  max-width: $admin-content-width;
  margin: 3rem 0 0;
  line-height: 1.475;
}

.tab {
  display: flex;
  background-color: #fff;
  border: 1px solid $color-background-dark;
  border-radius: 6px;
}

a.tabLink {
  display: flex;
  align-items: center;
  border-bottom: 0;
  border-radius: 6px;
  color: $color-black-100;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5em 1em;
  text-align: center;
  width: $admin-tab-width;

  &:hover {
    background-color: $color-background-dark;
    color: $color-black-100;
  }
}

.tabIcon {
  margin-right: 10px;
}

a.active {
  background-color: $color-black-100;
  color: #fff;

  &:hover {
    background-color: $color-black-100;
    cursor: default;
    color: #fff;
  }
}

