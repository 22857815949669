@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.modal {
  max-width: 500px;
}

.header {
  a {
    @include fontMedium;

    transition: color 0.2s ease;
    color: $color-black-100;
    text-decoration: underline;

    &:hover {
      color: $color-black-70;
    }
  }
}

.instructions {
  background-color: $color-background-light;
  border-radius: 0 0 6px 6px;

  form {
    line-height: 2rem;
  }

  h6 {
    margin-top: 1.5rem;
    font-weight: 600;
  }
}

.errorText {
  color: $color-negative;
  font-size: 1rem;
}

.featureGate {
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 3rem 3.75rem;
  text-align: center;
  width: 500px;

  img {
    display: block;
    margin: 0 auto;
  }

  a {
    @include fontMedium;
    color: $color-black-100;
    cursor: pointer;
    text-decoration: underline;
  }
}

.featureGateTitle {
  display: block;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.featureGateContent,
.featureGateSupport {
  font-size: 0.9rem;
  line-height: 1.5;
}

.featureGateContent {
  margin-bottom: 1.5rem;
}

.featureGateSupport {
  margin-top: 1.5rem;
}

.featureGateAction {
  display: block;
  margin: 0 auto;
  width: 200px;
}
