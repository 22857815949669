@import '../../style/typography';

.heading {
  margin: 0;
  font-weight: normal;
  line-height: normal;
}

.extraLargeSize {
  font-size: 2.25rem;
  margin-bottom: 1.5rem;
}

.largeSize {
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
}

.mediumSize {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.smallSize {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.lightWeight {
  @include fontLight;
}

.mediumWeight {
  @include fontMedium;
}

.boldWeight {
  @include fontBold;
}
