.positiveThumb svg path {
  fill: #00D1FF;
  fill: var(--primary-visualisation-color);
}

.negativeThumb {
  svg {
    transform: scale(1,1) scale(1,-1);
  }

  svg path {
    fill:#EC373C;
  fill: var(--negative-color);
  }
}
