@import '../../../style/colours';

.editableInput {
  color: $text-grey !important;
  font-size: 1em;
  margin-top: 6px !important;
}

.editableContainer {
  overflow: hidden;
}

.selectContainer {
  width: 160px;
}
