@import '../../../../style/colours';

.instructions {
  max-width: 620px;
  text-align: center;
  line-height: 1.5;
}

.image {
  margin: 1rem;
}

.iconContainer {
  margin-right: 0.5rem;
}
