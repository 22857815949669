@import '../../../style/vendor/mq';
@import '../../../src/ui/style/colors';

// We need to overwrite global styles (hence the !important)
.inputWithSuffix {
  text-align: center;
  border-radius: 4px 4px 0 0 !important;
}

.suffixLabel {
  border-radius: 0 0 4px 4px !important;
}

.validationMessage {
  font-size: 14px;
  display: block;
  clear: both;
  padding: 4px 0 18px;
  color: $color-negative;
}

@include mq($from: tablet) {
  .inputWithSuffix {
    padding-right: 12px !important;
    text-align: right;
    border-radius: 4px 0 0 4px !important;
  }

  .suffixLabel {
    float: right;
    border-radius: 0 4px 4px 0 !important;
  }
}
