@import '../../../src/ui/style/colors';

@mixin upgradeText {
  display: inline-block;
  vertical-align: middle;
  padding: 0.25rem 1rem 0.25rem 1rem;
  margin-left: 12px;
  border-radius: 6px;
  background-color: rgba($color-background-mid, 0.2);
  border: none;
  transition: background-color 100ms;
  color: #ffffff;
}

.mastheadButton {
  @include upgradeText;
  cursor: pointer;

  &:hover {
    border: none;
    background-color: rgba($color-background-mid, 0.6);
    color: #ffffff !important; // overwrite global css
  }
}

.mastheadText {
  @include upgradeText;
}
