@import '../../../../../../src/ui/style/colors';

.container {
  margin: 0 0 1.5rem;
  position: relative;

  label {
    display: block;
    font-size: 0.8rem;
    margin: 0 0 0.3rem;
  }
}

.error {
  position: absolute;
  font-size: 0.8rem;
  top: 100%;
  color: $color-negative;
}
