@import '../style/typography', '../style/colors';

.anchor {
  @include fontBold;

  color: $color-black-100;
  display: inline-block;
  text-decoration-line: underline !important;
  text-underline-offset: 2px !important;
  text-decoration-thickness: 1px !important;

  &:hover {
    color: $color-black-70 !important;
    cursor: pointer;
  }

  &:hover,
  &:focus {
    text-decoration-thickness: 2px !important;
  }
}
