@import '../../../../src/ui/style/typography';

label {
  &.label {
    @include fontLight;
    display: inline-block;
    font-size: 13px;
    color: #717171;
    padding: 2px 0;
    line-height: 17px;
  }
}
