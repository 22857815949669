@import '../../../style/colours', '../../../style/spacing',
  '../../../style/vendor/_font-awesome-variables';

.previewLayout {
  flex: 1 1 0;
  max-width: 460px;
}

.leaderboard {
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

@for $i from 1 through 20 {
  @keyframes leaderboard-up-#{$i} {
    from {
      transform: translate3d(0, $i * 100%, 0);
    }
    25% {
      transform: translate3d(-10px, $i * 75%, 0);
    }
    75% {
      transform: translate3d(-10px, $i * 25%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes leaderboard-down-#{$i} {
    from {
      transform: translate3d(0, -$i * 100%, 0);
    }
    25% {
      transform: translate3d(10px, -$i * 75%, 0);
    }
    75% {
      transform: translate3d(10px, -$i * 25%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
}

:global(.leaderboard) {
  li {
    table-layout: fixed;
    display: table;
    display: flex;

    width: 100%;
    white-space: nowrap;
    position: relative;

    @for $i from 1 through 20 {
      &[data-delta='#{$i}'] {
        z-index: $i + 20;
        animation: leaderboard-up-#{$i} 0.5s linear;
      }

      &[data-delta='-#{$i}'] {
        z-index: $i;
        animation: leaderboard-down-#{$i} 0.5s linear;
      }
    }
  }

  :global(.leaderboard-label),
  :global(.leaderboard-value),
  :global(.leaderboard-image) {
    display: table-cell;
    display: flex;
    padding: 10px 8px 10px 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include variable(
      border-bottom-color,
      --chart-axis-color,
      $transparent-white
    );
    overflow: hidden;
    align-items: center;
  }

  :global(.leaderboard-label-container) {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  li:last-of-type {
    :global(.leaderboard-label),
    :global(.leaderboard-value),
    :global(.leaderboard-image) {
      border-bottom: 0;
    }
  }

  :global(.leaderboard-label) {
    flex: 1;
    position: relative;
    @include widgetSpacing(padding-left);
  }

  :global(.leaderboard-value) {
    text-align: right;
    @include widgetSpacing(padding-right);
  }

  :global(.leaderboard-image) {
    width: 40px;
  }
}

.arrow {
  :global(.leaderboard-label)::before {
    @include fontAwesome;
    content: fa-content($fa-var-play);

    width: 1em;
    margin-right: 0.5em;
    margin-left: -4px;

    visibility: hidden;
  }

  &:global(.moved .leaderboard-label::before) {
    transform: scale(0.7, 0.5) rotate(-90deg);
    visibility: visible;
  }

  &:global(.moved-up .leaderboard-label::before) {
    color: $ui-green;
  }

  &:global(.moved-down .leaderboard-label::before) {
    transform: scale(0.7, 0.5) rotate(90deg);
    color: $error-red;
  }
}
