@import '../../../style/colours';
@import '../../../src/ui/style/colors';

:global(.react-tabs__tab-list) {
  display: flex;
  border-radius: 6px;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid $color-background-dark;
}

:global(.react-tabs__tab) {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5em 1em;
  text-align: center;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.2s ease;
  border-radius: 6px;
  color: $color-black-100;

  &:hover {
    opacity: 1;
    background-color: $color-background-mid;
  }

  &:focus {
    outline: none;
    background-color: $color-background-mid;
  }

  &[aria-selected='true'] {
    cursor: default;
    background-color: $color-black-100;
    color: #ffffff;
  }
}
