.salesforceConnector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.header {
  flex: 0 0 auto;

  text-align: left;
  line-height: 1.25;
  background-color: #fff;
  padding: 0.5rem 1rem;

  img {
    float: left;
    margin-right: 1rem;
  }

  h3 {
    margin: 1rem 0 0.5rem 0;
    font-size: 16px;
  }

  p {
    line-height: 1.75;
    font-weight: 300;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  padding: 1rem;
}

.AccountSelector {
  margin-bottom: 1.5rem;
}

.SearchInput {
  margin-bottom: 0.5rem;
}

.ReportSelector {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

