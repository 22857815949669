@import '../../../../style/colours';
@import '../../../../style/shared/link';

.panel  {
  width: 1068px;
  position: fixed;
  z-index: 2001;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: white;
  overflow-y: auto;
}

.headerContainer {
  padding: 1rem 1.5rem;
}

.header {
  align-items: center;
  display: flex;

  h3 {
    font-size: 24px;
    font-weight: 700;
    color: #16113A;
    flex: 1;
    padding-top: 5px;
  }
}

.icon {
  margin-right: 5px;
}

.container {
  display: grid;
  grid-template-columns: 58% 42%;
  height: 100%;
  text-align: left;

  a {
    font-family: "visuelt-medium-pro", Arial, sans-serif;
    color: #16113A;
    text-decoration: underline;
  }
}

.form {
  padding: 1.5rem;
  border-top: 1px solid #efefef;
}

.editFormSection {
  background-color: #f8f8f9;
}

.actions {
  padding: 1rem 0 0 1rem;
  text-align: right;

  button {
    width: 8.75rem;

    &:last-child {
      margin-left: 1.375rem;
    }
  }
}
