@import "../../../style/spacing",
  "../../../style/colours",
  "../../../style/vendor/font-awesome-variables";

.goal {
  display: flex;
  flex-direction: column;
  flex: 1;

  position: relative;
  width: 100%;
}

.goalProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 14px 26px;
  @include widgetSpacing(padding-right);
  color: $blue-summer-sky;
  font-size: 0.7em;
  box-sizing: border-box;
}

.goalComplete {
  position: absolute;
  bottom: 26px;
  @include widgetSpacing(right);
  padding: 0.2em 0.3em;
  line-height: 1.1;
  border-radius: 50% 50% 0 50%;
  background-color: $visualisation-green;
  color: #303030;

  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-check);
  }
}

.goalProgressBarTrack {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 14px;
  display: block;
  content: '';
  @include variable(background-color, --chart-axis-color, $transparent-white);
}

.goalProgressBar {
  transition: width 100ms linear, background-color 100ms linear;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0%;
  height: 14px;
  border-radius: 0 10px 10px 0;

  background-image: linear-gradient(92deg, #219bff, $visualisation-green);
}

.goalThreshold {
  position: absolute;
  bottom: 26px;
  @include widgetSpacing(right);
  padding: 6px 12px;
  margin-bottom: 0.25em;
  font-size: 0.3em;
  font-weight: 500;
  line-height: 1.1;
  border-radius: 20px 20px 0 20px;

  @include variable(color, --text-color, $text-colour);
  @include variable(background-color, --goal-pill-background-color, rgba(255, 255, 255, 0.12));
}

.goalThresholdReached {
  background-color: $visualisation-green;

  @include variable(color, --number-threshold-text-color, $dark-grey);
}
