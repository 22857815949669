@import "../../colours";

$land_color: #4a4a4a;
$land_path_color: #2a2a2a;
$plotted_point_color: #38cdff;

.map-visualisation {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .map-renderer {
    height: 100%;
    width: 100%;
  }

  .subunit {
    @include variable(fill, --map-subunit-color, $land_color);
    @include variable(stroke, --map-subunit-color, $land_path_color);
  }

  .single-point {
    @include variable(fill, --map-single-point-color, $plotted_point_color);
    fill-opacity: 0.6;
    @include variable(stroke, --map-single-point-color, $plotted_point_color);
    stroke-opacity: 0.05;
  }
}
