@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.bubble {
  @include fontLight;

  transition: 0.3s opacity ease-out;
  position: relative;
  padding: 10px;
  max-width: 200px;
  background-color: $color-black-100;
  border-radius: 6px;
  z-index: 9999;
  color: #ffffff;

  font-size: 1rem;
  line-height: 1.3;

  &.isLight {
    background-color: $color-background-light;
    border: 1px solid $color-background-dark;
    color: $color-black-100;

    &::after {
      border-top-color: $color-background-light;
    }
  }

  &.isWide {
    max-width: 320px;
  }
}
