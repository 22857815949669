@import '../../../style/colors';
@import '../../../style/typography';

.TreeColumn {
  @include fontLight;
  flex: 1;
  min-width: 260px;
  max-height: 300px;
  border-right: 1px solid $color-grey-10;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;

  &:last-of-type {
    border-right: none;
  }

  li {
    list-style: none;
    padding: none;
  }

  .columnHeader {
    padding: 14px 20px;
    font-size: 1rem;
    color: $color-grey-100;
    border-bottom: 1px solid $color-grey-10;
  }

  .columnOptions {
    overflow-y: auto;
    margin: 0;
    padding: 0;
  }
}

.fullWidth {
  width: 100%;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
}
