.previewLayout {
  flex: 1 0 0;
  position: relative;
  /*
   * prevent children (barchart svg) from
   * growing bigger than the parent
   */
  display: flex;
}

.container {
  :global(.label) {
    padding-right: 0.5em;
  }

  :global(.label), :global(.value) {
    // a height of 1.2 ensures the text is not cut off, we need to keep it at 
    // at least 1.2!
    height: 1.2em;
    line-height: normal;
  }

  &.tvMode {
    :global(.label), :global(.value) {
      font-size: 1.5rem;
    }
  }
}
