@import "../../../style/colours";
@import "../../../style/vendor/font-awesome-variables";

.warning {
  display: inline-block;
  vertical-align: middle;
  color: $error-red;
  padding-left: 30px;
  line-height: 1.3;
  position: relative;

  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-exclamation-triangle);
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
