@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/vendor/font-awesome-variables';
@import '../../../style/colours';
@import '../../../style/shared/animations';

.promoCard {
  @include fontLight;

  background: $color-black-100;
  background-position: 10% 50%, 90% 50%;
  background-repeat: no-repeat;

  color: #ffffff;
  border-radius: 6px;
  padding: 1rem;

  font-size: 1rem;
  line-height: 1.5;
}

.promoCardHeader {
  @include fontMedium;

  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 1rem;

  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-crown);
    color: $color-geckoboard-yellow;
    margin-right: 10px;
  }
}

.promoCardFooter {
  margin-top: 1rem;
}

.link {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    opacity: 0.8;
  }
}
