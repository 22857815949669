@import '../../../../src/ui/style/colors';

.quietInput {
  box-sizing: border-box;

  &[type='text'],
  &[type='number'] {
    color: $color-grey-100;
    border: none;
    font-size: 16px;
    padding: 0px 0px 12px;
    box-shadow: 0px -1px $color-grey-40 inset;
    display: block;
    width: 100%;
    height: 32px;

    &::placeholder {
      color: $color-grey-60;
    }

    // GLOBAL OVERRIDE.......
    background-color: inherit;
    border-radius: 0;

    &:hover {
      box-shadow: 0px -1px $color-black-100 inset;
    }

    &:focus {
      outline: none;
      box-shadow: 0px -2px $color-black-100 inset;
    }

    &:invalid {
      box-shadow: 0px -1px $color-negative inset;
      color: $color-negative;
    }
  }
}
