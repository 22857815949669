@import "../../../../style/colours";

.durationInputContainer {
  display: flex;
  justify-content: space-between;
  min-width: 270px;
}

.durationInput {
  display: flex;
  margin-right: 0.5rem;
}

.input {
  width: calc(100% - 30px);
  flex: 0 0 auto;
  height: 2.25rem;
  padding: .5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px 0 0 3px;
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;

  transition: border-color 100ms;

  &:focus {
    border-color: rgba(0, 0, 0, 0.25) !important;
  }
}

.suffix {
  border-radius: 0 3px 3px 0;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  background: $mid-grey;
  color: $text-colour;
  padding: .5rem 0 0 0;
  box-sizing: border-box;
  text-align: center;
}
