.chartTooltipWrapper {
  color: #fff;
  background-color: rgba(0,0,0, 0.7);
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 10px;
  position: absolute;
  line-height: 1.4;
  pointer-events: none;
  transition: opacity 0.3s;
  top: 0;

  ul {
    display: table;
    width: 100%;
  }

  li {
    text-align: right;
    display: table-row;

    .name {
      display: table-cell;
      max-width: 220px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
    }
    
    .value {
      display: table-cell;
      text-align: right;
    }
  }

  h3 {
    padding: 0 12px;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255, 0.2);
    padding-bottom: 4px;
    margin-bottom: 4px;
    font-weight: 500;
  }
}
