%alert-text {
  font-size: 17px;
  font-weight: $medium-weight;
}

.error {
  @extend %alert-text;
  margin: 10px 0;

  color: $error-red;

  .message {
    margin: 0;
    background-color: $blackest-night-grey;
  }
}

.flash-message {
  @extend %alert-text;
  margin: 0;
  color: #fff;
  border-radius: 0;

  font-weight: $regular-weight;

  .flash-message-container {
    position: relative;
    max-width: $wide;
    min-width: $mobile;
    margin: 0 auto;
    padding: 1em 20px;
  }

  &.error {
    background: $error-red;
  }

  &.warning {
    background: $warning-yellow;
  }

  &.success {
    background: $success-green;

    .flash-message-container::before {
      padding-right: 5px;
      @include fontAwesome;
      content: fa-content($fa-var-check);
    }
  }

  a:link, a:visited {
    color: #fff;
    font-weight: $medium-weight;
    border-bottom: 1px solid #ddd;
  }
}

.message {
  font-size: 17px;
  font-weight: $medium-weight;
  margin: 10px 0;
  padding: 10px;

  border-radius: 4px;
  background-color: $success-green;
}
