@import "../../../../style/colours";

.sparkline {
  display: flex;
  flex: 1;
  fill: none;
  @include variable(stroke, --sparkline-stroke, $blue-summer-sky);
  stroke-width: 2px;
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;

  svg {
    width: 100%;
    @include variable(filter, --sparkline-shadow, none);
  }
}

:global(.tv-mode) {
  .sparkline {
    stroke-width: 3.5px;
  }
}
