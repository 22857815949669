@import "../../../../style/vendor/font-awesome-variables";

.datasetVisualisationOptionsCard {
  margin-bottom: 1rem;
  cursor: default;
}

.cardInner {
  display: flex;
  flex-direction: row;
}

.deleteButtonContainer {
  height: 100%;
  margin: auto;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.5rem;
}

.inputsContainer {
  flex: 1;
}

.inputContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.input {
  width: 300px;
  margin-right: 0.5rem;
}

.cardTitleSection {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &.radio {
    position: relative;
    margin: -1rem;
  }
}

.cardTitle {
  padding-left: 30px;
  position: relative;

  &::before {
    margin-left: -30px;
    position: absolute;
    @include fontAwesome;
    content: fa-content($fa-var-cog);
  }
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font-size: inherit;
  outline: none;
}

.iconContainer {
  margin-right: 0.5rem;
}
