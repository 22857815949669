@import '../../src/ui/style/typography';
@import '../../src/ui/style/colors';

.wrapper {
  @include fontLight;
  color: $color-black-100;
  padding: 0 0px 30px 0px;
  width: 100%;

  header {
    background-color: $color-background-mid;
    padding: 1rem 0 0 0;
    margin: 0 0 24px 0;
    width: 100%;
    border-bottom: 1px solid $color-black-20;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.connectionSidePanel {
  background-color: $color-background-light;
  height: 100%;
}

.accountHeading {
  @include fontMedium;
  color: $color-grey-100;
  margin-bottom: 0.5rem;
}

.accountConnectionWrapper {
  display: grid;
  grid-template-columns: auto 148px;
  grid-gap: 0.625rem;
}

.logo {
  width: 48px;
  vertical-align: middle;
  margin: 0 15px 0 0;
}

.centered {
  max-width: 485px;
  margin: 0 auto;
}

.row {
  margin-bottom: 20px;
}

.row__nested {
  margin: -15px 0 20px 35px;
  width: 450px;
}

.groupedFields {
  >div {
    margin-bottom: 5px;
  }
}

.vizOptions {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 15px 20px 10px 20px;
  margin-left: -20px;

  h3 {
    @include fontMedium;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }
}

.buttonRow {
  display: flex;
  border-top: 1px solid $color-grey-20;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0 10px 40px;
  margin-left: -20px;

  button:first-child {
    margin-right: 10px;
  }
}

.inputHeading {
  display: flex;
}

.url {
  display: flex;

  button {
    margin-left: 5px;
  }
}

.radioButton {
  margin-right: 10px;
}

.checkboxWrapper {
  display: flex;
  gap: 0.5rem;
}

.errorMessage {
  margin: 1rem 0;
}

.inlineError {
  margin-top: 5px;
  font-size: 0.875rem;
  color: $color-negative;
}

.textarea {
  @include fontLight;
  width: 100%;
  resize: vertical;
  padding: 0.5rem;
  border-radius: 6px;
  color: $color-black-100;
  border: 1px solid $color-background-dark;
  box-sizing: border-box;
  outline: none;
  transition: border-color 100ms;
  font-size: 1rem;
  min-height: 60px;
  height: 60px;

  &:focus {
    border-color: rgba(0, 0, 0, 0.25);
  }
}

.dropDownWrapper {
  >div {
    width: 200px;

    div {
      min-width: 100%;
    }
  }
}

.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.alertContainer {
  margin-bottom: 1rem;
}
