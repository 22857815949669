@import "../vendor/font-awesome-variables",
  "../colours";

@mixin selectCarret {
  position: relative;
  display: inline-block;

  &:after {
    pointer-events: none;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    width: 8px;
    height: 16px;
    position: absolute;
    right: 14px;
    top: 50%;
    margin-top: -9px;
    line-height: 20px;
  }
}

@mixin selectContainer {
  .select-container {
    margin: 0 5px 10px 0;

    @include selectCarret;

    select {
      appearance: none;

      &::-ms-expand {
        display: none;
      }

      outline: 0;
      font-size: 14px;
      font-family: inherit;
      color: inherit;
      line-height: 20px;
      border-radius: 3px;
      padding: 10px;
      background-color: white;
      border: 1px solid $lightest-day-grey;
      padding-right: 50px;
      min-width: 250px;
      max-width: 400px;

      option {
        background-color: white;
      }
    }
  }
}
