@import '../../../style/shared/animations';
@import '../../../style/_colours';
@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/vendor/font-awesome-variables';

.fullscreen {
  @include fontBold;
  display: block;
  font-size: 1rem;
  background-color: transparent;
  color: $color-black-100;
  border: none;
  outline: none;
  padding: 0;

  transition: 450ms opacity;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-expand);
    font-weight: $fa-weight-solid;
    margin-right: 0.5em;
  }
}

.preview {
  display: inline-block;
  margin-left: 40px;
}

.customizeLink {
  @include animateButtonFade;
  margin-right: 20px;
  padding: 6px 20px;
  display: inline-block;
  vertical-align: middle;

  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.6;
  }

  i {
    margin-right: 0.5rem;
  }

  a,
  a:visited {
    text-decoration: none;
    border: 0;
    color: inherit;
  }
}

.sharingMenu {
  display: inline-block;
  position: relative;
}

.mobileBeta {
  display: inline-block;
  padding: 6px 0px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  color: $ui-green;
}
