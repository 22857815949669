@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';
@import '../../../../style/vendor/font-awesome-variables';

$progress-bar-height: 5px;

.container {
  padding: 0 0 $progress-bar-height;
  max-width: 1162px;
  width: 95vw;
  height: 80vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  @include fontBold;

  color: $color-black-100;
  padding: 1rem 0 0.5rem 2rem;
}

.footer {
  @include fontLight;

  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-bottom: $progress-bar-height;
}

.buttons {
  background: white;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.editorMessage {
  background: $color-background-light;
  padding: 20px;
  text-align: center;
  border-radius: 0 0 4px 4px;
}

.success {
  @include fontMedium;

  color: $color-black-100;
  display: block;
  font-size: 20px;
  margin-bottom: 10px;

  &::before {
    background: $color-positive;
    border-radius: 100%;
    color: white;
    @include fontAwesome;
    content: fa-content($fa-var-check);
    display: inline-block;
    margin-right: 12px;
    font-size: 14px;
    padding-top: 7px;
    height: 26px;
    width: 26px;
    text-align: center;
    box-sizing: border-box;
  }
}

.successSubtitle {
  display: block;
  font-size: 16px;
  margin-bottom: 20px;
  color: $color-grey-100;
}

.editorContainer {
  width: 100%;
  position: relative;
  flex: 1;
  flex-grow: 2;
  overflow: hidden;
}

.loadingPositioner {
  width: 100%;
  display: flex;
  align-items: center;
}

.error {
  background: #f6f6f6;
  padding: 0.5rem 2rem;
  z-index: 1;
}
