.widget-body.linechart-widget {
  padding-top: 0 !important;
  &.no-title { padding-top: 36px !important }
}

.piechart-visualisation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    pointer-events: none;
  }
}

.linechart-old-visualisation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    pointer-events: none;
  }
}
