@import '../../../../../src/ui/style/colors';

.FooterBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-grey-20;
  box-sizing: border-box;
  height: var(--footer-height);
  width: 100%;

  padding: 12px 18px;
}
