.form {
  padding: 2rem 4rem;

  * {
    box-sizing: border-box;
  }
}

.googleButton {
  background: url(https://d2s28ygc2k7998.cloudfront.net/images/google_sign_in_button.png) no-repeat 0 0;
  background-size: 100%;
  padding: 0;
  width: 191px;
  height: 46px;
  text-indent: -9999px;
  border: none;
  cursor: pointer;

  &:active {
    background-position: bottom left;
  }

  @media (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (min-resolution: 2dppx),
    (min-resolution: 192dpi) {
      & {
      background-image: url(https://d2s28ygc2k7998.cloudfront.net/images/google_sign_in_button@2x.png);
    }
  }
}

.facebookButton {
  background-image: url('https://d2s28ygc2k7998.cloudfront.net/images/facebook_logo_login_button.svg');
  background-position: 5%;
  background-repeat: no-repeat;
  background-color: #1877F2;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 46px;
  outline: none;
  padding-left: 35px;
  width: 230px;

  &:hover {
    background-color: #0d6ae4;
  }

  &:active {
    background-color: #0b5fcc;
  }
}
