@import "../../../style/_colours";

.wrapper {
  position: relative;
  opacity: 0.35;
}

.overlay {
   position: absolute;
   z-index: 100;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}
