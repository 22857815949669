@import '../../style/colors';

:local(.radioButtonLabel) {
  cursor: pointer;
}

:local(.radioButton) {
  /* remove standard radio button appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* create custom radio button appearance */
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-clip: content-box;
  border: 2px solid $color-black-20;
  border-radius: 50%;

  margin: 0 0.5em 0 0;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 200ms, border-color 200ms;
  outline: none;

  &:hover {
    border-color: $color-black-60;
  }

  &:focus {
    box-shadow: 0px 0px 1px 2px $color-black-20;
  }

  &:checked,
  &:checked:hover {
    background-color: $color-black-100;
    border-color: $color-black-100;
  }

  &:disabled {
    background-color: $color-background-light;
    border-color: $color-background-light;
  }
}

:local(.radioButtonText) {
  vertical-align: -10%;
}
