@import "../../../style/spacing";
@import "../../../style/colours";

.mapVisualisation {
  position: absolute;
  top: 0;
  @include widgetSpacing(left right bottom);

  circle[fill='#38cdff'] {
    // When the color is the default one, overwrite it with a css variable
    @include variable('fill', --primary-visualisation-color, #38cdff);
  }
}

/*
 * AmMaps
 *
 * AmMaps adds classnames to all elements of the map.
 * A reference can be found here:
 * http://www.amcharts.com/kbase/css-class-names/
 */
:global(.amcharts-map-area-unlisted), .topoSvg path {
  @include variable('fill', --map-background-color, #4a4a4a);
  @include variable('stroke', --widget-background-color, #2a2a2a);

  opacity: 0.3;

  vector-effect: non-scaling-stroke;
  stroke-width: 0.6;
  stroke-opacity: 0.6;
}
