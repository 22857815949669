@import '../../../../../style/colours';
@import '../../../../../src/ui/style/typography';

.progressIndicatorContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.progressBarContainer {
  width: 100%;
  height: 28px;
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  transition: height 0.2s;
}

.progressBarBackground {
  background-color: var(--neutral-color, #46476e);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progressBarProgress {
  @include variable(
    background-color,
    --primary-visualisation-color,
    $blue-summer-sky
  );
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.text {
  @include fontMedium;

  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: font-size 0.2s;
}

.percentageText {
  @include variable(color, --primary-visualisation-color, $blue-summer-sky);
}

.targetText {
  color: var(--secondary-text-color, #c0c9de);
}

.targetReached {
  .percentageText {
    @include variable(color, --positive-color, $visualisation-green);
  }

  .progressBarProgress {
    @include variable(background-color, --positive-color, $visualisation-green);
  }
}
