@import '../../../../src/ui/style/colors';

.label {
  flex: 1;

  position: relative;
  z-index: 1;
  min-height: 41px;
  padding: 6px 12px 6px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-left: 56px;
  background-color: transparent;
  border-radius: 6px;
}

.radioInput {
  display: flex;
  width: 100%;

  position: relative;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 14px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid $color-background-dark;
}

.helpIcon,
.warningIcon {
  margin-left: 1ex;
}
