.text-field {
  appearance: none;

  padding: 10px;

  border: 1px solid #cfcfcf;
  border-radius: 3px;

  font: inherit;
}
