.logo {
  display: block;
  margin: 2px;
  width: 32px;
  height: 32px;
}

.serviceTitle {

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4; // required to avoid decenders being cut off.
}

.serviceColumn {
  // This aims to be wide enough to show service names without truncation.
  width: 180px;
}

.label {
  display: inline-block;
  margin-top: 3px;
  margin-left: 5px;
}
