@import '../../../../src/ui/style/colors';

.autoBtn {
  margin-right: 0.5rem;
}

.precisionNumber {
  font-size: 0.875rem;
  line-height: 1.25em;
  padding: 10px 10px 0;
  display: inline-block;
  background-color: #ffffff;
  color: $color-black-100;
  vertical-align: middle;
  height: 100%;
  width: 35px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid $color-background-dark;
  border-right: none;

  cursor: default;

  &.inactive {
    opacity: 0.6;
  }
}

.precisionGroup {
  margin-left: 0.6rem;
}
