@import '../../../../../../src/ui/style/colors';

.CollapsibleCard {
  .header {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    width: 100%;
    display: flex;
    padding: 1rem;
    background: $color-background-mid;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: $color-black-100;
    font-size: 1rem;
    position: relative;
    cursor: pointer;
    border: none;
    outline: none;

    &:hover {
      background: $color-background-dark;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $color-black-100;
    }

    .arrow {
      background: none;
      border: none;
      cursor: pointer;
      color: inherit;
      font-size: inherit;
      outline: none;
      transition: transform 0.2s ease;
      transform: rotate(90deg);
      width: 17px;
      height: 17px;
      padding: 0 0 0 3px;
    }

    &.collapsed {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      .arrow {
        transform: rotate(0deg);
      }
    }
  }

  .content {
    background: $color-background-mid;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 1px solid $color-grey-40;
    padding: 1rem;
    color: $color-grey-100;
  }
}
