@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.submitButton {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.urlInput {
  display: flex;
  align-items: center;

  label {
    @include fontMedium;

    display: block;
    margin-right: 1rem;
    white-space: nowrap;
  }

  input {
    flex: 1;
  }
}

.errorMessage {
  margin-bottom: 1rem;
  max-width: 500px;
}

.validationError {
  color: $color-negative;
  padding: 0.25rem 0 1rem;
  font-size: 0.875rem;
}
