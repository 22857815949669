@import '../../style/colours';
@import '../../style/typography';
@import '../../style/menu/variables';
@import '../../style/vendor/font-awesome-variables';

.update {
  opacity: 0.5;
  font-size: 12px;
}

.menuItemWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.menuItem {
  display: inline;
  color: $color-black-100;
  background-color: $color-background-light;
  line-height: 1rem;
  
  > span:first-child {
    &::before {
      line-height: 1rem;
    }
    width: 1rem;
    margin-right: 8px;
    text-align: center;
  }
}

.separator {
  margin: 4px 0;
  border-top: 1px solid $color-background-dark;
  width: 100%;
}

.menuItemDisabled,
.menuItemLoading {
  opacity: 0.5;
}

.spinnerWrapper {
  position: absolute;
  top: -3px;
  right: 0;
}

.menu {
  display: flex;
  flex-direction: column;
  left: 10px;
  position: absolute;
  list-style: none;
  bottom: auto;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  border: 1px solid $color-background-dark;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-width: 145px;
  background-color: $color-background-light;

  > li {
    background-color: $color-background-light;
    
    &:hover:not(.refreshTime), &:focus:not(.refreshTime) {
      div {
        background-color: $color-background-mid;
      }
    }
    
    > div {
      transition: none;
      padding: 0.25rem 1rem;
    }

    &:first-child {
      > div {
        padding-top: 8px;
      }
    }

    &:last-child {
      > div {
        padding-bottom: 8px;
      }
    }
  }

  &.inside {
    left: auto;
    right: 26px;
    opacity: 1;
  }

  &.outside {
    opacity: 1;
  }
}

.icon {
  font-size: 1.25rem;
}

.menuTooltipWrapper {
  position: absolute;
  top: -2px;
  right: 0;
}
