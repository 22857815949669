.filePickerWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.error {
  margin: 0.5rem 0 0;
}
