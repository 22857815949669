@import '../../../../style/layout';
@import '../../../../style/colours';

.container {
  width: 100%;
  max-width: $admin-content-width + 2rem;
  margin: 2rem auto 0;
  padding: 0 1rem 6rem;
  color: $text-grey;
  box-sizing: border-box;
}
