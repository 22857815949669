@import '../../../style/colours';
@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/vendor/font-awesome-variables';

$menu-button-height: 48px;
$add-button-height: 64px;

.dropMenuPicker {
  @include fontBold;

  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  .toggle {
    color: #fff;
    position: relative;
    z-index: 999;
    left: calc(32px + 1rem); // leave space for GB logo
    padding: 15px 20px 16px 20px;

    font-size: 1rem;
    white-space: nowrap;

    &::before {
      @include fontAwesome;
      content: fa-content($fa-var-bars);
      display: inline-block;
      margin-right: 6px;
    }
  }

  > ul {
    display: block;
    top: 0;
    left: 0;
    height: auto;
    z-index: 998;
    min-width: 240px;
    max-width: 400px;
    max-height: 100vh;
    overflow: visible;
    opacity: 1;
    transition: 450ms transform cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateX(-102%);
  }

  .isActiveLink {
    border-left: 5px solid $color-black-100;
    background-color: $color-background-dark;
  }

  &:global(.open) {
    > ul {
      transform: translateX(0);
      border: none;
      box-shadow: none;
    }

    .toggle {
      color: $text-grey;
    }
  }
}

.dropMenuPickerList {
  @include fontMedium;

  width: 100%;
  padding-top: $menu-button-height;
  padding-bottom: #{$add-button-height};
  background-color: white;

  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #dee0e3;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  > ul {
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    max-height: calc(100vh - #{$add-button-height} - #{$menu-button-height});
    border-top: 1px solid $secondary-grey;
  }
}

.addDashboardMenuItem {
  box-sizing: border-box;
  width: 100%;
  height: $add-button-height;
  position: relative;
  top: -#{$add-button-height};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  // margins ensure the item doesn't cover the border of the picker
  margin-top: -1px;
  margin-left: 1px;
  padding: 0px 20px; // lines the button up with the drop menu items
  border-radius: 0 0 3px 3px;
  border-top: 1px solid $secondary-grey;
  transition: 0.1s background-color ease-in-out;
}
