@import "../../../style/colours",
  "../../../style/vendor/font-awesome-variables";

.icon {
  &::after {
    cursor: pointer;
    opacity: 1;
  }

  &:hover::after {
    opacity: .7;
  }

  &.help {
    &::after {
      @include fontAwesome;
      content: fa-content($fa-var-question-circle);
    }
  }
  &.warning {
    &::after {
      @include fontAwesome;
      content: fa-content($fa-var-exclamation-triangle);
    }
  }
  &.info {
    &::after {
      @include fontAwesome;
      content: fa-content($fa-var-info-circle);
    }
  }
}

.isDark {
  &::after {
    color: $text-grey;
  }
}

.isVisible::after {
  opacity: 1;
}
