// Values taken from style/shared/_animations.scss
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$shortFade: 180ms;

@mixin animateDropMenu($transform-origin) {
  > ul {
    opacity: 0;
    transform: scale(0.6);
    transform-origin: $transform-origin;
    transition: $shortFade all $easeOutQuint;
  }

  &:global(.open) > ul {
    opacity: 1;
    transform: scale(1);
  }
}
