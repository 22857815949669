@import '../../../../src/ui/style/colors';
@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../src/ui/style/typography';

.adminModal {
  width: 560px;
  max-width: 100%;

  h3 {
    margin-bottom: 1rem;
  }
}

.adminModalImage {
  display: flex;
  justify-content: center;
}

.featureGate {
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 3rem 3.75rem;
  text-align: center;
  width: 500px;

  a {
    @include fontMedium;
    color: $color-black-100;
    cursor: pointer;
    text-decoration: underline;
  }
}

.featureGateTitle {
  display: block;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.featureGateContent,
.featureGateSupport {
  font-size: 0.9rem;
  line-height: 1.5;
}

.featureGateContent {
  margin-bottom: 1.5rem;
}

.featureGateSupport {
  margin-top: 1.5rem;
}

.featureGateAction {
  display: block;
  margin: 0 auto;
  width: 200px;
}

.inputContainer {
  padding: 1rem 0 2rem 0;

  button {
    border: solid 1px $color-grey-20;
    border-radius: 6px;

    &:focus,
    &:active {
      outline: none;
      opacity: 1;
      border: solid 1px $color-black-100;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.muted {
  color: $color-grey-60;
  font-size: 0.825rem;
}

.error {
  margin: 0.5rem 0 1rem;
  color: $color-negative;
  font-size: 1rem;
}

a.manageLink {
  color: $color-black-100;
  transition: color 0.2s ease;

  &:hover {
    color: $color-black-70;
  }
}

.manageLinkIcon {
  margin-right: 0.5rem;
}

.action {
  margin: 1.5rem auto 0 auto;
  max-width: 200px;
}
