@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.dropdownContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
}

.label {
  @include fontBold;
  color: $color-black-100;
  width: 15%;
}

.dropdown {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.dayPicker {
  margin-top: 2px;
  margin-bottom: 2px;
}
