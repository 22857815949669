@import '../../../style/colours';

:local(.geckoboardLogo) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  :local(.geckoboardLogoLink) {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.8rem 1rem;

    border-radius: 2px;
    font-size: 1.5rem;

    &:visited:hover,
    &:hover {
      color: #eee;
    }
  }

  .clickable {
    cursor: pointer;
  }
}
