@import '../../../../../../style/colours';
@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';
@import '../../../../../../style/vendor/font-awesome-variables';
@import '../../../../../../style/shared/link';

.connectorForm {
  display: grid;
  width: 1068px;
  grid-template-columns: 58% 42%;
  text-align: left;
  height: 100%;

  a {
    @include fontMedium;
    color: $color-black-100;
    text-decoration: underline;
  }

  @media screen and (max-width: 1060px) {
    width: 640px;
    display: flex;
    flex-direction: column;
  }
}

.headerContainer {
  background-color: white;
  padding: 1rem 1.5rem;
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;

  h3 {
    @include fontMedium;
    font-size: 1.5rem;
  }
}

.databaseIcon {
  margin-right: 0.313rem;
}

.form {
  padding: 1.5rem;
}

.halfFields {
  display: flex;

  .field {
    flex: 1;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.field {
  margin-bottom: 20px;

  label {
    color: $color-black-100;
    display: block;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  // it's targeting .boxPositioner in the <Dropdown /> component.
  // without this, there will be a gap between dropdown and button.
  > div > div {
    margin: 0;
  }

  input[type=password]::placeholder {
    opacity: 0.7;
    color: $color-black-100;
  }
}

.supplementary {
  color: $color-grey-80;
  display: block;
  font-size: 0.875rem;
  line-height: 1.625rem;
}

.supplementaryIcon {
  margin-right: 0.5rem;
}

.select {
  margin: 5px 0;
  width: 100%;

  select {
    background: white;
  }
}

.actions {
  padding: 1rem 0 0 1rem;
  text-align: right;

  button {
    width: 8.75rem;

    &:last-child {
      margin-left: 1.375rem;
    }
  }
}
