@import "../../../../../../style/typography";
@import "../../../../../../style/colours";

.container {
  width: 560px;
  max-width: 100%;
  max-height: calc(100vh - 16rem);
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; // Firefox requires a height value to support flex
}

.list {
  display: flex;
  flex: 1;
  min-height: 0; // Firefox requires a height value to support flex
}

.footer {
  flex: 0;
}

.infoMessage {
  font-size: 0.9rem;

  a {
    color: $ui-green;
  }
}
