@import '../../../../../src/ui/style/colors';

.ReconnectPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  padding: 20px;
}

.cancelButton {
  width: 110px;
  margin: 0 auto;
}

.ErrorMessage {
  display: block;
  border-radius: 6px;
  padding: 1rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3;
  background: rgba($color-negative, 0.1);
  color: $color-negative;
  text-align: center;

  .icon {
    display: block;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

.ImportingMessage {
  display: block;
  border-radius: 6px;
  padding: 1rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3;
  background: $color-background-mid;
  color: $color-black-100;
  text-align: center;

  .icon {
    display: block;
    text-align: center;
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
  }
}

.Refresh {
  text-align: center;
}
