@import '../../../style/colours', '../../../src/ui/style/colors',
  '../../../src/ui/style/typography', '../../../style/shared/animations',
  '../../../style/vendor/font-awesome-variables';

.masthead {
  @include fontBold;

  flex: 1;
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.legacyDashboardMasthead {
  position: relative;
  background-color: #ffffff;
  border-bottom: solid 1px $color-background-dark;
  color: $color-black-100;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: 1rem;

  > span {
    align-self: center;
    display: flex;
  }
}

.crumb {
  line-height: 48px;
  max-width: 500px;
  padding: 0 0 0 16px;
  overflow: hidden;

  border: 0;
  color: $color-black-100;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;

  transition: 300ms color $easeOutQuint;

  &:hover {
    color: $gray-lavender;
  }

  &:link {
    border: 0;
  }
}

// The masthead is hidden when in fullscreen mode
body:fullscreen .masthead {
  display: none;
}

// The masthead is responsive on dashboards
.masthead {
  @include fontBold;
  min-width: auto;
}

.dashifyButton {
  margin-left: 16px;
  align-self: center;
}

.setContainerLayoutButton {
  align-self: center;
}
