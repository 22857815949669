@import "../../../../../style/colours",
  "../../../../../style/vendor/font-awesome-variables";

.value {
  display: inline-block;
  white-space: nowrap;
}

.indicator::before {
  display: inline-block;
  margin-right: 0.5em;
  content: '';
}

.increased {
  @include variable(color, --comparison-color, $visualisation-green);

  .indicator::before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-up);
    font-weight: $fa-weight-solid;
  }
}

.decreased {
  @include variable(color, --comparison-color, $error-red);

  .indicator::before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
  }
}

.unchanged {
  @include variable(color, --comparison-color, $warning-yellow);

  .indicator::before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-right);
    font-weight: $fa-weight-solid;
  }
}

.blankComparison {
  .indicator::before {
    content: "–";
  }
}

.reversed {
  &.decreased {
    @include variable(color, --comparison-color, $visualisation-green);
  }

  &.increased {
    @include variable(color, --comparison-color, $error-red);
  }
}
