@import "../../../style/colours";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.column {
  width: 70px;
  float: left;
  height: 128px;

  &.large {
    height: 260px;
    width: 150px;
  }
}

.positive {
  background-color: $rag-green;
}

.level {
  background: $orange-beta;
}

.negative {
  background-color: $error-red; 
}

.table {
  width: 118px;
  margin-left: 80px;
  display: block;

  tr {
    border-bottom: solid 1px #363636;

    &:last-child { border-bottom: none }
    &:first-child { td { padding-top: 0 } }
  }

  td {
    overflow: visible;
    padding: 8px 0;

    &.number {
      max-width: 86px;
      font-size: 30px;
    }

    &.secondary {
      max-width: 30px;
      font-size: 12px;
      padding-left: 12px;
    }
  }

  &.large {
    width: 268px;
    margin-left: 170px;

    td {
      padding: 20px 0;

      &.number {
        max-width: 115px;
        font-size: 60px;
      }

      &.secondary {
        font-size: 24px;
        padding-left: 20px;
        max-width: 53px;
      }
    }
  }
}

