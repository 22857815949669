@import '../../../../src/ui/style/colors';

.section {
  width: 100%;
  margin: 0 0 3rem;
  color: #000;

  a {
    font-weight: bold;
    color: inherit;
  }

  h2,
  legend {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2;
  }

  label {
    font-size: 0.8rem;
  }
}
