@import '../../../../../../style/colours';

.container {
  text-align: left;
}

.headerContainer {
  background-color: white;
  padding: 1rem 1.5rem;
}

.header {
  align-items: center;
  display: flex;

  h3 {
    font-size: 24px;
    flex: 1;
  }
}

.icon {
  margin-right: 5px;
}

.connectionList {
  padding: 1rem 1.5rem;
}
