.loadingContainer {
  height: 100vh;
  display: flex;
  align-items: center;
}

.errorContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
