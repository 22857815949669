.timezoneWrapper {
  display: flex;
}

.timezoneWrapper > * {
  flex-grow: 1;
}

.timezoneWrapper > *:first-child {
  margin-right: 5px;
}

.timezoneWrapper > *:last-child {
  margin-left: 5px;
}
