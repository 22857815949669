:local(.panelContainer) {
  position: fixed;
  z-index: 9000;

  &:before {
    content: '';
    background: rgba(10, 8, 26, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    opacity: 0;
    transition: var(--animation-speed) opacity;
  }
}

:local(.panelContainer--open) {
  &:before {
    opacity: 1;
  }
}

:local(.panel) {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background: #fff;
  z-index: 1;
  transition: transform var(--animation-speed) ease-in-out;
}

:local(.panel--left) {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

:local(.panel--right) {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

:local(.panel--open) {
  transform: translate3d(0, 0, 0);
}
