.inlineSettings {
  display: flex;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}

.label {
  flex: 1;
  align-self: center;
}
