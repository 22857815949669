@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/vendor/font-awesome-variables';

a.ManageConnectionsLink {
  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-cog);
    font-weight: $fa-weight-solid;
    margin-right: 0.3rem;
  }

  @include fontBold;

  display: block;
  transition: 0.15s all ease-out;
  flex: 0 0 auto;
  align-self: center;
  border-bottom: none;
  color: $color-black-100;
  margin-bottom: 12px;

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    border-bottom: 1px solid $color-black-100;
    outline: none;
  }
}
