.Resizer__mouseDown * {
  cursor: col-resize !important;

  .ResizerBar:hover {
    opacity: 0.2;
  }
}

.ResizerColumn {
  position: relative;
  user-select: none;
  opacity: 0;
  inset: 0;
  transition: opacity 225ms;
}

.Resizer { 
  position: absolute; 
  display: flex;
  z-index: 2;
  inset: 0;

  &:hover .ResizerColumn {
    opacity: 1;
  }
}

.ResizerBar {
  position: absolute;
  right: -8px;
  width: 16px;
  height: 100%;
  cursor: col-resize;
  z-index: 10;
  user-select: all;
  opacity: 0.2;
  transition: opacity 225ms;
  border-radius: 4px;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    width: 6px;
    height: 100%;
    background: var(--text-color);
    transform: translate(-50%, 0);
    border-radius: 6px;
  }

  &.clicked {
    opacity: 1;
  }

  &:hover{
    opacity: 1;
  }
}
