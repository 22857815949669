@import '../../../../../src/ui/style/colors';

.DurationInput {
  display: flex;
}

.durationSection {
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-right: 0px;
  }

  .durationLabel {
    flex: 0;
    font-size: 16px;
    padding: 2px 4px 0;
    color: $color-grey-100;
  }
}
