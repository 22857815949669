.input {
  flex: 0 0 auto;
  width: 100% !important;
  padding: 6px 25px 6px 15px !important;
  border: none;
  background-color: transparent;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
}

.inputWrapper {
  display: flex;
  flex: 1;
}
