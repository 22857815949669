@import '../compact-config-colors';

.ghostInput {
  height: 53px;
  padding-top: 8px;
}

.label {
  height: 9px;
  width: 90px;
  border-radius: 3px;
  background: $ghost-colour;
  margin-bottom: 7px;
}

.value {
  height: 14px;
  width: 200px;
  border-radius: 3px;
  background: $ghost-colour;
}
