@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.dropdownSection {
  margin-bottom: 1rem;
}

.dropdownContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
}

.label {
  @include fontBold;
  color: $color-black-100;
  width: 15%;
}

.dropdown {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.postTimeSection {
  display: flex;
  align-items: center;
  width: 85%;
}

.postTimeDropdown {
  width: 40%;
}

.postTimeTimezone {
  margin-left: 1rem;
  width: 60%;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.removeButton {
  align-self: flex-end;

  button {
    padding-left: 0;
  }
}

.error {
  margin-top: 1rem;
}

.dayPicker {
  margin-top: 2px;
  margin-bottom: 2px;
}
