@import '../../../style/colours';
@import '../../../src/ui/style/colors';

$numberTextLineHeight: 1.2em;

.input {
  min-width: 100px;
  max-height: $numberTextLineHeight * 2;
  @include variable(color, --text-color, $text-colour);

  &.hasValue,
  &:focus,
  &:hover {
    opacity: 1 !important;
  }

  &:hover {
    border-color: $color-grey-60;
  }
}

.restoreButton {
  // Button style reset
  appearance: none;
  font-size: inherit;
  background: inherit;
  color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  color: $gray-main;

  &:hover,
  &:focus,
  &:active {
    opacity: 1 !important;
  }
}
