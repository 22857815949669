.default {
  position: fixed;
  left: 0;
  bottom: 0;
  display: inline-flex;
  flex: 0 0 auto;
  padding-bottom: 1rem;
  padding-left: 1rem;
  overflow: hidden;
  z-index: 9999999999; // can be rendered above popover / modal background / intercom bubble
}

.preview {
  composes: default;
  left: 480px; // Size of the left panel in config view
}

.right {
  composes: default;
  left: auto;
  right: 0;
  padding-right: 1rem;
}
