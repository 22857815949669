@import '../../../style/colors';
@import '../../../style/typography';

.TreeNode {
  margin: 0;
}

.TreeNodeButton {
  @include fontLight;
  appearance: none;
  border: none;
  width: 100%;
  color: $color-grey-100;
  font-size: 1rem;
  text-align: left;
  position: relative;
  line-height: 1.3;
  background: transparent;
  margin: 0;

  .rightChevron {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    padding-right: 12px;
    font-size: 0.8rem;
    color: $color-grey-60;
  }

  .label {
    display: block;
    overflow-wrap: anywhere;
  }

  .secondaryLabel {
    display: block;
    font-size: 0.825rem;
    color: $color-grey-80;
    margin-top: 2px;
    overflow-wrap: break-word;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    background: $color-background-light;
    color: $color-black-40;

    .label {
      font-weight: 500;
    }
  }

  &.active {
    background: $color-background-mid;
    color: $color-black-100;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: $color-black-100;
    }

    .rightChevron {
      color: $color-grey-100;
    }
  }

  &.focused {
    outline: 2px solid Highlight;
    outline: 2px solid -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      background: $color-grey-5;
    }

    &:active {
      background: $color-grey-10;
    }
  }

  &.leaf {
    .rightChevron {
      visibility: hidden;
    }

    &:hover,
    &:focus {
      .rightChevron {
        visibility: hidden;
      }
    }
  }
}

.TreeNodeButton--expanded {
  padding: 16px 36px 16px 20px;
}

.TreeNodeButton--dense {
  padding: 8px 36px 8px 20px;
}
