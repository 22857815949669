@import '../../../../../src/ui/style/colors';

.MetricSortBy {
  color: $color-black-100;

  .dropdowns {
    display: flex;
  }

  .dropdown {
    flex: 1;
    overflow: hidden;

    &:first-of-type {
      margin-right: 20px;
    }
  }
}
