@import '../../../style/colors';
@import '../../../style/typography';

.TreeSelectFooterButton {
  @include fontMedium;
  appearance: none;
  background: white;
  border: none;
  border-top: 1px solid #ececec;
  color: $color-grey-100;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  outline: none;
  padding: 16px 20px;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus {
    background: $color-grey-5;
  }

  &:active {
    background: $color-grey-10;
  }

  &:disabled {
    color: $color-grey-40;
  }

  .icon {
    margin-right: 0.5rem;
  }
}
