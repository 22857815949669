@import "../colours";

.chart {
  @include typography;
  font-size: 0.875rem;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  .axis {
    .tick {
      @include variable(fill, --secondary-text-color, $gray-lavender);
      stroke: none;

      text {
        @include variable(fill, --text-color, $text-colour);
        stroke: none;
      }

      line {
        @include variable(fill, --chart-axis-color, $gray-lavender);
        stroke: none;
      }
    }

    path, line {
      fill: none;
      stroke: none;
    }

    .axis-y .tick text {
      text-anchor: start !important;
    }
  }

  .line-group {
    fill: none;
    stroke-width: 1.5px;
    stroke-linecap: round;

    &.incomplete {
      stroke-dasharray: 5px,5px;
    }

    &.secondary {
      stroke-width: 1px;
      stroke-dasharray: 5px,5px;
    }
  }

  .grid {
    fill: none;
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }

  .grid-x .tick {
    transition: 0.1s linear stroke;
    @include variable(stroke, --widget-background-color, $gray-jaguar);
    
    stroke-width: 3px;
  }

  .grid-x-threshold .tick {
    transition: 0.1s linear stroke;
    @include variable(stroke, --chart-threshold-color, $gray-montana);
    stroke-width: 3px;
  }

  .grid-y .tick {
    @include variable(stroke, --chart-axis-color, $transparent-white);

    &.zero {
      @include variable(stroke, --chart-axis-color-strong, $translucent-white);
      stroke-width: 2;
    }
  }

  .legend {
    line {
      stroke-width: 4px;
      stroke-linecap: round;
    }

    text {
      @include variable(fill, --secondary-text-color, $gray-lavender);
      text-anchor: start;
      font-size: 0.875rem;
    }
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .tooltip-overlay {
    fill: none;
    pointer-events: all;
    cursor: default;
  }

  .tooltip-label-wrapper {
    opacity: 0;
    clear: both;
    text-align: center;
    cursor: default;

    .tooltip-label {
      display: inline-block;
      padding: 6px 0;
      color: #fff;
      background-color: rgba(0,0,0, 0.7);
      border-radius: 4px;
      text-align: center;
      text-shadow: none;

      li {
        padding: 0 12px;
        text-align: right;
        &:first-of-type {
          text-align: center;
          border-bottom: 1px solid rgba(255,255,255, 0.2);
          padding-bottom: 4px;
          margin-bottom: 4px;
          font-weight: 500;
        }
      }
    }
  }

  .tooltip-focus {
    cursor: default;

    .tooltip-dot {
      stroke: $gray-jaguar;
      stroke-width: 1.5px;
    }

    .tooltip-dashed-line {
      stroke: $gray-ship;
      stroke-width: 2px;
      stroke-dasharray: 0, 8;
      stroke-linecap: round;
    }
  }

  .threshold {
    transition: 0.1s linear fill;
    @include variable(fill, --chart-threshold-color, $gray-montana);
  }

  .currentPeriod {
    transition: 0.1s linear fill;
    opacity: 0.3;
    @include variable(fill, --positive-color, $rag-green); 
  }

  .bar.goal {
    @include variable(fill, --positive-color, $rag-green);
  }
}
