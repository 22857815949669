@import '../../../../style/vendor/font-awesome-variables';

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  &.negativeState {
    --widget-background-color: transparent;
  }

  &.positiveState {
    --widget-background-color: transparent;
  }

  &.highlightSIFrame {
    z-index: 21;
  }

  &.isMenuOpenOnSingleFrame {
    z-index: 20;
    background-color: var(--widget-background-color);
  }
}

.statusIndicator {
  box-sizing: border-box;
  border-radius: calc(7.5px * var(--scale-factor, 0.8));
  background-color: var(--container-background-color);

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .negativeState & {
    border: 2px solid var(--negative-color);
    background-color: rgba(236, 55, 60, 0.1); //negative-color, with low opacity
  }

  .positiveState & {
    border: 2px solid var(--positive-color);
    background-color: rgba(15, 211, 84, 0.1); //positive-color, with low opacity
  }

  &.isMenuOpen {
    // must be larger than the z-index of the menu overlay
    z-index: 25;
  }

  // This provides some spacing between the frame and the status indicator
  // when in a grouped widget container.
  &.isGrouped {
    height: calc(100% - 1rem * var(--scale-factor));
    width: calc(100% - 1rem * var(--scale-factor));
    top: calc(0.5rem * var(--scale-factor));
    left: calc(0.5rem * var(--scale-factor));
  }
}

.statusIndicatorIcon {
  position: absolute;
  width: calc(60px * var(--scale-factor, 0.8));
  height: calc(60px * var(--scale-factor, 0.8));
  right: calc(-11px * var(--scale-factor, 0.8));
  bottom: calc(-12px * var(--scale-factor, 0.8));
  border-radius: 50%;
  box-sizing: border-box;
  padding-top: calc(6px * var(--scale-factor, 0.8));

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: $fa-weight-solid;
  font-size: calc(2.4rem * var(--scale-factor, 0.8));

  .positiveState & {
    background-color: var(--positive-color);
  }

  .negativeState & {
    background-color: var(--negative-color);
  }
}
