@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.TimezoneNotification {
  margin: 10px -15px -15px -15px;

  a {
    cursor: pointer;
  }
}
