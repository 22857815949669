@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../src/ui/style/colors';

.customTimespanWrapper {
  padding: 9px 20px 18px 13px;
  border-radius: 6px;
  border: solid 2px $color-black-100;
  background-color: #ffffff;

  header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 26px 0;
  }
}

.closeButton {
  color: $color-black-60;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  outline: none;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inputQuantity {
  width: 25%;
  margin-left: 20px;
}

.inputUnit {
  width: 42%;
  margin-left: 10px;
  position: relative;
}

.hint {
  color: $color-grey-80;
  font-size: 14px;
  padding: 5px 0 0 137px;
  height: 25px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;

  button {
    width: 120px;
  }

  button:last-child {
    margin: 0 0 0 10px;
  }
}
