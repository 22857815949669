$size: 40px;

.base {
  background-color: var(--neutral-color);
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: $size;
  max-height: $size;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  object-fit: cover;
}

.fallback {
  position: absolute;
  inset: 0;
}

.image-type-plain {
  border-radius: 4px;
}

.image-type-avatar {
  border-radius: 9999px;
}
