@import "../colours";

.barchart {
  font-size: 0.875rem;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .bar {
    @include variable(fill, --primary-visualisation-color, $blue-summer-sky);
  }

  .goal.bar {
    @include variable(fill, --positive-color, $rag-green);
  }

  .separator {
    @include variable(stroke, --chart-axis-color, $transparent-white);
    stroke-width: 1;
  }

  .label, .value {
    overflow: hidden;

    @include variable(fill, --text-color, $text-colour);
    @include variable(color, --text-color, $text-colour);

    text-overflow: ellipsis;
  }

  &.horizontal {
    .labels {
      display: flex;
    }

    .label {
      flex: 1;
    }

    .label, .value {
      white-space: nowrap;
      display: inline-block;
    }

    .value {
      text-align: right;
    }
  }

  &.vertical .label {
    max-height: 2em;

    text-align: center;
  }

  .threshold {
    transition: 0.2s linear fill;
    @include variable(fill, --chart-threshold-color, $gray-montana);
  }

  .value-axis {
    @include variable(fill, --text-color, $text-colour);
    shape-rendering: crispEdges;

    path {
      display: none;
    }

    line {
      @include variable(stroke, --chart-threshold-color, $gray-charcoal);
    }

    .tick text {
      text-anchor: start !important;
    }
  }
}
