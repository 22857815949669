@import '../../../../src/ui/style/colors';

.input {
  font-size: 1rem;
  font-family: inherit;
  width: 100%;
  appearance: none;
  border: 1px solid $color-background-dark;
  border-radius: 6px;
  padding: 0.5rem;
  transition: border-color 100ms;
  outline: none;

  &::placeholder {
    opacity: 0.5;
  }

  &.focus {
    border-color: $color-black-100;
  }
}
