@import "../../../../style/typography";

.configFormWrapper {
  @include typography;

  display: flex;
  flex: 0 0 auto;
  width: 480px;
  overflow: hidden;
  font-size: 16px;
}

.flexForm {
  display: flex;
  flex: 1;
  overflow: auto;
}
