@import "../colours";

@mixin link {
  transition: color .2s, border-color .2s;

  color: $ui-green;
  border-bottom: 1px solid $ui-green;

  &:hover {
    color: darken($ui-green, 10%);
    border-bottom-color: darken($ui-green, 10%);
  }
}
