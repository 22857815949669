@import "../../../style/_colours.scss";
@import "../../../style/vendor/_font-awesome-variables.scss";

.textDropdown {
  position: relative;
  display: inline-block;

  &:after {
    pointer-events: none;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
    line-height: 14px;
  }

  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }

    outline: 0;
    font: inherit;
    background-color: transparent;
    color: inherit;

    border: none;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 10px + 8px + 5px; // leave room for caret

    &:hover {
      cursor: pointer;
    }

    option {
      background-color: white;
      color: black;
    }
  }

}
