@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.footerBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  padding: 5px;

  .leftHandSide {
    display: flex;
    align-items: center;
    min-width: 280px;
    flex: 4;

    .textContainer {
      justify-self: center;
      display: flex;
      flex-direction: column;
      padding: 5px 10px 5px 16px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .chatToUsButton {
      margin: 0 1rem;
    }

    .browseHelpCenterButton {
      margin-left: 1rem;
    }
  }
}

.heading {
  @include fontBold;
  font-size: 1.75rem;
  color: $color-black-100;
  margin: 0;
}

.subheading {
  @include fontLight;
  font-size: 1rem;
  color: $color-black-100;
  margin: 0;
}
