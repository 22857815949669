@import '../ui/style/colors', '../ui/style/typography';

.surveyForm h4 {
  font-size: 1rem;
  margin-top: 1rem;
}

.checkboxWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.question {
  margin-top: 2rem;
}

.integrationButton {
  display: inline-flex;
}

.integrationButtonLabel {
  margin-left: 10px;
}

.integrationIcon {
  margin-top: -1px;
  margin-left: -9px;
}

.suggestedintegrationButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
}

.suggestedIntegrationLabel {
  @include fontLight;
  margin: 0.5em;
}

.suggestedIntegrationTime {
  @include fontLight;
  font-size: 13px;
  line-height: 20px;
  color: $color-black-40;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-size: 13px;
    height: 14px;
  }
}

.browseButton {
  button {
    padding: 0;
  }
}
