@import '../../../../../../src/ui/style/colors';

.NavigationCard {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: $color-background-mid;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 1rem;
  width: 100%;
  text-align: left;

  &:hover {
    background: $color-background-dark;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-black-100;
  }
}
