
// Variables with easings Bezier curves
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

// Timing variables
$shortFade: 180ms;
$buttonHover: 200ms;
$buttonFade: 450ms;

@mixin animateProperty($property: all) {
  transition: $property $shortFade $easeOutQuint;
}

@mixin animateButtonFade {
  transition: $buttonFade opacity $easeOutQuint;
}

@mixin animateButtonHover {
  transition: $buttonHover all $easeOutQuint;
}

@mixin animateDropMenu($transform-origin) {
  > ul {
    opacity: 0;
    transform: scale(0.6);
    transform-origin: $transform-origin;
    transition: $shortFade all $easeOutQuint;
  }

  &.open > ul {
    opacity: 1;
    transform: scale(1);
  }
}
