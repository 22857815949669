@import '../../../style/colours';
@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/shared/animations';

.card {
  border-radius: 6px;

  .header {
    padding: 1rem;
  }

  .body {
    padding: 1rem;
    border-top: 1px solid $color-grey-40;

    &.bodyOnly {
      border-top: none;
    }

    &.slim {
      padding: 0.5rem;
    }

    &.superSlim {
      padding: 3px;
    }
  }

  &.canClick {
    cursor: pointer;
    transition: 0.1s background-color linear;
  }

  &.default {
    background: $color-background-mid;

    &.canClick:hover {
      background: $color-background-dark;
    }
  }

  &.lighter {
    background: $mid-light-grey;
    color: $text-colour;

    &.canClick:hover {
      background: $mid-grey;
    }
  }

  &.lightest {
    background: $secondary-grey;
    color: $text-grey;

    &.canClick:hover {
      background: $light-grey;
    }
  }

  &.light {
    background: white;
    border: 1px solid $color-grey-20;

    &.canClick:hover {
      background: $light-grey;
    }
  }
}

.headerContent {
  @include fontMedium;
}

.disabled {
  opacity: 0.3;
}

.group {
  border-bottom: 1px solid $color-grey-40;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-right: 1rem;
  padding-left: 1rem;

  &:last-of-type {
    border-bottom: 0;
  }
}

.toggleButton {
  @include fontLight;

  border: none;
  background: none;
  font-size: 1rem;
  color: $color-black-100;
  border-radius: 6px;
  margin: -8px;
  padding: 8px;
  width: 70px;
  -webkit-appearance: none;
  outline: none !important;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $color-background-light;
  }
}
