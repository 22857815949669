@import '../../../../style/colours';
@import '../../../../style/typography';
@import '../../../../src/ui/style/typography';

.presetPanelContentOuter {
  overflow: scroll;
  flex: 1;
}

.presetPanelContentInner {
  padding: 20px;
}

.presetPanelButton {
  font-size: 1rem;

  color: $body-background;

  appearance: none;

  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  margin: 0;
}

.presetPanelDescription {
  @include fontLight;

  background-color: $color-background-mid;
  padding: 15px 20px 15px 20px;
  text-align: left;
}
