@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';

.toast {
  @include fontMedium;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  color: $color-black-100;
  min-width: 415px;
  max-width: 20vw;

  &.autoWidth {
    min-width: auto;
  }

  &.delayedAnimation {
    transition-delay: 3s;
  }

  transition: transform 0.2s, background-color 0.15s, color 0.15s;

  &:global(.toast-enter),
  &:global(.toast-appear) {
    transform: translateY(calc(100% + 1rem));
    transition-timing-function: ease-out;
  }

  &:global(.toast-enter-active),
  &:global(.toast-appear-active) {
    transform: translateY(0);
  }

  &:global(.toast-exit) {
    transition-delay: 0s;
    transform: translateY(0);
    transition-timing-function: ease-in;
  }

  &:global(.toast-exit-active) {
    transform: translateY(calc(100% + 1rem));
  }

  p {
    flex: 1;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.3;
    margin: 0.5rem 1rem;
  }
}

.success {
  border-top: solid 6px $color-geckoboard-green;
}

.error {
  border-top: solid 6px $color-negative-on-dark;
}

.toastIcon {
  font-size: 1.4rem;
  padding-left: 1rem;
  flex: 0;
}

.toastIconLarge {
  font-size: 2rem;
}
