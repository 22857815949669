@import '../../../../src/ui/style/colors';

.customizePanel {
  position: fixed;
  width: 30%;
  min-width: 400px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 2;
  padding-top: 96px;
  display: flex;

  &:global(.panel-enter),
  &:global(.panel-appear) {
    transform: translateX(-100%);
  }

  &:global(.panel-enter-active),
  &:global(.panel-appear-active) {
    transform: translateX(0);
    transition: transform 0.2s ease-out;
  }

  &:global(.panel-exit) {
    transform: translateX(0%);
  }

  &:global(.panel-exit-active) {
    transform: translateX(-100%);
    transition: transform 0.2s ease-out;
  }
}
