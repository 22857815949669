@import '../../../style/vendor/mq', '../../../style/colours',
  '../../../src/ui/style/colors';

.lLoading {
  margin-top: 128px;
}

.popover {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;

  cursor: pointer;

  &::after {
    transition: opacity 0.1s ease-out;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100;

    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
  }

  .container {
    position: fixed;
    bottom: 0;
    z-index: 2001;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }

  :global(.panel-enter),
  :global(.panel-appear) {
    transform: translateX(100%);
  }

  :global(.panel-enter-active),
  :global(.panel-appear-active) {
    transform: translateX(0);
    transition-duration: 0.2s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }

  :global(.panel-exit) {
    transform: translateX(0%);
  }

  :global(.panel-exit-active) {
    transform: translateX(100%);
    transition-duration: 0.2s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }

  @include mq($from: desktop) {
    &.sidePanel {
      top: 96px;

      &::after {
        top: 96px;
      }

      .container {
        display: block;
        position: static;
      }

      .content {
        display: flex;
        flex-direction: column;
        height: auto;
        position: fixed;
        z-index: 1000;
        margin: 0;
        right: 0;
        top: 96px;
        bottom: 0;
        width: auto;
        min-width: 630px;
        max-width: 100%;
        border-radius: 0;
      }

      &:global(.popover-enter) {
        opacity: 0;
      }

      &:global(.popover-enter-active) {
        transition-duration: 0.2s;
        transition-property: opacity;
        transition-timing-function: ease-out;

        opacity: 1;
      }

      &:global(.popover-exit) {
        opacity: 1;
      }

      &:global(.popover-exit-active) {
        transition-duration: 0.2s;
        transition-property: opacity;
        transition-timing-function: ease-out;

        opacity: 0;
      }

      :global .popover-header {
        flex: none;

        display: block;
        margin: -60px -60px 0;
        padding: 60px 60px 20px;

        background-color: $popover-header-background-colour;

        &.small {
          padding-top: 30px;
        }
      }

      :global(.popover-main) {
        flex: 1 0 auto;
      }

      :global(.popover-footer) {
        flex: none;

        padding: 40px 60px 30px;
        margin: 100px -60px -60px;
      }
    }
  }

  .content {
    z-index: 2;
    max-width: 640px;
    margin: 60px auto;
    background-color: $color-background-light;
    color: $color-black-100;
    border-radius: 4px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    cursor: default;

    > div {
      height: 100%;
    }

    :global .form-field {
      > label {
        background-color: $field-label-background-colour-light;

        &.focus {
          background-color: $field-border-focus-colour-light;
        }
      }
      &.invalid {
        > label {
          background-color: $error-red;
        }
      }
    }

    :global(.button-row) {
      margin-top: 2em;
    }

    :global(.button-row .btn-secondary) {
      float: left;
      margin-left: 0;
    }

    :global(.popover-footer) {
      margin: 0 -20px -20px;
      padding: 20px;
      display: block;
      text-align: left;

      background-color: $popover-footer-background-colour;
    }
  }
}
