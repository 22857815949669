@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.label {
  @include fontMedium;
}

.controlsContainer {
  flex: 1;
  margin-top: 4px;
}

.controls {
  flex: 1;
  display: flex;
}

.amount {
  flex: 1;
  margin-right: 20px;
}

.unit {
  flex: 2;
}

.hint {
  display: block;
  color: $color-grey-60;
  margin-top: 10px;
}

.error {
  display: block;
  color: $color-negative;
  margin-top: 10px;
}
