@import '../../style/colors';

.MultiSelect {
  font-size: 1rem;
}

.DropdownIndicator {
  font-size: 12px;
  color: $color-black-70;
  padding: 0 12px;
}

.asterisk {
  font-size: 0.5rem;
  color: $color-black-100;
  position: absolute;
  top: 0;
  right: 1rem;
  bottom: 0;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
