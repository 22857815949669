@import '../../../../src/ui/style/colors', '../../../../style/vendor/mq',
  '../../../../style/colours';

.disabledOverlay {
  align-self: center;
}

.visualisationPicker {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid $color-background-dark;

  li {
    flex: 1;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.icon {
  background-color: #ffffff;
  border-radius: 4px;
  display: block;
  cursor: pointer;
  padding: 12px 8px;
  text-align: center;
  color: $color-black-100;
  width: 100%;
  margin: 0;
  border: none;
  font-size: 1.25rem;

  transition: 0.1s all ease-out;

  &::before {
    padding: 1px 2px;
  }

  &:hover,
  &:focus {
    background-color: $color-background-mid;
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-black-100;
  }

  &.active {
    background-color: $color-black-100;
    color: white;
  }
}
