@import '../../style/colors';

:local(.Border) {
  background: white;
  display: inline-block;
  border: 1px solid $color-grey-10;
  box-sizing: border-box;

  &.rounded {
    border-radius: 6px;
    overflow: hidden;
  }

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3) !important;
  }
}
