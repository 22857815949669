@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';
@import '../../style/vendor/font-awesome-variables';
@import '../../style/vendor/mq';

.ServiceList {
  background-color: $color-background-light;
  color: $color-black-100;
  min-height: 100vh;
  height: 100%;
}

.wrapper {
  @include fontBold;

  margin: 0 auto;
  max-width: 120em;
  min-width: 20em;
}

.connectionsHeading {
  flex: 1;
  padding-right: 1rem;
}

a.manageLink {
  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-cog);
    font-weight: $fa-weight-solid;
    margin-right: 0.3rem;
  }

  @include fontBold;

  transition: 0.15s all ease-out;
  flex: 0 0 auto;
  align-self: center;
  border-bottom: none;
  color: $color-black-100;

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    border-bottom: 1px solid $color-black-100;
    outline: none;
  }
}

.IntegrationsList {
  section {
    padding-bottom: 2rem;
  }
}

.freeToConnectHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  button {
    padding: 0;
  }
}

.restrictedServices {
  background-color: #ffffff;
  margin: 0 -2rem 2rem 0;
  padding: 2rem 2rem 0 2rem;
  border-radius: 10px;
  border: 2px solid #d4d3db;
  overflow: hidden;

  p {
    @include fontMedium;
  }
}

.restrictedServiceList {
  background-color: #f4f4f7;
  margin: 0 -2rem;
  padding: 2rem;
}
