.previewContainer {
  flex: 1;
}

.previewPlaceholder {
  background: #272953;
  border-radius: 10px;
  height: 100%;
  padding: 2.5rem 1rem 1rem 1rem;
  color: var(--text-color);
  font-size: 1rem;
  
  display: flex;
  flex-direction: column;

  span {
    display: block;
  }

  > div {
    opacity: 0.8;
  }

  div {
    line-height: 1.5;
    margin: 0 auto;
    text-align: center;
    width: 65%;
    font-size: 1.5vw;

    img {
      margin-top: 1rem;
      width: 50%;
    }
  }
}

.paddingFlexLayout {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  padding: 1rem;
}
