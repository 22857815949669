@import '../../../src/ui/style/colors';

.container {
  width: 100%;
  padding: 2.4rem 1rem 1.8rem;
  color: $color-grey-100;
  box-sizing: border-box;
  max-width: 70.75rem;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4;
    margin: 0.5rem 0 1.75rem 0;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.4;
    margin: 0.5rem 0 1.5rem 0;
  }
}
