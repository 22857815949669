@import '../../../style/colours';
@import '../../../src/ui/style/colors';
@import '../../../style/vendor/_font-awesome-variables.scss';

.previewLayout {
  position: relative;
  flex: 1 1 0;
  width: auto;
  min-height: 0;

  // Our geckometer svg does not scale well with preserveAspectRatio
  // Since we want to force a max height in preview mode, we'll target the svg to use flex box
  &.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 600px;

    & > .svg {
      flex: 1 1 auto;
    }
  }
}

.wrapper {
  font-size: 0.875rem;
  height: 100%;
  box-sizing: border-box;
  @include variable(fill, --text-color, $gray-charcoal);
}

.value {
  font-size: 78px;
}

.min,
.max {
  font-size: 28px;
}

.svg {
  height: 100%;
  width: 100%;
}

.gaugeArc {
  @include variable(fill, --neutral-color, #39393b);
}

.gaugeNeedle {
  @include variable(fill, --gauge-needle-color, $gray_athens);
  @include variable(stroke, --gauge-needle-color, $gray_athens);
}

.gaugeNeedleReady {
  transition: transform 0.5s ease-in-out;
}

.positive {
  @include variable(fill, --positive-color, $color-positive);
}

.warning {
  @include variable(fill, --negative-color, $color-negative);
}

.amber {
  @include variable(fill, --caution-color, $color-warning);
}

.outOfBoundsWarning {
  position: absolute;
  top: 50%;
  left: 50%;

  // Keeps it nicely offset from vertical center and scales with font-size
  transform: translate(-50%, -140%);

  span {
    &::before {
      @include variable(color, --text-color, $gray_athens);
      @include fontAwesome;
      content: fa-content($fa-var-exclamation-triangle);
      opacity: 0.4;
    }
  }
}
