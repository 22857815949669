.info-section {
  color: white;
  margin: 170px auto;
  width: 50%;
  text-align: center;
  font-size: 120%;
  h1 {
    margin-bottom: 1.5em
  }
}
