@import '../../../../style/colours', '../../../../src/ui/style/colors',
  '../../../../style/vendor/font-awesome-variables';

$width: 60;
$height: 30;
$ballDiameter: 20;

$gutter: calc(($height - $ballDiameter) / 2);

.toggleInput {
  position: relative;
  display: inline-block;
  width: #{$width}px;
  height: #{$height}px;
  float: right;

  border-radius: 15px;

  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  font-size: 11px;
  line-height: #{$height}px;
  vertical-align: bottom;

  color: #fff;

  input {
    position: absolute;
    left: -999em;
  }

  &::after,
  &::before {
    box-sizing: border-box;
    display: inline-block;
    width: #{$width}px;
    vertical-align: top;
    height: #{$height}px;
  }

  &::after {
    content: '';
    padding-right: 7px;
    text-align: right;

    background-color: $color-grey-40;
  }

  &::before {
    transition: 0.1s margin-left ease-in;

    margin-left: -#{$width}px;
    padding-left: 7px;
    content: '';

    background-color: $color-black-100;
  }

  &.checked {
    &::before {
      margin-left: 0px;
    }
  }

  &:global(.disabled) {
    cursor: default;
  }

  &:global(.active) {
    box-shadow: 0 0 0 1px $color-black-100;
  }
}

.hideToggle {
  display: none;
}

.knob {
  transition: 0.1s left ease-in;

  position: absolute;
  left: #{$gutter}px;
  top: #{$gutter}px;
  background-color: #fff;
  display: inline-block;
  height: #{$ballDiameter}px;
  width: #{$ballDiameter}px;
  border-radius: 50%;

  &:global(.checked) {
    left: #{$width - $gutter - $ballDiameter}px;
  }
}

.text {
  &::after {
    content: 'OFF';
  }

  &::before {
    content: 'ON';
  }
}

.label {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  user-select: none; // don't let user select toggle label

  &:global(.disabled) {
    cursor: default;
  }
}

.title {
  flex: 1;
}
