@import '../../../../style/layout';
@import '../../../../src/ui/style/colors';

.container {
  width: 100%;
  background: #ffffff;
  padding: 2.4rem 1rem 1.8rem;
  box-sizing: border-box;
}

.contentRow {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: $admin-content-width;
  margin: 0 auto;
  white-space: nowrap;

  h1 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.4;
  }
}

.tabs {
  display: flex;
  max-width: $admin-content-width;
  margin: 2rem 1rem 0 0;
  line-height: 1.475; // This is required to match up with the tab size in the Share panel.
}

.tabsInner {
  background-color: #fff;
  border: 1px solid $color-background-dark;
  border-radius: 6px;
}

a.tabLink {
  border-bottom: 0;
  border-radius: 6px;
  color: $color-black-100;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5em 1em;
  text-align: center;
  width: $admin-tab-width;

  &:hover {
    background-color: $color-background-dark;
    color: $color-black-100;
  }
}

a.active {
  background-color: $color-black-100;
  color: #fff;

  &:hover {
    background-color: $color-black-100;
    cursor: default;
    color: #fff;
  }
}

.iconContainer {
  margin-right: 0.5rem;
}
