@import '../../../style/spacing';

$loading-shimmer-width: 500px;

.tableWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.previewLayout {
  flex: 1 1 0;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: collapse;
  word-break: break-all;
}

.th {
  color: var(--secondary-text-color);
  font-size: 0.875rem;
  max-width: 80px;
  overflow: hidden;
}

.measuring {
  width: auto;
  visibility: hidden;
}

.heading {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
  padding: 0 14px 0px 14px;
  padding-right: var(--widget-side-padding);
  padding-left: var(--widget-side-padding);

  .fixedPadding & {
    padding: 0 16px 10px 16px;
  }

  .fixedPadding .th:first-of-type & {
    padding-left: 0;
  }

  .fixedPadding .th:last-of-type & {
    padding-right: 0;
  }
}

.heading_content {
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  word-break: break-all;
  // Prevent cut off of text on Windows + Chrome
  padding-top: 5px;
}

.rightAligned {
  text-align: right;
  white-space: nowrap;
}

.td {
  position: relative;
  font-size: 1rem;
  padding: 0;
  @include widgetSpacing(padding-right padding-left);
  box-sizing: border-box;
  overflow: hidden;

  .mixedCell {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
  }
}

.fixedPadding {
  .td > div {
    padding: 0 16px;
    .content {
      width: calc(100% - 32px);
    }
  }

  .td:first-of-type > div {
    padding-left: 0;
    .content {
      width: calc(100% - 16px);
    }
  }

  .td:last-of-type > div {
    padding-right: 0;
    .content {
      width: calc(100% - 16px);
    }
  }
}

.tvMode {
  .th {
    font-size: 1.125rem;
  }

  .td {
    font-size: 1.5rem;
  }
}

.tr {
  border-bottom: 1px solid var(--chart-axis-color);
}

tbody .tr:last-of-type {
  border-bottom: none;
}

.mixedCell {
  display: inline-block;
  position: relative;
  line-height: normal;
  width: 100%;

  .content {
    position: absolute;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    > div {
      /* ok honestly it looks like it's messed up but it's because right now
       * the table has many options and some have dates and it uses a component
       * that seems to be reused a lot (RelativeTime) and I don't want to break
       * other things
       */
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }

  .spacer {
    height: 0;
    overflow: hidden;
  }
}

.cell {
  line-height: normal;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
}

.imageCell {
  padding-right: 0;
  width: 40px;
  box-sizing: content-box;

  + .td {
    padding-left: 0;
  }
}

.imageWrapper {
  display: inline-block;
  padding-right: 8px;
}

.image {
  position: absolute;
  aspect-ratio: 1;
  max-height: 40px;
}

.hiddenContent {
  visibility: hidden;
}

@keyframes cellShimmer {
  0% {
    left: -$loading-shimmer-width;
  }
  100% {
    left: 800px;
  }
}

.loadingCell {
  background-color: var(--widget-loader-color);
  opacity: 0.5;

  position: absolute;
  top: 13px;
  bottom: 13px;
  @include widgetSpacing(left right);

  border-radius: 100px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: $loading-shimmer-width;
    left: -$loading-shimmer-width;
    height: 100%;

    background: linear-gradient(
      to right,
      transparent 0%,
      var(--widget-shimmer-color) 50%,
      transparent 100%
    );

    animation-duration: 1.5s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: cellShimmer;
    animation-timing-function: linear;
  }
}
