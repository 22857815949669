@import '../../../../../../../src/ui/style/typography';

.button {
  margin-top: 1rem;
}

.description {
  @include fontLight;
  margin-bottom: 1rem;
}

.header {
  @include fontMedium;
  margin-bottom: 0.3rem;
}
