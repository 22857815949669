@import "../../../style/vendor/font-awesome-variables", "../../../style/colours";

$filepicker-file-hover-color: lighten($pinstripe-grey, 8%);

.item {
  padding: 1rem 120px 1rem 40px;
  border-bottom: 1px solid transparent;
  border-top: 1px solid $pinstripe-grey;
  font-size: 14px;
  text-align: left;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 60ms background-color;

  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-folder);
    margin-left: -24px;
    position: absolute;
  }

  &:first-child {
    border-top: none;
  }

  &:hover, &:focus {
    outline: 0;
    background-color: $filepicker-file-hover-color;
  }
}
