@import '../../../../src/ui/style/_colors.scss';

.Form {
  color: $color-black-100;
  background-color: $color-grey-5;
  border-radius: 0.375rem;
  padding: 0.75rem;
  font-family: 'visuelt-regular-pro', Arial, sans-serif;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.Header {
  gap: 0.75rem;

  h3 {
    line-height: 1.625rem;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'visuelt-bold-pro', Arial, sans-serif;
  }
}

.BodyText {
  line-height: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
}

.Label {
  margin-right: 1rem;
}

.DeleteButton {
  button {
    font-size: 1.125rem;
    padding-left: 1rem;
    padding-right: 0.125rem;

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.bundleBadge {
  margin-bottom: 2px;
}

.channelSelector {
  max-width: 90%;
}
