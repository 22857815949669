@import "../../colours";

.non-graphql-dashboard {
  .clock-widget {
    padding: 0 !important;
  }
}

.clock-visualisation {
  position: absolute;
  height: 100%;
  width: 100%;

  .date {
    display: block;
    padding: 12px;
  }

  .time {
    display: block;
    padding: 20px 12px;

    @include variable(background-color, --widget-background-color, #1f1f1f);

    sub {
      top: 0;
    }
  }

  .day {
    display: block;
    margin: 0 0 2px;
  }

  &.no-title {
    margin-top: -39px;

    .time { padding: 32px 12px }
    .date { padding-top: 24px }
  }
}

.tv-mode .clock-visualisation {
  .time, .date {
    padding: 16px;
  }
}
