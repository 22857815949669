.DataSourceEducationalFooter {
  padding: 1.25rem 5rem;
  background: white;
  display: flex;
}

.icon {
  margin-right: 1.5rem;
}

.text {
  text-align: left;

  p {
    margin-bottom: 4px;
  }

  a {
    font-weight: 500;
  }
}
