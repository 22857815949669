.widgetMetaWrapper {
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 0.5rem;

  // Ensure that the widget title control is rendered above the visualisation
  position: relative;
  z-index: 1;
}

.widgetTitle {
  flex: 1 1 auto;
  line-height: 1.25;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 3px;
}
