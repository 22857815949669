@import "../../../../style/colours",
  "../../../../style/menu/variables";

$border-radius: 6px;
$margin-tags: 1px;

.wrapper {
  position: relative;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  position: relative;
  font-size: 1rem;
  padding: 1px;
  border-radius: $border-radius;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  color: $text-grey;
}

.small {
  font-size: 0.875rem;
}

.error {
  border-color: $error-red;
  box-shadow: 0 0 0 2px rgba(227, 82, 79, 0.5);

  &:focus {
    border-color: $error-red;
  }
}

.input {
  &[type=text] {
    box-sizing: border-box;
    display: inline-block;
    font-size: inherit;
    width: 100%;
    min-height: 30px;
    box-shadow: none;
    padding: 0.5rem;
    border: none;

    &:focus, &:visited {
      outline: none;
    }
  }
}

.small {
  .input {
    &[type=text] {
      padding: 0.25rem 0.5rem;
    }
  }
}

.scrollable {
  overflow: scroll;
  overflow-x: hidden;
  max-height:250px;
}

.container {
  flex: 1 0 60%;
}

.itemsContainer {
  position: absolute;
  padding: 0.25rem 0;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 16rem;
  overflow: auto;

  .containerOpen & {
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: $drop-menu-border-colour;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
}

.item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  .highlighted {
    font-weight: bold;
  }

  .userTypedValue {
    font-style: italic;

    .highlighted {
      font-weight: normal;
    }
  }
}

.itemHighlighted {
  background-color: $lightest-day-grey;
}

.noResults {
  background-color: #fff;
  color: $label-grey;
  font-style: italic;
  font-size: 0.875rem;
  cursor: default;

  padding: 0.75rem 1rem;
  border: 1px solid $drop-menu-border-colour;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  // Match position of the dropdown list
  position: absolute;
  left: 1px;
  right: 1px;
  bottom: 0.15rem;
  transform: translateY(100%);

  z-index: 1;
}

.tag {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  background-color: $secondary-grey;
  padding-left: .5rem;
  margin: $margin-tags;
  max-width: calc(100% - 2px);
  border-radius: 4px;

  > span {
    flex: 1 1 auto;
    padding: .25rem 0;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.removeTag {
  flex: 0 0 auto;
  font-size: 12px;
  border: none;
  background-color: transparent;
  color: $mid-grey;
  outline: none;
  cursor: pointer;
  padding: 2px 6px;
  margin: 2px;
  border-radius: 2px;

  transition: 0.1s ease-out background-color;

  &:hover, &:active {
    background-color: darken($color-background-dark, 10%);
  }
}
