@import '../../../../style/vendor/_font-awesome-variables.scss';
@import '../../../../style/colours';
@import '../../../../src/ui/style/colors';

.progressCard {
  position: relative;
}

.progressCardInner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &.radio {
    position: relative;
    margin: -1rem;
  }
}

.progressTitle {
  padding-left: 30px;
  position: relative;

  &::before {
    margin-left: -30px;
    position: absolute;
    @include fontAwesome;
    content: fa-content($fa-var-bullseye-arrow);
  }
}

.radio {
  height: 41px;

  & .progressTitle {
    padding: 10px 56px;

    &::before {
      content: '';
    }
  }
}

.layoutRows {
  flex-direction: column;

  .label {
    text-align: right;
    width: 100px;
  }
}

.progressForm {
  width: 100%;
  white-space: nowrap;
}

.durationColumnTitle {
  width: 100px;
}

.durationInput {
  width: 270px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font-size: inherit;
  outline: none;
}

.iconContainer {
  margin-right: 0.5rem;
}
