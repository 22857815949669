@import '../../../style/colours';
@import '../../../src/ui/style/colors';

// These colour names are not optimal but only contains within this file.
// TODO Come up with a better colour name schema
// -- Private --
$_dove-gray: #717171;
$_dusty-gray: #9b9b9b;
$_iron: #e0e2e5;
$_silver-sand: #bec0c4;
$_tundora: #4a4a4a;
$_athens-gray: #f6f6f7;

// -- Public --
$accent: $color-geckoboard-green;
$text-colour: $_tundora;
$secondary-text-colour: $_dove-gray;
$error-colour: $color-negative;

$divider-colour: $_iron;
$caret-down-colour: $_dusty-gray;

$input-border-colour: $_iron;
$input-hover-border-colour: $_silver-sand;
$input-focus-border-colour: $accent;
$input-placeholder-colour: $_silver-sand;
$input-detail-colour: $_dusty-gray;
$input-detail-active-colour: $_dove-gray;

$label-colour: $_dove-gray;

$btn-border-colour: $_iron;
$primary-btn-background-colour: $accent;
$primary-btn-hover-background-colour: lighten(
  $primary-btn-background-colour,
  5%
);
$active-btn-background-colour: $accent;
$secondary-btn-background-colour: $_iron;
$btn-hover-background-colour: $_athens-gray;
$link-colour: $accent;
$btn-disabled-text-colour: $_dusty-gray;

$action-btn-colour: $text-colour;
$action-btn-icon-colour: $_dove-gray;
$action-btn-hover-colour: $accent;

$rule-colour: $_athens-gray;
$rule-hover-colour: darken($_athens-gray, 5%);

$sortable-list-item-colour: $_athens-gray;
$sortable-list-item-hover-colour: darken($_athens-gray, 5%);

$number-picker-btn-background-colour: $_athens-gray;
$number-picker-btn-background-hover-colour: darken(
  $number-picker-btn-background-colour,
  5%
);
$number-picker-btn-background-active-colour: darken(
  $number-picker-btn-background-colour,
  7%
);
$number-picker-btn-colour: $text-colour;
$number-picker-btn-disabled-colour: $_silver-sand;

$add-to-list-btn-colour: $_silver-sand;
$add-to-list-btn-hover-colour: darken($_silver-sand, 5%);

$ghost-colour: $_athens-gray;
