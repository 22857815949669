@import "../../style/colours",
  "../../style/typography";

.widgetFooter {
  display: flex;
  align-items: center;
  padding: 12px 0 12px 6px;
  height: 13px;
}

.serviceIcon {
  flex: 1;
  padding-right: 1ex;
  width: 24px;
  overflow: hidden;
}

.logo {
  margin: 3px 0;
  border-radius: 2px;

  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
