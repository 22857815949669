@import '../../../../src/ui/style/typography';

.container {
  padding: 2rem 4rem;
  background-color: #fff;
  box-sizing: content-box;
}

.content {
  text-align: center;
}

.heading {
  @include fontMedium;

  padding: 0 0 1.5rem !important;
  margin: 0 !important;
}

.serviceIcon {
  margin-bottom: 1rem;
}
