@import '../../../../src/ui/style/colors';

.flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.overflowContent {
  overflow-y: auto;
  padding: 1rem 1rem calc(1rem + 69px) 1rem; // padding bottom takes addWidgetBar height into account
  height: 100%;
  width: 100%;
}

.addWidgetBar {
  position: fixed;
  left: 0;
  bottom: 0;
  background: $color-background-light;
  width: 480px;
  height: 69px;
  z-index: 10;
}

.errorWrapper {
  padding-top: 8px;
}

.visualizationTypeLabel {
  margin-bottom: 14%;
}
