@import '../../../../../src/ui/style/typography';

.comparisonContainer {
  display: flex;
  width: 100%;
  align-items: baseline;
  line-height: 1.2;
}

.value {
  @include fontMedium;

  flex: 0 0 auto;
  margin-right: 0.2em;
}

.label {
  overflow: hidden;
}
