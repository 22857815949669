@import '../../../style/colours';
@import '../style/colors';
@import '../style/typography';

:local(.StatusLabel) {
  @include fontMedium;

  display: table;

  text-transform: uppercase;
  color: $color-geckoboard-white;

  margin: auto;
  border-radius: 5px;
}

:local(.type-alpha) {
  background-color: $orange-beta;
}

:local(.type-beta) {
  color: $color-black-100;
  background-color: $color-geckoboard-yellow;
}

:local(.type-legacy) {
  color: $color-grey-100;
  background-color: $color-black-20;
}

:local(.type-new) {
  background-color: $color-geckoboard-green;
}

:local(.type-pro) {
  background-color: $color-black-100;
}

:local(.size-small) {
  font-size: 0.5625rem;
  padding: 2px 4px;
}

:local(.size-medium) {
  font-size: 0.75rem;
  padding: 3px 6px;
}
