@import '../../../../src/ui/style/colors';

.rightLayout {
  flex: 1;
  display: flex;
  flex-direction: column;

  background-color: $color-black-100;
  overflow: hidden;
}
