@keyframes display-transition {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes display-transition-out {
  to {
    opacity: 1;
    transform: scale(1);
  }
  from {
    opacity: 0;
    transform: scale(0);
  }
}

.pip {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
}

.animatePip {
  animation: display-transition 0.28s cubic-bezier(0.695, -0.05, 0.265, 1.55);
}

.animatePipOut {
  animation: display-transition-out 0.28s cubic-bezier(0.695, -0.05, 0.265, 1.55);
}
