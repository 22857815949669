@import '../../../style/vendor/font-awesome-variables', '../../../style/colours',
  '../../../src/ui/style/colors';

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid $color-background-dark;
  font-size: 14px;
  text-align: left;
  position: relative;

  color: $color-black-100;
  background: #ffffff;
  transition: 60ms background-color;

  cursor: pointer;

  &:first-child {
    border-top: none;
  }

  button {
    color: $color-black-100;
    appearance: none;
    border: none;
    background: none;
    font: inherit;
    line-height: inherit;
    outline: none;
    cursor: pointer;
  }

  .deleteButton {
    align-self: stretch;
    color: $color-black-60;
    width: 50px;
    visibility: hidden;
    border-left: 1px solid $color-background-dark;
    font-size: 1rem;

    &:hover,
    &:focus {
      color: $color-black-100;
    }
  }

  &:hover,
  &:focus {
    background: $color-background-light;

    .deleteButton {
      visibility: visible;
    }

    .info.canDelete {
      visibility: hidden;
    }
  }
}

.loadingSpinnerContainer {
  margin: 0 10px 0 4px;

  > div {
    width: 10.5px !important;
    height: 14px !important;
  }
}

.iconContainer {
  margin: 0 10px 0 4px;
}

// can be disabled when a file is selected and it's loading
.disabled {
  pointer-events: none;
  cursor: default;
  color: $color-black-40;

  button {
    color: $color-black-40;
    padding-right: 16px;
  }

  .info, .isDemo {
    color: $color-black-40;
  }
}

.file {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 52px;
  outline: none;
  padding: 0.5rem;
  vertical-align: bottom;
  max-width: 600px;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 70%;
}

.info {
  color: $color-black-60;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem 1rem 0 0;
  float: right;
}

.subtitle {
  composes: info;
  opacity: 0.5;
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder {
  composes: info;
  opacity: 0.5;
}

.tooltip {
  span {
    white-space: normal;
    width: 140px;
    text-align: left;
  }
}

.isDemo {
  &::after {
    display: inline-block;
    position: absolute;
    right: 1rem;
    top: 1rem;

    content: 'Demo dataset';
    color: $color-black-60;
  }
}
