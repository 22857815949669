.slide-pagination {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  padding: 0 30px;
  font-size: 12px;
  @include variable(color, --secondary-text-color, $gray_lavender);

  .inner {
    display: flex;
    align-items: center;

    height: 36px;
    overflow: hidden;
    z-index: 7;

    nav {
      font-size: 1.125rem;
      @include variable(color, --secondary-text-color, rgba(255, 255, 255, 0.55));
    }

    nav a:link {
      display: block;
      float: left;
      width: 12px;
      height: 12px;
      margin-left: 6px;
      @include variable(background-color, --neutral-color, #424242);
      overflow: hidden;
      text-indent: -100px;
      border: none;
      border-radius: 50%;

      &.activeSlide {
        @include variable(background-color, --secondary-text-color, #9f9f9f);
      }
    }
  }

  .prev, .next {
    display: inline-block;
    padding: 10px;
    margin: -10px;
    cursor: pointer;
    transform: scale(0.7, 1.2);
    visibility: hidden;

    &:hover {
      @include variable(color, --text-color, $link-colour);
    }
  }

  .prev {
    margin-right: 0;
  }

  .next {
    margin-left: 0;
  }
}

.non-graphql-dashboard {
  .widget-body:hover .slide-pagination {
    .prev, .next {
      visibility: visible;
    }
  }
}
