@import '../../../src/ui/style/typography';

.container {
  @include fontLight;

  position: relative;
  width: 100%;
  height: 100%;

  color: var(--text-color, #eee);
}
