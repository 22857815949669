@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/gecko-font/variables';

.buttons {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 0 auto 0.5rem;
}

.modalContent {
  max-width: 420px;

  ul {
    margin-left: 20px;
    margin-top: 1rem;
    margin-bottom: 2rem;

    line-height: 1.8;

    li {
      &::before {
        font-family: 'GeckoFont';
        content: $gecko-var-star;
        font-size: 0.75rem;
        margin-left: -20px;
        margin-right: 10px;
      }
    }
  }
}

.text {
  a {
    color: $color-black-100;
    text-decoration: underline;
    transition: opacity 100ms;

    &:hover {
      opacity: 0.7;
    }
  }
}

.noCreditCardRequired {
  align-self: center;
  font-size: 0.875rem;
}
