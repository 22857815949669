@import 'variables';
@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.drop-menu-picker {
  width: 0;

  .drop-menu-toggle {
    position: relative;
    z-index: 999;
    left: calc(32px + 1rem); // leave space for GB logo
    padding: 15px 20px 16px 20px;

    font-size: 1rem;
    white-space: nowrap;
  }

  > ul {
    transition: 450ms left $easeOutQuint;

    display: block;
    top: 0;
    height: auto;
    left: -400px;
    z-index: 998;
    min-width: 240px;
    max-width: 400px;
    max-height: 100vh;
    overflow: visible;

    opacity: 1;
  }

  .drop-menu-picker-list {
    width: 100%;
    max-height: 100vh;
    padding-top: 48px;
    padding-bottom: 60px;
    background-color: white;

    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $drop-menu-border-colour;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    > ul {
      width: 100%;
      overflow: auto;

      border-top: 1px solid $secondary-grey;
    }
  }

  .drop-menu-control.active {
    @include fontMedium;
    border-left: 5px solid $color-black-100;
    background-color: $color-background-dark;
  }

  &.open {
    > ul {
      left: 0;
      border: none;
      box-shadow: none;
    }

    .drop-menu-toggle {
      color: $color-black-100;
    }
  }
}

.masthead-dashboard-add {
  border-top: 1px solid $secondary-grey;
  background-color: white;
  margin-top: -1px;
  padding: 10px;
  width: calc(100% - 1px);
  height: 52px;
  position: relative;
  top: -52px;

  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  .bttn {
    width: 100%;
    line-height: inherit;
  }
}
