.container {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.vertical {
  flex-direction: row;
}
