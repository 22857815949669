@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.Pagination {
  display: flex;

  button {
    @include fontMedium;
    appearance: none;
    background: $color-background-dark;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-right: 10px;
    color: $color-black-100;
    font-size: 1rem;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.current {
      background: $color-black-100;
      color: white;
    }
  }
}
