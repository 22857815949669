@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/vendor/font-awesome-variables';

.addWidgetBar {
  @include fontMedium;

  z-index: 10;
}

.borderTop {
  border-top: 1px solid $color-background-dark;
}

.externalLink {
  margin-top: 6px;
  color: $color-black-100;

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  &::after {
    @include fontAwesome;
    content: fa-content($fa-var-external-link-alt);
    display: inline-block;
    padding-left: 4px;
    font-size: 0.9em;
    color: transparent;
    margin-left: -20px;
  }

  &:hover {
    &::after {
      color: inherit;
      margin-right: -20px;
      padding-right: 0;
      margin-left: 0;
    }
  }
}
