.loadingContainer {
  margin: auto;
}

.errorContainer {
  margin: 2rem 1rem;
}

.content {
  padding: 2rem 4rem;
}

.buttonContainer {
  text-align: right;
}

.consentActionErrorContainer {
  margin-top: 1rem;
}

.successAlertContainer {
  margin-bottom: 1rem;
}

.headerDescription {
  line-height: 1.5;
}
