@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.dashboardSettingsContainer {
  @include fontLight;

  background: #fff;
  border-radius: 4px;
  color: $color-black-100;
  padding: 30px;
  width: 500px;

  a,
  a:link {
    @include fontBold;
    text-decoration: none;
    color: $color-black-100;
    border-bottom: 1px solid $color-black-100;

    &:visited:hover,
    &:hover,
    &:focus {
      // We have to define text and border color again
      // to override global styles
      color: $color-black-100;
      border-bottom-color: $color-black-100;

      // actual change on hover
      border-bottom-width: 2px;
    }
  }
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 0.8rem;
  }
}

.errorMessage {
  margin-top: 10px;
}
