@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

$row-background-light: darken($color-background-light, 1%);
$row-background-dark: $color-background-mid;

.InvoicesTable {
  table {
    width: 100%;
    background: $row-background-light;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  th {
    @include fontMedium;

    color: $color-black-100;
    padding: 22px;
  }

  td {
    padding: 26px;
  }

  tbody tr:nth-child(odd) {
    background: $row-background-dark;
  }

  a {
    @include fontBold;
    color: $color-black-100;
    text-decoration: underline;

    &:hover {
      color: $color-black-70;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
