.non-graphql-dashboard {
  .polymorphic-widget {
    .text-visualisation {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
    }
  }
}
