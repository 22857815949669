@import '../../src/ui/style/colors';

// Font Awesome
$fa-font-path: './libs/font-awesome';

// Colours
$body-background-colour: #3a3a3a;
$new-body-background-colour: #404344;
$text-colour: #eee;
$dark-text-colour: #3e4553;
$link-colour: #fff;
$inline-link-colour: rgb(125, 186, 16);
$inline-link-darker-colour: rgb(115, 161, 51);

// new greys
$gray_athens: #dfdfdf;
$gray_lavender: #c2c9cb;
$gray_main: #8b9092;
$gray_ship: #4d4f50;
$gray_bastille: #2d2e2f;
$gray_charcoal: #454545;
$gray_jaguar: #2a2a2a;
$footer-dark-grey: #1f1f1f;

// polecat's greys
$blackest-night-grey: #24272a;
$lightest-day-grey: #f0f2f3;
$mid-morning-grey: #e3e4e5;
$murky-grey: #717779;
$pinstripe-grey: #e0e2e5;

// transparent colours
$transparent-black: rgba(0, 0, 0, 0.13);

// New blue
$blue_summer_sky: #3fb4f9;
$blue_curious: #398fc2;

$field-background-colour: $link-colour;
$field-colour: $body-background-colour;
$field-border-colour: $link-colour;
$field-border-focus-colour: darken($text-colour, 10%);
$field-border-colour: $link-colour;
$field-label-background-colour: #585858;
$field-label-focus-background-colour: lighten(#585858, 10%);
$field-help-icon-colour: $link-colour;
$field-help-icon-selected-colour: #222222;
$field-disabled-colour: rgb(57, 57, 57);
$field-disabled-background-colour: rgb(176, 176, 176);

// Form fields used on light background
$field-label-background-colour-light: #ddd;
$field-label-focus-background-colour-light: darken($text-colour, 10%);
$field-border-focus-colour-light: darken($text-colour, 10%);

$masthead-background-color: #313131;
$main-navigation-background-color: #585858;

$btn-hover-colour: #bbb;
$btn-hover-background-colour: $body-background-colour;
$btn-submit-background-colour: $color-geckoboard-green;
$btn-submit-colour: $link-colour;
$btn-submit-hover-background-colour: darken($btn-submit-background-colour, 10%);
$btn-secondary-background-colour: #c9c9c9;
$btn-secondary-colour: #505050;
$btn-secondary-hover-background-colour: darken(
  $btn-secondary-background-colour,
  20%
);
$btn-light-background-colour: #a1a1a1;
$btn-light-colour: $btn-submit-colour;
$btn-light-hover-background-colour: $body-background-colour;
$btn-dark-background-colour: #434343;
$btn-dark-colour: $link-colour;
$btn-destructive-background-colour: $color-negative;
$btn-destructive-colour: $link-colour;
$btn-destructive-hover-background-colour: darken(
  $btn-destructive-background-colour,
  20%
);

$pill-background-colour: $murky-grey;
$pill-highlight-background-colour: $gray_main;

$success-green: $color-geckoboard-green;
$error-red: $color-negative;
$warning-yellow: #e39c4f;

$tooltip-background-colour: #222;
$tooltip-colour: $text-colour;

$drop-menu-toggle-background-colour: $btn-dark-background-colour;
$drop-menu-background-colour: #f2f3f5;
$drop-menu-item-hover-background-colour: #fff;
$drop-menu-item-hover-colour: #101214;
$drop-menu-colour: #3a3a3a;
$drop-menu-divider-colour: #dee0e3;

$wizard-step-roundel-colour: #eee;
$wizard-step-active-background-colour: lighten(#5e5f60, 10%);
$wizard-step-active-border-colour: $link-colour;
$wizard-step-background-colour: #5e5f60;
$wizard-step-hover-background-colour: #585858;
$wizard-step-hover-colour: #bbb;

$popover-background-colour: $lightest-day-grey;
$popover-header-background-colour: #fff;
$popover-footer-background-colour: #e0e0e0;
