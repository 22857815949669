@import "../../../../style/colours";

.container {
  background:
          url(https://d2s28ygc2k7998.cloudfront.net/admin-empty-bg-left.png) top left/317px repeat-y,
          url(https://d2s28ygc2k7998.cloudfront.net/admin-empty-bg-right.png) top right/241px repeat-y,
          linear-gradient(to bottom, rgba($gray-alabaster,0) 50%, rgba($gray-alabaster,1) 100%),
          white;
  background-size: 317px 52px, 214px 52px, 100%;
  border-radius: 3px;
  padding: 1rem 2rem 3rem;
}
