@import '../style/colors';

:local(.infoIcon) {
  display: flex;
  align-items: center;
  height: 100%;
  width: 1.75rem;
  margin-left: 0.625rem;
  font-size: 1rem;
  color: $color-black-100;
}

:local(.infoIconStroyWrapper) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
