@import '../../../../../src/ui/style/typography';
@import '../../../../../style/colours';

.mobileNumberContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0;
}

.primaryNumberLayout {
  flex: 0 0 auto;
}

.primaryNumber {
  @include fontMedium;

  display: inline-block;
}

.labelLayout {
  display: block;
  flex: 0 0 auto;
  width: 100%;
  line-height: normal;
}

.noDataLabel {
  color: var(--text-color);
  opacity: 0.5;
}
