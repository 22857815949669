
.widgetLoader {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;
}

.test {
  height: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.message {
  margin: 0.5rem 0 0 0;
  text-align: center;
  line-height: 1.2rem;
  font-size: 14px;
}
