.primaryNumberWrapper {
  white-space: nowrap;
}

.prefix, .suffix, .abbreviation {
  font-size: 0.6em;
}

.negative, .prefix {
  margin-right: 0.05em;
}

.suffix, .abbreviation {
  margin-left: 0.05em;
}

.abbreviation {
  margin-right: 0.1em;
}

.timeWindow {
  font-variant-numeric: tabular-nums;
}