
@import "../../style/colours";
@import "../../style/vendor/font-awesome-variables";
@import "../../style/typography";

$widget_bg: #2a2a2a;
$footer-height: 36px;

.resizing .size {
  @include variable(background-color, --widget-background-color, $widget_bg);
  position: relative;
  overflow: hidden;
  z-index: 2;
  opacity: 0.8;
  overflow: hidden;
}

.resizeHandle {
  position: absolute;
  bottom: 0;
  right: 0;

  transition: opacity 0.1s ease;
  opacity: 0;
  z-index: 1;

  &.disabled {
    display: none;
  }
}

:global(.draggable .widget):not(.focused) .widgetInner,
.draggable:not(.focused) .widgetInner {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.widget:hover .resizeHandle {
  opacity: 0.3;
}

.widget .resizeHandle:hover,
.resizing .resizeHandle {
  opacity: 0.5;
}

.widgetInner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ghostPreview {
  pointer-events: none;
  transition-duration: 0.1s;
  transition-property: height, width;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;

  @include variable(mix-blend-mode, --widget-drag-blend-mode, 'normal');
  @include variable(background-color, --widget-drag-ghost-color, 'rgba(113, 119, 121, 0.5)');
}

:global(.menu-open) .widget :global(.settings-button) {
  display: block;
}

// This applies the warning background for both the dashboard, and storybook
// as unfortunately they don't share class names / markup
.widgetInner,
.widget :global(.widget-inner) {
  transition: background-color 0.1s;
  @include variable(background-color, --widget-background-color, $widget_bg);
  background-image: var(--widget-background-image, none);

  overflow: hidden;
}

.widget {
  @include fontLegacy;
  &.transparent {
    --widget-background-color: transparent;

    :global(header.has-title),
    &:focus-within header,
    &:hover header,
    &.resizing header {
      background-color: var(--widget-active-background-color);
    }
  }

  &.focused {
    z-index: 10000000 !important;
    cursor: default !important;
  }

  :global(.settings-button) {
    display: none;
  }

  :global(.widget-footer) {
    transition: opacity 110ms ease-in;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 0;
    width: $footer-height;
    height: $footer-height;
    z-index: 2;
  }

  &.resizing {
    box-shadow: none !important;
  }

  &:hover,
  &.resizing,
  &.focused {
    :global(.widget-footer) {
      opacity: 1;
    }

    :global(.settings-button) {
      display: block;
    }

    :global(.slide-ribbon) {
      transition: opacity 100ms;
      opacity: 0.2;
    }
  }

  &.editable {
    &:hover,
    :global(.selected) &,
    :global(.isBeingDragged) &,
    &.resizing,
    &.focused {
      --widget-background-color: var(--widget-active-background-color);
    }
  }

  &:global(.loading) {
    header :global(.time-since) {
      transform: translateX(100%);
    }

    legend::after {
      display: none;
    }
  }

  &:global(.warning) {
    header :global(.time-since) {
      transition: .2s .4s opacity;
      opacity: 1;
      visibility: visible;
    }
  }
}

:global(.warningThresholdReached) {
  --text-color: #{$text-colour};
  --title-color: #{$text-colour};
  --widget-background-image: linear-gradient(to top left, #ff575c, #ca2c44);
  --sparkline-stroke: #4f2828;
  --sparkline-shadow: drop-shadow(0 2px 0px rgba(255, 255, 255, 0.6));
  --comparison-color: #4f2828;
  --input-placeholder-color: #{$text-colour};
  --goal-display: none;
}

:global(.positiveThresholdReached) {
  --text-color: var(--positive-color, #{$visualisation-green});
  --title-color: var(--positive-color, #{$visualisation-green});
  --sparkline-stroke: var(--positive-color, #{$visualisation-green});
  --sparkline-shadow: drop-shadow( 0 2px 0px rgba(255, 255, 255, 0.6));
  --widget-bottom-border: 5px solid var(--positive-color, #{$visualisation-green});
  --goal-display: none;
}

:global(.non-graphql-dashboard) {
  :global(.tv-mode) {
    :global(.visualisation-positioner) {
      top: 52px;
    }
  }
}

.integrationWarning {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: yellow;
  font-size: 1.75rem;
}
