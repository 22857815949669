@import "slide-pagination";
@import "../../colours";

.list-visualisation {
  position: absolute;
  padding: 0;
  top: 3px;
  left: 16px;
  right: 16px;
  bottom: 32px;

  .w-list {
    height: 100%;
    width: 100%;
  }

  .w-list-container {
    height: calc(100% - 20px);
    width: 100%;
    overflow: hidden;
  }

  .widget-body {
    padding: 0;
    height: 100%;
    overflow: hidden;

    line-height: 1.3;
    font-size: 18px;
  }

  .title, .description {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: inherit;
    padding-bottom: 4px;
    font-weight: 500;
    overflow: hidden;
    font-size: 18px;
  }

  .w-label {
    display: none;
  }

  .description {
    color: var(--secondary-text-color, #828282);
    padding-bottom: 10px;
    font-size: 11px;
  }

  &.is-large {
    // Only display labels when widget is large enough
    .w-label {
      display: inline-block;

      padding: 4px;
      text-transform: uppercase;
      font-size: .85em;
      position: relative;
      top: -2px;

      text-shadow: none;
    }

    .description {
      font-size: 18px;
    }
  }

  .slide-pagination {
    width: auto;
    left: -16px;
    right: -16px;
    bottom: -32px;
    height: 36px;
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-overflow: ellipsis;
    line-height: 1.3;
    opacity: 0;
  }

  .active {
    opacity: 1;
    z-index: 2;
  }
}
