.feedbackButton {
  button {
    // Override UI Kit <Button /> padding
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
}
