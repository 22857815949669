@import '../../../../src/ui/style/colors';
@import '../../../../src/ui/style/typography';

.dropdownSection {
  margin-bottom: 1rem;
}

.deletedChannelAlert {
  margin-bottom: 1rem;
}

.dropdownContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
}

.label {
  @include fontBold;
  color: $color-black-100;
  width: 15%;
}

.dropdown {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.SlackPrivateChannelsDropdownHeading {
  padding-bottom: 6px;
  border-bottom: solid 1px $color-grey-10;

  button {
    padding: 0px;
  }
}

.slackPrivateChannelsHeadingIcon {
  margin-left: 8px;
}

.postTimeSection {
  display: flex;
  align-items: center;
  width: 85%;
}

.postTimeDropdown {
  width: 40%;
}

.postTimeTimezone {
  margin-left: 1rem;
  width: 60%;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.dayPicker {
  margin-top: 2px;
  margin-bottom: 2px;
}

.error {
  margin-top: 1rem;
}
