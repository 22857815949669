.container {
  overflow: hidden;
  inset: 0;
  position: absolute;
}

.wrapper {
  display: flex;
  flex-direction: column;

  --expanded-bar-height: var(--bar-height);
  --expanded-bar-radius: var(--bar-radius);
}

.scaling0 {
}

.scaling1 {
  --bar-height: 24px;
  --label-line-height: 28px;
}

.scaling2 {
  --bar-height: 24px;
  --completion-margin: 16px;
  --label-line-height: 28px;
  --row-margin: 8px;
}

.scaling3 {
  --bar-height: 16px;
  --bar-label-display: none;
  --completion-margin: 16px;
  --expanded-bar-height: 24px;
  --label-line-height: 28px;
  --row-margin: 8px;
  --stage-label-display: none;
  --stage-value-display: none;
}

.scaling4 {
  --bar-height: 8px;
  --bar-label-display: none;
  --bar-radius: 2px;
  --completion-margin: 16px;
  --expanded-bar-height: 24px;
  --expanded-bar-radius: 4px;
  --label-line-height: 28px;
  --row-margin: 4px;
  --stage-label-display: none;
  --stage-value-display: none;
}

.grid {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: 24px;
}

.stageContainer {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 2;
}

.stageContainer + .stageContainer {
  margin-top: var(--row-margin, 16px);
}

.stageExpanded {
  --bar-height: var(--expanded-bar-height);
  --bar-radius: var(--expanded-bar-radius);
  --stage-label-display: block;
  --bar-label-display: flex;
  --stage-value-display: block;
}

.stageLabel {
  grid-column: 1;
  font-size: 24px;
  line-height: var(--label-line-height, 32px);
  display: var(--stage-label-display, block);
}

.stageBars {
  position: relative;
  grid-column: 1;
}

.stageValue {
  grid-column: 2;
  font-size: 24px;
  line-height: var(--bar-height, 32px);
  justify-self: flex-end;
  font-variant-numeric: tabular-nums;
  display: var(--stage-value-display, block);
}

.barValue {
  height: var(--bar-height, 32px);
  border-radius: var(--bar-radius, 4px);
  background-color: var(--primary-visualisation-color);
  line-height: 24px;
  width: var(--bar-width);
  transition: width 250ms;
}

.barDelta {
  height: var(--bar-height, 32px);
  border-radius: var(--bar-radius, 4px);
  background-color: var(--chart-axis-color);
  position: absolute;
  top: 0;
  z-index: -1;
  width: var(--shadow-width);
  transition: width 250ms;
}

.barLabelWrapper {
  color: var(--container-background-color);
  font-family: 'visuelt-bold-pro';
  font-size: 20px;

  position: absolute;
  top: 2px;
  bottom: 0;
  display: var(--bar-label-display, flex);
  align-items: center;
  justify-content: flex-start;
  --label-transform: translateX(0%);
}

.barLabelWithTransitions {
  transition: all 250ms;

  .barLabel {
    transition: transform 250ms;
  }
}

.barLabel {
  padding-left: 8px;
  padding-right: 8px;
  transform: var(--label-transform);
}

.barLabelInBar {
  left: var(--bar-width);
  right: calc(100% - var(--bar-width));
  --label-transform: translateX(-100%);
}

.barLabelInShadow {
  left: var(--bar-width);
  right: calc(100% - var(--shadow-width));
  color: var(--primary-visualisation-color);
}

.barLabelOutside {
  left: var(--shadow-width);
  right: 0;
  color: var(--primary-visualisation-color);
}

.completion {
  margin-top: var(--completion-margin, 24px);
  font-size: 24px;
  line-height: 32px;
  color: var(--secondary-text-color);

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  gap: 8px;
}

.completionPill {
  background-color: var(--text-color);
  color: var(--container-background-color);
  font-family: 'visuelt-medium-pro';
  border-radius: 4px;
  height: 32px;

  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pillLabel {
  margin-top: 2px; // Fix awkward baseline of font
  font-variant-numeric: tabular-nums;
}

.noTabular {
  font-variant-numeric: initial;
}

.showResizeMessage {
  position: relative;
  visibility: hidden;
}
