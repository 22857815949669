@import "../../../style/typography";

.picker {
  @include typography;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;

  &:global(.stretch) {
    flex: 1;
  }
}

.search {
  margin: 1rem 0;
}

.error {
  margin: 1rem 0;
}
