@import '../../shared/link';
@import '../variables';
@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

/*
A horizontal form field and label

.form-field.invalid                 - Invalid state for the field
.form-field.form-button-type        - An inline form button
.form-field.form-radio-type         - A radio field with several options
.form-field.form-field-help-active  - with help tooltip visible
*/
.form-field {
  clear: both;

  &:after {
    content: '';
    display: table;
  }

  position: relative;
  max-width: 960px;
  line-height: 1.8;
  margin-bottom: 10px;

  > label {
    @include fontMedium;

    position: relative;
    display: block;
    min-height: 40px;
    padding: 6px 12px 5px;

    border-radius: 4px 4px 0 0;
    background-color: $color-background-dark;

    cursor: pointer;
  }

  > input[type='text'],
  > input[type='password'],
  textarea,
  .select-field .chosen-single,
  .select-field .chosen-choices {
    position: relative;
    width: 100%;
    border-radius: 0 0 4px 4px;
    border: 1px solid #dadada;
    box-sizing: border-box;

    &:focus {
      box-shadow: none;
      border-color: $field-border-focus-colour;

      outline: none;
    }
  }

  .select-field {
    width: 100%;

    .chosen-choices {
      min-height: 40px;
      padding-left: 10px;
    }

    .chosen-container-single .chosen-single {
      padding-left: 15px;
    }

    .search-choice {
      padding: 9px 20px 9px 5px;
    }

    .search-choice-close {
      top: 9px;
    }

    .search-field .default {
      padding: 9px 4px;
    }
  }

  .chosen-container {
    vertical-align: top;
  }

  .field-required {
    position: absolute;
    right: 10px;
    z-index: 3;

    font-size: 30px;
    color: $field-label-background-colour;
  }

  &.form-select-type {
    .field-required {
      right: 30px;
    }
  }

  &.form-hidden-type {
    display: none;
  }

  .field-help {
    position: absolute;
    z-index: 5;
    top: 5px;
    right: 25px;
    margin-left: -25px;

    .help-text-close-button {
      display: none;
    }
  }

  .field-info {
    float: left;
    margin-top: 8px;

    a {
      @include link;
    }
  }

  &.invalid {
    > input,
    textarea,
    .select-field .chosen-single,
    .select-field .chosen-choices {
      border-color: $color-negative-on-dark;
    }

    > label {
      background-color: $color-negative-on-dark;
      color: #fff;
    }

    .field-required {
      color: $color-negative-on-dark;
    }
  }

  .selection-group {
    float: left;
    padding: 0 3px 0 11px;
    width: 100%;

    background-color: $field-background-colour;
    border-radius: 0 0 4px 4px;
    border: 1px solid #dadada;
    box-sizing: border-box;

    > input {
      width: 26px;
      height: 26px;
      margin: 7px 0;
    }

    label {
      display: inline-block;
      vertical-align: top;
      margin-top: 6px;
      margin-right: 10px;
      padding-right: 15px;

      color: $field-colour;
      cursor: pointer;
    }
  }

  &.form-timezone-type,
  &.form-map-type {
    margin-bottom: 0;

    .select-field {
      margin-bottom: 10px;
    }

    .field-label {
      display: none;
    }
  }

  &.memorable-info {
    margin-bottom: 30px;
  }

  .help-text {
    transition: opacity 0.2s;

    left: -999em;
    opacity: 0;
  }

  &.form-field-help-active {
    .help-text {
      left: -335px;
      opacity: 1;
    }
  }

  &.form-field-nested,
  &.form-map-type > label:nth-of-type(3),
  &.form-timezone-type > label:nth-of-type(3) {
    &:before {
      position: absolute;
      top: -60px;
      height: 100px;
      width: 2px;

      color: $color-background-dark;
      background-color: $color-background-dark;

      font-size: 8px;
      font-weight: 900;

      @include fontAwesome;
      content: fa-content($fa-var-circle);
      vertical-align: bottom;
      text-indent: -3px;
      line-height: 200px;
    }

    &.focus::before,
    &.focus label::before {
      background-color: $color-background-dark;
      color: $color-background-dark;
    }
  }

  &.form-field-nested {
    padding-left: 30px;

    &::before {
      left: 12px;
    }
  }

  &.form-map-type,
  &.form-timezone-type {
    > label:nth-of-type(3) {
      margin-left: 30px;

      &::before {
        left: -18px;
      }
    }

    .double-select,
    .timezone-location {
      padding-left: 30px;
    }
  }

  &.form-field-copyable {
    input[type='text'] {
      padding-right: 60px;
    }

    .field-copy-value {
      display: inline-block;
      position: absolute;
      right: 0;
      height: 40px;

      border-radius: 0 4px 4px 0;
      outline: none;

      &::before {
        @include fontAwesome;
        content: fa-content($fa-var-clipboard);
      }

      &.copied {
        background-color: $btn-submit-background-colour;

        &::before {
          @include fontAwesome;
          content: fa-content($fa-var-check);
          color: $btn-submit-colour;
        }
      }
    }
  }

  .duration-input-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }

  .duration-input {
    display: flex;
    margin-left: 0.5rem;
  }

  .duration-input-field {
    width: 80%;
    flex: 1 0 0;
    height: 40px;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
    outline: none;
    font-size: 1rem;

    transition: border-color 100ms;

    &:focus {
      border-color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .duration-input-suffix {
    border-radius: 0 3px 3px 0;
    width: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    background: $mid-grey;
    padding: 0.4rem 0 0 0;
    box-sizing: border-box;
    text-align: center;
  }

  @include mq($from: tablet) {
    &::after {
      content: '';
      clear: both;
      display: table;
    }

    > label {
      float: left;
      width: 40%;

      border-radius: 4px 0 0 4px;
    }

    > input[type='text'],
    > input[type='password'],
    .selection-group,
    .select-field {
      float: left;
      width: 60%;
    }

    > input[type='text'],
    > input[type='password'],
    .selection-group,
    .select-field .chosen-single,
    .select-field .chosen-choices {
      border-radius: 0 4px 4px 0;
    }

    .chosen-with-drop .chosen-single,
    .chosen-with-drop .chosen-choices {
      border-radius: 0 4px 0 0;
    }

    &.form-field-help-active .help-text {
      left: 0;
    }

    &.form-textarea-type {
      label {
        float: none;
        width: auto;

        border-radius: 4px 4px 0 0;
      }
    }

    &.form-field-nested {
      max-width: 990px;
      margin-right: -30px;

      &::before {
        top: -10px;
        height: 30px;
        line-height: 60px;
      }

      & + &.form-field-nested::before {
        top: -30px;
        height: 50px;
        line-height: 100px;
      }

      > label {
        padding-right: 60px;
      }

      > input[type='text'],
      > input[type='password'],
      textarea,
      .chosen-container {
        margin-left: -30px;
      }

      > input[type='text'],
      > input[type='password'],
      textarea {
        z-index: 1;
      }

      .field-required {
        right: 60px;
      }

      .field-help {
        margin-right: 30px;
      }

      .selection-group {
        position: relative;
        margin-left: -30px;
        z-index: 1;
      }
    }

    &.form-timezone-type,
    &.form-map-type {
      margin-bottom: 0;

      .select-field,
      .multi-select-label {
        margin-bottom: 10px;
      }

      > label:nth-of-type(3) {
        margin-left: 30px;
        margin-right: -30px;

        &:before {
          top: -30px;
          left: -18px;
          height: 50px;

          line-height: 100px;
        }
      }

      .double-select,
      .timezone-location {
        padding-left: 0;
      }
    }

    &.form-field-copyable.field-copy-ready {
      .field-copy-value {
        top: 0;

        border-radius: 0 4px 4px 0;
      }
    }

    .duration-input-container {
      width: 60%;
    }
  }
}
