@import '../../../style/spacing';
@import '../../../style/widget';
@import '../../ui/style/typography';
@import '../../ui/style/colors';
@import '../../../style/colours';
@import '../../../style/widget';

.previewLayout {
  flex-direction: column;
  flex: 1 0 auto;
  padding: 0;
  @include widgetSpacing(padding-top padding-bottom);
}

.textWrapper,
.variableFontSize {
  display: flex;
  flex: 1 0 auto;
  overflow: visible;
  position: relative;
}

.slider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.slidePreview {
  flex: 1 0 auto;
  overflow: hidden;
  @include widgetSpacing(padding);
  line-height: 1.2;

  // show maximum 4 lines (only works in webkit)
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.slideDashboard {
  flex: 1 0 0;
  overflow: hidden;
  @include widgetSpacing(padding);

  font-size: 1.5rem;
  line-height: 2.125rem;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.dotPreview {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.paginationPreview {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 20px;
}

.paginationDashboard {
  display: flex;
  align-items: center;

  height: 36px;
  overflow: hidden;
  z-index: 10;
}

// Ergh, the icon must be rendered at the top of the widget
.icon {
  top: -$widget-header-height;
}

:global(.tv-mode) .icon {
  top: -$widget-header-height-large;
}

:global(.widget):hover .icon {
  opacity: 0.2;
}

.largeHeading,
.smallHeading {
  .slider {
    position: absolute;
    top: -$widget-header-height;
    bottom: 0;
    justify-content: center;

    :global(.tv-mode) & {
      top: -$widget-header-height-large;
    }
  }

  .slideDashboard {
    z-index: 1;
    min-height: 60px;
    font-size: 42px;
    line-height: 60px;
    flex: 0;
    white-space: nowrap;
  }

  .paginationDashboard,
  .icon {
    display: none;
  }
}

.smallHeading {
  .slideDashboard {
    min-height: 40px;
    font-size: 28px;
    line-height: 40px;
  }
}

.markdown {
  @include fontLight;
  line-height: 1.25;
  word-wrap: break-word;

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include fontBold;
    margin-bottom: 0.3rem;
    padding: 0;
    line-height: 1.25;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  h6 {
    font-size: 0.85rem;
  }

  // Links
  a {
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    @include variable(color, --primary-visualisation-color, $color-grey-60);
  }

  // paragraph
  p {
    font-size: 1.5rem;
    margin-top: 0;
    line-height: 1.25;
  }

  // Emphasis
  em {
    font-style: italic;
  }

  strong {
    @include fontBold;
  }

  // Blockquote
  blockquote {
    padding: 0 1rem;
    color: $color-grey-60;
    border-left: 0.25em solid $color-grey-40;

    p {
      font-size: 1rem;
      color: $color-grey-40;
    }

    cite,
    em {
      font-size: 0.875rem;
      color: $color-grey-20;
    }

    cite:before {
      content: '\2014 \00A0';
    }
  }

  // Code blocks
  pre {
    border-radius: 6px;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    white-space: pre;
    word-wrap: normal;
    line-height: 1;

    code {
      display: inline;
      overflow: visible;
    }
  }

  // Inline code
  code {
    font-size: 1rem;
    font-family: Consolas, Monaco, Andale Mono, monospace;
  }

  // Images
  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin: 1rem 0;
  }

  // Horizontal Rule
  hr {
    height: 0.25rem;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: $color-grey-60;
  }

  // We don't want to apply this style to looping dots.
  li::not(.paginationDashboard) {
    line-height: 1.6;
    padding: 5px 0;
  }

  // Unordered list items
  ul:not(.paginationDashboard) {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 1rem;

    ul {
      list-style-type: circle;
      margin-left: 1rem;

      ul {
        list-style-type: square;
      }
    }
  }

  // Ordered list itmes
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    margin-left: 1rem;

    ol {
      list-style-type: lower-alpha;
      margin-left: 1rem;

      ol {
        list-style-type: lower-roman;
      }
    }
  }

  // Task lists
  ul:has(input[type='checkbox']) {
    margin-left: 0;
  }

  li:has(input[type='checkbox']) {
    list-style-type: none;
  }

  // Tables
  table {
    display: block;
    width: max-content;
    max-width: 100%;

    tr {
      border-top: 1px solid $color-grey-20;
    }

    th,
    td {
      padding: 6px 13px;
      border: 1px solid $color-grey-20;
      text-align: center;
    }
  }
}
