.non-graphql-dashboard {
  .image-widget.widget-body {
    padding: 0px;

    &.no-title {
      top: 0;
    }
  }
}

.image-visualisation {
  height: 100%;
  text-align: center;

  img {
    pointer-events: none;
    max-width: 100%;
  }
}

.is-mobile .image-visualisation {
  & > div {
    height: 100%;
  }

  img {
    max-height: 100%;
  }
}
