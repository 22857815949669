.container {
  select {
    width: 10rem;
  }
}

.permissionDropMenuList {
  top: 100%;
  margin-left: -26px;
}
