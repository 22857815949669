@import '../compact-config-colors';
@import '../../../../src/ui/style/colors';

.quietDismissable {
  width: 100%;
  display: flex;
}

.childPlaceholder {
  flex: 1;
}

.closeButton {
  display: block;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: $color-black-70;
  cursor: pointer;
  padding: 0px 9px 9px 16px;
  margin: 0;

  &:hover,
  &:focus {
    color: $color-black-100;
  }
}
