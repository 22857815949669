@keyframes loading-bar {
  0% {
    width: 0%;
    left: 0;
  }
  25%, 70% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

.non-graphql-dashboard {
  .loadable {
    &::after {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
      display: block;
      width: 0%;
      height: 3px;

      background-color: $success-green;

      content: '';
    }

    &.loading::after {
      transform: translateZ(0);
      animation: loading-bar 4s ease-in-out infinite;
    }
  }
}
