@import '../../../../style/shared/animations', '../../../../src/ui/style/colors',
  '../../../../style/colours';

.radio {
  width: 41px;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0 0 6px;
  background-color: $color-grey-40;
  pointer-events: none;
  z-index: 2;

  @include animateProperty(all);

  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background-color: #ffffff;
    vertical-align: -7px;

    @include animateProperty(all);
  }
  // hide default radio button
  input[type='radio'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.checked {
  background-color: $color-black-100;

  &:before {
    background-color: transparent;
  }
}

.disabled {
  &:before {
    border-color: $color-grey-20;
    background-color: $color-grey-20;
  }
}

.extended {
  width: 40%;
  z-index: 1;
}
