@import "../../spacing";

.rag-visualisation {
  height: 100%;
  box-sizing: border-box;
  padding: 16px 0;
  @include widgetSpacing(padding-left padding-right);


  table {
    width: 100%;
    height: 100%;

    tr {
      border-bottom: solid 1px #363636;
      @include variable(border-color, --neutral-color, #363636);

      &:last-child { border-bottom: none }
      &:first-child { td { padding-top: 0 } }
    }

    td {
      padding: 8px 0;

      &.t-secondary {
        font-size: 12px;
        padding-left: 12px;
      }
    }

    &.isLarge {
      td {
        padding: 20px 0;

        &.t-secondary {
          font-size: 24px;
          padding-left: 20px;
        }
      }
    }
  }
}
