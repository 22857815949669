@import "../colours";

.dashboard-sized-el {
  margin: 0 auto;
}

// Multi widget selection indicator
.dragger {
  position: absolute;
  z-index: 1000;
  border: 1px solid $gray_charcoal;
  @include variable(background-color, --dragger-color, rgba(255,255,255,0.2));
}
