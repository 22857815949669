.config-form-wrapper {
  form {
    .form-field {
      &.form-radio-input {
        label {
          border-radius: 5px 0px 0px 5px;

          &.focus {
            background-color: transparent;
          }
        }

        input[type=text] {
          display: none;
        }

        &.checked {
          input[type=text] {
            display: block;
          }

          label {
            width: 40%;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
