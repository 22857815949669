@import '../../shared/link';
@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.field-help {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;

  .help-text {
    transform: translate(20px, -25%);
    @include fontLight;

    position: absolute;
    min-width: 300px;
    z-index: 2;
    top: 5px;
    padding: 15px;

    background-color: $color-black-100;
    color: #ffffff;
    border-radius: 4px;

    line-height: 1.2;

    a {
      @include fontBold;

      color: #ffffff;
      text-decoration: underline;
    }
  }

  .help-icon {
    position: absolute;
    display: inline-block;
    width: 1em;

    border: none;
    color: $color-black-100;

    text-indent: -999em;
    overflow: hidden;

    cursor: help;
    outline: none;

    &::after {
      position: absolute;
      left: 0;

      @include fontAwesome;
      content: fa-content($fa-var-question-circle);
      text-indent: 0;
    }
  }
}

.form-field-help-active {
  .help-icon {
    color: $field-help-icon-selected-colour;
  }
}
