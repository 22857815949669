@import "../../../style/colours";

.breadcrumb {
  flex: none;

  text-align: left;
  font-size: 0.875rem;
  margin: 1rem 0;
  padding-left: 0.25rem;
}

.path {
  &::before {
    content: '/';
    padding: 0 0.5rem;
  }

  &:first-of-type::before {
    content: '';
    padding: 0;
  }
}

.clickablePath {
  composes: path;
  cursor: pointer;

  &:hover {
    color: $gray-main;
  }
}
