@import '../../../style/colours';

.buttonBarContainer {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid $color-grey-20;
  padding: 0.75rem 1.125rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  div:last-child {
    max-width: 300px;
  }
}

.spaceBetween {
  justify-content: space-between;
}
