@import '../../../../ui/style/colors';
@import '../../../../ui/style/typography';

.container {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
}

.iconButton {
  align-content: center;

  button {
    padding: 0;
    height: 24px;
    margin-left: 4px;

    &:focus {
      box-shadow: none;
    }
  }
}

.secondaryText {
  @include fontLight;

  color: $color-black-70;
  font-size: 12.8px;
  height: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  padding: 0px;
}
