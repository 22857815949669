@import '../../../ui/style/_colors';
@import '../../../ui/style/_typography';

.warningBanner {
  background-color: $color-geckoboard-yellow;
  color: $color-black-100;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 65px;
  padding-top: 4px;
  box-sizing: border-box;
}

.warningBanner a {
  @include fontBold;
  color: $color-black-100;
  text-decoration: underline;
  cursor: pointer;
}
