@import "../../../../style/vendor/_font-awesome-variables.scss";

.copieable {
  position: relative;
  margin-left: 24px;
  margin-top: 16px;
  display: inline-block;
  border-bottom: 1px solid;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::before {
    left: -24px;
    position: absolute;
    @include fontAwesome;
    content: fa-content($fa-var-clipboard);
    border-bottom: none;

  }

  &.copied::before {
    @include fontAwesome;
    content: fa-content($fa-var-check);
  }
}
