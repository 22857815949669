@import '../../src/ui/style/colors';

// /*
// A horizontal form field and label

// .form-item.invalid                 - Invalid state for the field
// .form-item.form-item-help-active  - with help tooltip visible
// */
.form-item {
  @import 'form-item-help';

  clear: both;

  &:after {
    content: '';
    display: table;
  }

  position: relative;
  max-width: 960px;
  line-height: 1.8;
  margin-bottom: 10px;

  > label {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 40px;
    padding: 6px 20px 5px 12px;

    border-radius: 4px 4px 0 0;
    background-color: $color-background-dark;

    cursor: pointer;

    &.focus {
      background-color: $field-label-focus-background-colour;
    }
  }

  .form-item-input {
    position: relative;
    width: 100%;
    padding: 9px 25px 9px 15px;
    border-radius: 0 0 4px 4px;
    border: 1px solid $field-border-colour;
    font-size: 1rem;
    font-family: inherit;

    &:focus {
      box-shadow: none;
      border-color: $field-border-focus-colour;

      outline: none;
    }
  }

  .form-item-required {
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 3;

    font-size: 30px;
    line-height: 50px;
    color: $body-background;
  }

  .form-item-help {
    position: absolute;
    z-index: 5;
    top: 5px;
    right: 40px;
    margin-left: -25px;
  }

  &.invalid {
    .form-item-input {
      border-color: $color-negative-on-dark;
    }

    > label {
      background-color: $color-negative-on-dark;
      color: #fff;
    }

    .field-required {
      color: $color-negative-on-dark;
    }
  }

  .help-text {
    position: absolute;
    transition: opacity 0.2s;

    left: -999em;
    opacity: 0;
  }

  .form-item-help-active {
    .help-text {
      left: -335px;
      opacity: 1;
    }
  }

  @include mq($from: tablet) {
    &::after {
      content: '';
      clear: both;
      display: table;
    }

    > label {
      float: left;
      width: 40%;

      border-radius: 4px 0 0 4px;
    }

    .form-item-input {
      float: left;
      width: 60%;
      border-radius: 0 4px 4px 0;
    }

    .form-item-help {
      position: absolute;
      left: 40%;
      right: auto;
    }

    .form-item-help-active .help-text {
      left: 0;
    }
  }
}
