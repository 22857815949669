@import "../../../style/colours";

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sharingMode {
  .hiddenColumnsFade,
  .tooltip {
    display: none;
  }
}

.hiddenColumnsFade {
  opacity: 0;
  transition: opacity 110ms ease-in;
  position: absolute;
  pointer-events: none;
}

.hiddenColumnsFade {
  width: 100px;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, transparent, var(--widget-background-color));
}

:global(.widget):hover {
  .hiddenRowsFade,
  .hiddenColumnsFade,
  .tooltip {
    opacity: 1;
  }
}

.tooltip {
  opacity: 0;
  transition: opacity 110ms ease-in;
  position: absolute;
  bottom: 6px;
  right: 32px;
  background-color: $tooltip-background-colour;
  color: $tooltip-colour;
  font-size: 16px;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  line-height: normal;
  pointer-events: none;

  &:after {
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: -5px;
    width: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid $tooltip-background-colour;
    border-bottom: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }
}
