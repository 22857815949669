@import "../../../../style/vendor/font-awesome-variables";
@import "../compact-config-colors";

.withCaret {
  position: relative;

  &::after {
    position: absolute;
    right: 1px;
    bottom: 4px;
    top: 4px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 8px;
    background: #FFFFFF;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    color: $input-detail-colour;
    font-size: 12px;
  }
}

.dropdownList {
  border: none !important;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3) !important;
}
