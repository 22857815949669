@import '../../../../../src/ui/style/colors';
@import '../../../../../src/ui/style/typography';

.ActionCard {
  border: solid 1px $color-grey-20;
  border-radius: 6px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
}

.EditActionCard {
  border-left: solid 6px #272956;
}

.ActionCard--disabled {
  opacity: 0.5;
}

.header {
  display: flex;

  button {
    appearance: none;
    background: none;
    border: none;
    font-size: inherit;
    cursor: pointer;
    margin: 0;
  }
}

.actionCardButton {
  @include fontLight;

  flex: 1;
  line-height: 1.2;
  display: inline-block;
  text-align: left;
  display: flex;
  align-items: center;
  color: $color-black-100;
  padding: 0.875rem 1rem;
  transition: background 0.2s;
  overflow: hidden;

  &:is(button):hover {
    background: $color-background-mid;
  }
}

.editCardButton {
  padding: 0.875rem 1rem 0.875rem 0.625rem;
}

.deleteButton {
  padding: 1rem;
  border-left: solid 1px $color-grey-20 !important;

  &:hover {
    background: $color-background-mid;
  }
}

.deleteButton--disabled {
  opacity: 0.5;
}

.mainIcon {
  margin-right: 0.5rem;
  position: relative;
  top: 1px;
  line-height: 1;
}

.title {
  flex: 1;
  padding-right: 1rem;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  border-top: solid 1px $color-grey-20;
  padding: 1rem;
}

.wrapper {
  overflow: hidden;
}

.actionButtonsContainer {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: flex-end;
}

.actionButtons {
  max-width: 100%;
  width: 260px;
}
