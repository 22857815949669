@import '../../../../src/ui/style/_colors.scss';
@import '../../../../src/ui/style/typography.scss';

.Container {
  border: solid 1px $color-grey-20;
  border-radius: 0.375rem;
  background-color: #fff;
  width: 25rem;
}

.Content {
  padding: 0.625rem 0.875rem 1rem 0.875rem;
}

.Title {
  @include fontBold;
  border-bottom: solid 1px $color-grey-20;
  padding: 1rem;
  color: $color-black-100;
  display: flex;
  gap: 0.7rem;
  align-items: baseline;
}

.TitleIcon {
  font-size: 1.125rem;
}
