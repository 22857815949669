@import '../../../src/ui/style/_colors.scss';
@import '../../../src/ui/style/typography.scss';

.Container {
  border: solid 1px $color-grey-20;
  border-radius: 0.375rem;
  background-color: #fff;
  color: $color-black-100;
  width: 25rem;
}

.Title {
  border-bottom: solid 1px $color-grey-20;
  padding: 1rem;
  color: $color-black-100;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  h2 {
    flex-grow: 1;
  }
}

.TitleIcon {
  font-size: 1.125rem;
}

.Content {
  padding: 0.625rem 0.875rem 1rem 0.875rem;
}

.EditForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.InputRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}

.Input {
  width: 100%;
}

.Label {
  flex-shrink: 0;
  color: $color-black-100;
}

.Inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  & .Input {
    flex-shrink: 1;
  }
}

.NoShrink {
  flex-shrink: 0;
  white-space: nowrap;
}

.Controls,
.DoneControl {
  display: flex;
  justify-content: flex-end;
}

.SingleButton {
  width: 130px;
}

.MultiButton {
  width: 260px;
}

.DoneControl {
  margin-top: 0.875rem;
}

.List {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.IsEmpty {
  text-align: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.IndicatorList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 0 0.5rem 0;
  padding: 0;

  li {
    margin: 0;
    list-style: none;
  }
}

.IndicatorRow {
  border: 1px solid $color-grey-20;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: $color-black-100;

  &::before {
    content: ' ';
    width: 0.38rem;
    position: absolute;
    left: 0.0575rem;
    top: 0.0575rem;
    bottom: 0.0575rem;
    border-radius: 9999px;
  }

  &[data-label='warning']::before {
    background-color: $color-negative;
  }

  &[data-label='success']::before {
    background-color: $color-positive;
  }
}

.Description {
  flex-grow: 1;
  padding: 0.875rem 0.25rem 0.875rem 1rem;
}

.IndicatorRowControls {
  display: flex;
  flex-grow: 0;
  gap: 0.25rem;
  flex-direction: row;
  border-left: 1px solid $color-grey-20;
  align-self: stretch;
  padding: 0.375rem;
}

.IconButton,
.CloseModalButton {
  display: block;
  background: none;
  border: none;
  font-size: 1.125rem;
  cursor: pointer;
  color: $color-black-100;

  &:hover {
    color: $color-black-40;
  }
}

.CloseModalButton {
  flex-grow: 0;
  color: $color-black-40;

  &:hover {
    color: $color-black-100;
  }
}
