@import '../style/colors';
@import '../style/typography';

.progressBar {
  position: relative;
  display: block;
  width: 100%;
  background-color: #e0e2e5;
  border-radius: 2px;
  background-clip: padding-box;
  overflow: hidden;

  &.success {
    background-color: $color-geckoboard-green;
  }

  &.error {
    background-color: $color-negative-on-dark;
  }
}

.indeterminate {
  background-color: $color-geckoboard-green;

  &:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: transform;
    -webkit-animation: indeterminate 2.1s
      cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
      infinite;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: transform;
    -webkit-animation: indeterminate-short 2.1s
      cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
      infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
  }
}

.determinate {
  background-color: $color-geckoboard-green;
  will-change: transform;
  transition: 0.5s ease-out;
}

.progressPercentage {
  @include fontSmall;
  color: $color-black-70;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
