.text-bttn-combo-field {
  display: inline-block;
  position: relative;

  .text-field {
    padding-right: 40px;
  }

  .bttn {
    position: absolute;
    top: 1px;
    right: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
