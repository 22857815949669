@import '../../../style/typography';

.offlineBanner {
  align-items: center;
  background: #c6c6c6;
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: $medium-weight;
  height: 35px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 10;

  &:before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 3px;
    background: #ff1712;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.27);
    margin: 1PX 8px 0 0;
  }
}
