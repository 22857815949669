@import '../../style/colours';
@import '../../style/vendor/font-awesome-variables';
@import '../../src/ui/style/typography';

$widget_bg: #2a2a2a;

.container {
  $min-height: 39px;

  display: flex;
  margin-bottom: 0;
  display: flexbox;
  justify-content: space-between, end;
  overflow: visible !important;
  line-height: 17px;
  font-size: 14px;
  min-height: $min-height;
  min-height: calc(#{$min-height} * var(--scale-factor, 1));
  position: relative;
  z-index: 5;
}

:global(.draggable) .container.draggable {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.title {
  flex: 1;
  overflow: hidden;
}

.options {
  display: flex;
  align-items: center;
}

.settingsButton {
  width: 22px;
  height: 22px;
  @include variable(color, --title-color, #ffffff);
  padding: 0;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: 0;

  &:hover {
    opacity: 0.5;
  }

  transition: 0.2s ease-out;
}

.menu {
  transition: opacity 200ms cubic-bezier(0.86, 0, 0.07, 1), visibility 0s 200ms;
  opacity: 0;
  visibility: hidden;
  position: relative;
}

.open {
  .settingsButton {
    @include variable(color, --text-color, #ffffff);
  }

  .menu {
    visibility: visible;
    opacity: 1;
  }
}

.statusIndicator {
  display: none;
  padding: 0;
  text-align: center;
  text-rendering: geometricPrecision;
  line-height: 1;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  overflow: hidden;

  &,
  &:hover,
  &:visited:hover {
    color: inherit;
  }

  &::before {
    display: block;
    width: 25px;
    line-height: 25px;
    @include variable(background-color, --widget-background-color, $widget_bg);
    @include fontAwesome;
    content: fa-content($fa-var-sync);
  }

  &:active::before {
    transition: transform 0.2s;
    transform: translateX(0) rotate(120deg);
  }
}

.staleData {
  // normalize button styles
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: calc(var(--scale-factor, 1) * 100%);

  display: none;
  padding: 3px 8px 5px;
  margin: 0 5px;
  text-align: right;
  border-radius: 100px;

  color: $warning-yellow;
  @include variable(background-color, --background-color, $widget_bg);

  &::before {
    @include fontMedium;

    @include fontAwesome;
    content: fa-content($fa-var-clock);
    padding-right: 2px;
    text-rendering: geometricPrecision;
  }
}

/*
 * WIDGET STATES
 */
:global(.widget.loading) {
  .staleData {
    display: none !important;
  }
}

:global(.widget.warning) {
  .staleData {
    display: block;
  }
}

:global(.widget.failed) {
  .statusIndicator {
    display: block;
  }
}
