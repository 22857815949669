@import '../../../style/colours', '../../../src/ui/style/colors',
  '../../../src/ui/style/typography',
  '../../../style/vendor/font-awesome-variables';

$offline-banner-height: 35px;
$dashboards-button-bottom: 20px;
$ui-z-index: 10000;
$footer-height: 60px;

.offlineBanner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $ui-z-index;
}

.title {
  padding: 0 var(--widget-side-padding, 14px);
  color: var(--dashboard-text-color, $text-colour);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
  font-size: 1.5rem;
}

.contentWrapper {
  min-height: calc(100vh - #{$footer-height});
  padding-bottom: $footer-height;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.isOffline {
  padding-top: $offline-banner-height;
}

.footerWrapper {
  position: fixed;
  bottom: 0;
  z-index: 10;
  height: $footer-height; // Height of the footer
  width: 100%;
  filter: drop-shadow(0px -12px 14px rgba(0, 0, 0, 0.1));
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;

  box-sizing: border-box;

  background-color: var(--mobile-footer-background-color);
  @include fontBold;
  color: var(--mobile-footer-text-color);
}

.dashboardTitle {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100%);
  font-size: 1.5rem;
}

.menu {
  border: 0;
  padding: 0;
  background: none;
  color: var(--mobile-footer-text-color);
  cursor: pointer;
  font-size: 2.3rem;
  outline: none;
}

.emptyDashboard {
  background: $lightest-day-grey;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.emptyContent {
  margin: 130px auto 0 auto;
  width: 180px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;

  svg {
    display: block;
    margin: 0 auto 35px auto;
  }
}

.reconnectButton {
  margin: 15px 0 0 0;
}

.switchToDesktop {
  display: flex;
  justify-content: center;
}

.switchToDesktopButton {
  border-style: none;
  outline: none;
  color: var(--text-color, #e0e0e0);
  background-color: var(--widget-background-color, #2a2a2a);
  font-size: 14px;
  padding: 14px;
  border-radius: 4px;

  &::before {
    @include fontBold;
    @include fontAwesome;
    content: fa-content($fa-var-desktop);
    margin-right: 8px;
  }
}
