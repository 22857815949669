@import '../../../../src/ui/style/colors';

.input {
  font-size: 1rem;
  font-family: inherit;
  border-radius: 6px;
  border: 1px solid $color-grey-20;
  color: $color-black-100;
  padding: 0 0 0 14px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;

  &:hover {
    border-color: $color-black-70;
  }

  &:focus {
    border-color: $color-black-100;
    outline: none;
  }

  &:disabled {
    border-color: $color-grey-10;
  }
}
