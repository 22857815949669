@import '../../../../src/ui/style/colors';

.warning {
  background-color: $color-negative;
  min-width: 10px;
  margin-right: 0.5rem;

  align-self: normal;
}

.statusIndicator {
  align-items: baseline;
  flex-wrap: nowrap;
}

.inputContainer {
  display: flex;
  flex-direction: row;
}

.displayThresholdTypeBelowClassname {
  flex-direction: column;
}

.positive {
  background-color: $color-positive;
  min-width: 10px;
  margin-right: 0.5rem;

  align-self: normal;
}

.state {
  min-width: 7.5em;
  font-size: 1rem;
}

.stateLabel {
  padding-left: 1rem;
}

.thresholdType {
  width: 90px;
  font-size: 1rem;
  white-space: nowrap;
}

.duration .value {
  width: 280px;
}

.durationInput {
  width: 270px;
}

.durationInputClear {
  width: 11px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font-size: inherit;
  outline: none;
}
