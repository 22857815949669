.ConfigErrorState {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 20px;
}

.cancelButton {
  width: 110px;
  margin: 0 auto;
}
