@import "../colours";

.is-fullscreen {
  @include variable(background-color, --background-color, $dashboard_bg);


  &.idle, &.idle * {
    cursor: none!important;
  }

  .fullscreen-btn {
    display: none;
  }
}

.fullscreen-btn {
  opacity: 1;
  min-width: 42px;

  &::after {
    @include fontAwesome;
    content: fa-content($fa-var-expand);
  }
}

.idle .fullscreen-btn {
  transition: 3s opacity;
  opacity: 0;
}
