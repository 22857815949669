@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';

.list {
  margin: 0 auto;
  text-align: left;
  width: 380px;
  margin-bottom: 3rem;

  li {
    margin-bottom: 10px;
  }
}

.iconContainer {
  display: inline-block;
  text-align: center;
  width: 30px;
}

.engineWrapper {
  padding-bottom: 5px;
}

.button {
  @include fontMedium;

  align-items: center;
  border: 0;
  border-radius: 6px;
  background: $color-background-light;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  padding-top: 15px;
  transition: background 0.2s ease-in-out;
  width: 100%;
  height: 100px;

  span {
    display: block;
    font-size: 1rem;
    margin-top: 5px;
  }

  &:hover {
    background: $color-background-mid;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $color-black-100;
  }
}

.engineLogo {
  margin-bottom: 0.5rem;
}

.footer {
  margin-top: 3rem;

  a {
    @include fontMedium;
    color: $color-black-100;
    text-decoration: underline;
  }
}
