.compactConfig {
  margin-bottom: 20px;
  cursor: auto;
}

.loadingPositioner {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
}
