.DataListSortBySelector {
  .dropdowns {
    display: flex;
  }

  .dropdown {
    flex: 1;
    overflow: hidden;

    &:first-of-type {
      margin-right: 20px;
    }
  }
}
