@import "../../../../style/colours";

.colorPicker {
  background: $dark-grey;
  padding: .5rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
}

.saturation {
  position: relative;
  width: 300px;
  height: 300px;
}

.hue {
  position: relative;
  width: 30px;
  margin-left: 10px;
}

.pointer {
  margin-top: 1px;
  width: 34px;
  border-radius: 1px;
  height: 4px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px;
  background: #fff;
  transform: translateX(-2px) translateY(-2px);
}
