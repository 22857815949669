@import '../../../../../../src/ui/style/colors';

.icon {
  margin-left: 8px;
  margin-right: 12px;
}

.label {
  flex: 1;
  text-align: left;
  color: $color-black-100;
}

.meta {
  margin-right: 8px;
  color: $color-black-60;
}
