.previewItem {
  width: 100%;
  opacity: 0.8;
  transform: rotate(2deg);
}

.sortableInner {
  flex: 1;
}

:global(.isDragging) .sortableInner {
  opacity: 0.3;
}

.handle {
  display: inline-flex;
  margin-right: 0.6em;
  transform: translateY(3px);
}
