@import '../../style/colours';

.pageContainer {
  padding: 0px 0px 0px 30px;
  display: flex;
  min-height: 100vh;

  @media (max-width: 1000px) {
    padding: 0 50px 0px 30px;
  }
}
.surveySide {
  flex-grow: 1;
  margin: 0 0 0 20px;
}

.centeredContent {
  max-width: 800px;
  margin: 0 auto;
}

.survey {
  padding: 0 0 30px 50px;
}

.lovedBy {
  background-color: $color-black-100;
  text-align: center;
  vertical-align: middle;
  max-width: 500px;
  margin-left: 80px;
  padding: 90px 20px 20px 20px;
  box-sizing: border-box;
  color: #fff;

  h3 {
    line-height: 1.3;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.logoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 60px;
  align-items: center;
  column-gap: 26px;
  row-gap: 32px;

  div {
    height: 33px;

    &.loom {
      height: 34px;
    }
    &.sendCloud {
      height: 39px;
    }
    &.weTransfer {
      height: 22px;
    }
    &.zapier {
      height: 38px;
    }

    svg {
      height: 100%;
    }
  }
}

.logo {
  display: block;
  width: 150px;
  margin-bottom: 30px;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  width: 100%;
}

.buttonPositioner {
  width: 215px;
}
