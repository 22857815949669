.l-connect-service-account {

  .connect-account-icon {
    margin-bottom: 20px;
  }

  p, .form-field {
    text-align: left;
  }

  &, .form-actions {
    text-align: center;
  }

  @include mq($from: wide) {
    text-align: left;

    .connect-account-icon {
      position: absolute;
      top: 60px;
      right: 60px;
    }

    .memorable-info {
      margin: -60px -60px 2em;
      padding: 0 60px 20px;

      input[type=text] {
        border-color: #d4d4d4;
      }
    }

    .form, .form-actions {
      margin-top: 2em;
    }

    .form-actions {
      text-align: right;
    }
  }

  &.spreadsheet-service {
    .oauth-note {
      margin-top: 18px;
    }

    @include mq($from: wide) {
      .first-connection {
        margin-top: 84px;
      }
      .oauth-note {
        float: left;
      }
      .btn-connect-spr {
        float: right;
      }
    }
  }
}
