@import '../../../../../src/ui/style/colors';

.FilterRules {
  border-top: solid 1px $color-grey-20;
  padding: 0;
  margin: 0;
  list-style: none;
}

.FilterRule {
  display: flex;
  line-height: 1.2;
  text-align: left;
  color: $color-black-100;
  transition: background 0.2s;
  align-content: center;
  align-items: stretch;
  border-bottom: solid 1px $color-grey-20;

  &:last-child {
    border-bottom: 0;
  }
}

.FilterContent {
  appearance: none;
  background: none;
  border: 0;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0.5rem 1rem;
  font-size: 16px;
  flex-grow: 2;
  cursor: pointer;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 51px);

  &:hover {
    background: $color-background-mid;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    padding-right: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.FilterDelete {
  appearance: none;
  background: none;
  border: 0;
  padding: 12px 18px;
  border-left: solid 1px $color-grey-20;
  line-height: 1.2;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: $color-background-mid;
  }
}

.FilterShimmer {
  width: 100%;
  height: 40px;
  background: $color-background-mid;

  > div {
    width: 100%;
    height: 100%;
  }
}
