@import '../../../../style/vendor/font-awesome-variables';
@import '../../../../style/colours';
@import '../../../../src/ui/style/colors';

.label {
  text-align: left;
  padding: 0 2em 0 1em;
  height: 2.25rem;
  border-radius: 6px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &::after {
    pointer-events: none;
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
    line-height: 14px;
  }

  &.standard-dropdown {
    background: #ffffff;
    color: $color-black-100;
    padding-left: 0.6em;
    border: 1px solid $color-background-dark;
  }

  &.small-dropdown {
    background-color: $btn-secondary-background-colour;
    color: $btn-secondary-colour;
    font-size: 0.875rem;
    padding-left: 0.6em;
    height: 2rem;
  }

  &.light {
    background: #ffffff;
  }

  &.dark {
    background: $secondary-grey;
  }

  &.hover-transparent {
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
