@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.data-source-connector {
  @include bttn;
  @include fontLight;
  @import '../shared/form-item';

  $whitespace-unit: 16px;
  $whitespace-s: calc($whitespace-unit / 2); // 8px
  $whitespace-m: $whitespace-unit; // 16px
  $whitespace-l: $whitespace-unit * 2; // 32px
  $whitespace-xl: $whitespace-unit * 4; // 64px

  overflow-x: hidden;
  height: 100%;

  .form-item {
    min-height: 67px;
    margin-bottom: 10px;

    @include mq($from: desktop) {
      margin-bottom: 0;
    }
  }

  .memorable-info {
    margin: 0 -60px 0;
    padding: 0 60px $whitespace-s;

    input[type='text'] {
      border-color: $gray-athens;
    }
  }

  legend {
    margin-bottom: $whitespace-m;
  }

  fieldset > p:first-child {
    margin-top: 2em;
  }

  &,
  .form-actions {
    text-align: center;
  }

  @include mq($from: tablet) {
    form,
    .data-source-connector-footer {
      text-align: left;
    }
  }

  @include mq($from: desktop) {
    .form-actions {
      text-align: right;
      margin-top: 0;
      float: right;
    }
  }

  .import-help-text {
    padding-bottom: 3px;
    display: inline;
  }
}
