.image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  pointer-events: none;
}

.placeholderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholderContainer svg {
  width: 50%;
  height: 50%;

  path, circle {
    fill: var(--text-color);
    opacity: 0.5;
  }
}
