:global {
  #footer-overlay {
    .modal {
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      z-index: 999999;
      margin-top: -20px;
      transition: opacity 100ms ease-in;
    }
    
    .footer-overlay-enter {
      opacity: 0.01;
      transition: opacity 100ms ease-in;
    }

    .footer-overlay-enter.footer-overlay-enter-active {
      opacity: 1;
    }

    .footer-overlay-exit {
      opacity: 0.01;
      transition: opacity 100ms ease-in;
    }

    .footer-overlay-exit.footer-overlay-exit-active {
      opacity: 0.01;
    }
  }
}
