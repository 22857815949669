@import '../../../src/ui/style/colors', '../../../src/ui/style/typography',
  '../../../style/vendor/font-awesome-variables',
  '../../components/menu/drop-menu/animation';

.fileTitle {
  line-height: 48px !important;
  width: 100%;
  padding-right: 30px !important;

  &:before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
    color: $color-black-100;
    position: absolute;
    right: 10px;
  }
}

.dropMenu {
  @include fontLight;
  min-width: 0; // needed for flexbox
  flex-shrink: 1;

  @include animateDropMenu(top right);
}

.dropList {
  border: 1px solid $color-background-dark;
  left: auto !important;
  right: 0;
}
