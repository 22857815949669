.timezoneSettings {
  margin: 20px 0 0 0;
}

.dropdownsContainer {
  display: flex;
  margin: 9px 0 0 0;
  line-height: 1.3;

  & > div {
    flex: 1 1 0;
  }
  & > *:first-child {
    margin-right: 5px;
  }
  & > *:last-child {
    margin-left: 5px;
  }
}
