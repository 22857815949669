@keyframes appearsIn {
    0%   { opacity: 0; top: 20px; }
    100% { opacity: 1; top: 7px; }
}

.container {
  position: absolute;
  top: 4px;
  right: 0;
  padding: 6px 2px;
  display: flex;
  background: white;
  transform: translateY(-100%);
  border-radius: 6px 6px 0 0;
  animation: appearsIn 325ms;

  button {
    margin: 0 4px;
  }
}

