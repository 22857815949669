@import '../../../../style/colours';
@import '../../../../src/ui/style/typography';

.presetPanelLayout {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
}

.scrollHidden {
  overflow: hidden;
}

.presetPanelHeader {
  @include fontMedium;

  display: flex;
  align-items: center;

  background-color: #fff;
  font-size: 1.25rem;
  padding: 20px;
  border-bottom: 1px solid $pinstripe-grey;
}

.logo {
  margin-right: 1rem;
}

.loadingOverlay {
  background-color: $body-background;
  opacity: 0.4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
