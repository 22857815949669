.field {
  margin-bottom: 14px;
}

/* Two fields sitting side by side with appropriate space between */
.pair {
  display: flex;

  .field {
    margin-right: 10px;
    margin-bottom: 0;
    max-width: 50%;
    flex: 1;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
