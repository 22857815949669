@import '../../../../src/ui/style/colors';

.leftInRow {
  flex: 1;
  margin-right: 10px;
}

.rightInRow {
  flex: 0;
}

.unitInput {
  width: 60px;

  & input {
    height: 36px;
  }

  & input::placeholder {
    color: $color-grey-20;
  }
}

.abbreviationButtonSpacer {
  padding: 0 12px;
}
