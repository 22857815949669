@import '../../../../../src/ui/style/colors';

.filterConfig {
  position: relative;
  padding: 14px 20px;
  border-radius: 6px;
  border: solid 2px $color-black-100;
  background-color: #ffffff;

  header {
    display: flex;
    margin: 0 0 18px 0;

    h2 {
      margin: 0 0 0 10px;
      flex-grow: 1;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0 0 0 15px;
      width: 124px;
    }
  }
}

.closeButton {
  color: $color-black-60;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  outline: none;
}

.dropdown {
  position: relative;
  margin: 0 0 14px 0;
}
