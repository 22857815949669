@import '../../../style/vendor/_font-awesome-variables';

.widgetImporting {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;
  font-size: 1.25rem;
  opacity: 0.5;

  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-truck);
    font-size: 52px;
    margin-bottom: 12px;
    transform: scaleX(-1);
  }
}
