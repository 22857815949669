.paddingFlexLayout {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  padding: 1rem;
}

.filterbarFlexLayout {
  flex: 0 0 auto;
  margin: -.5rem -2rem .5rem;
}

.previewContainer {
  flex: 1;
}
