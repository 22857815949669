.errorMessage {
  padding-bottom: 15px;
}

.feedbackSection {
  margin-top: 25px;
}

.form {
  margin-top: 20px;

  label {
    font: inherit;
  }

  textarea {
    min-width: 98%;
    resize: vertical;
  }
}

.warningList {
  list-style: disc;
  padding-left: 10px;
  padding-bottom: 5px;
}
