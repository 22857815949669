@import '../layout', 'text-field', 'text-bttn-combo-field',
  'masthead-dashboard-sharing', 'masthead-dashboard-options',
  '../../src/ui/style/colors';

.masthead {
  transition: 0.1s transform ease-out;
  @include typographySmall;
  @include bttn;

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: $dark-grey;
  color: #fff;

  min-width: $page-min-width;

  &,
  * {
    box-sizing: border-box;
  }
}

.masthead-navigation {
  position: relative;
  background-color: #ffffff;
  border-bottom: solid 1px $color-background-dark;
  color: $color-black-100;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: 1rem;

  > span {
    align-self: center;
    display: flex;
  }
}

.masthead-dashboard-picker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  // override main ul positioning to fix handleResize
  // from DashboardPicker offscreen.
  &.drop-menu-picker {
    & > ul {
      transition: 450ms transform $easeOutQuint;
      transform: translateX(-100%);
      left: 0;
    }

    &.open > ul {
      transform: translateX(0);
    }
  }
}

.masthead-title-wrapper {
  display: flex;
  margin: auto 0;
  align-items: center;

  // The menu items on the right take up a little less
  // than 500px, so we're leaving some space for them
  max-width: calc(100% - 500px);

  @include mq($from: desktop) {
    max-width: calc(100% - 550px);
  }
}

.masthead-title {
  margin: 2px 0 0;
  max-width: 400px;
  padding: 0 20px;
  font-size: 1rem;
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.masthead-dashboard-options {
  align-self: flex-end;
}

.masthead-menu {
  flex: 0 0 auto;
  align-self: flex-end;
}

.masthead-dashboard-options {
  .drop-menu,
  > .bttn {
    margin-left: 0px;

    @include mq($from: desktop) {
      margin-left: 40px;
    }
  }
}

.masthead-widget-wizard {
  line-height: 48px;
  vertical-align: top;
}
