@import '../../style/colors', '../../style/typography';

.uploadContainer {
  background-color: white;
  border-radius: 0.625rem;
  border: 2px dotted #dfe4ee;
}

.fileUpload {
  position: relative;
  width: auto;
  height: 110px;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-out background-color;
  outline: none;
}

.fileUploadWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span:first-child {
    transition: 0.1s ease-out opacity;
    font-size: 2.25rem;
    color: $color-black-70;
    margin-bottom: 0.5rem;
  }
}

.fileDropNote {
  font-size: 1.25rem;
  color: $color-black-70;
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    font-size: 1.25rem;
    padding: 0;
    color: $color-black-70;

    &:focus {
      box-shadow: none;
    }
  }
}

.fileUploadNote {
  @include fontLight;
  color: $color-black-100;
  font-size: 0.9rem;
}

.fileDragActive {
  background-color: rgba($color-grey-120, 0.1);
  font-size: 1.25rem;
}

.loader {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.filePreview {
  display: flex;

  img {
    max-width: 100%;
    max-height: 100px;
    transition: 0.1s ease-out filter;

    &.isLoading {
      filter: grayscale(100%) blur(1px) opacity(0.5);
    }
  }
}
