@import '../../../../../../src/ui/style/colors';
@import '../../../../../../src/ui/style/typography';

.numberComparisonCard {
  border-radius: 6px;
  background: $color-background-mid;
  padding: 1rem;

  .inputWithLabel {
    display: flex;

    label {
      flex: 1;
      margin-top: 7px;
      color: $color-grey-100;
    }

    .mainInputLabel {
      @include fontMedium;
    }

    .input {
      flex: 2;
    }
  }
}
