@import '../../../../../style/colours', '../../../../../src/ui/style/colors',
  '../../../../../style/vendor/font-awesome-variables';

.value {
  display: inline-block;
  white-space: nowrap;
}

.indicator::before {
  display: inline-block;
  margin-right: 0.5em;
  content: '';
}

.increased {
  @include variable(color, --positive-color, $color-positive);

  .indicator::before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-up);
    font-weight: $fa-weight-solid;
  }
}

.decreased {
  @include variable(color, --negative-color, $color-negative);

  .indicator::before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-down);
    font-weight: $fa-weight-solid;
  }
}

.unchanged {
  @include variable(color, --caution-color, $color-warning);

  .indicator::before {
    @include fontAwesome;
    content: fa-content($fa-var-caret-right);
    font-weight: $fa-weight-solid;
  }
}

.blankComparison {
  .indicator::before {
    content: '–';
  }
}

.reversed {
  &.decreased {
    @include variable(color, --positive-color, $color-positive);
  }

  &.increased {
    @include variable(color, --negative-color, $color-negative);
  }
}
