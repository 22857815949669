.masthead-dashboard-sharing {
  > ul {
    top: calc(100% + 0.5rem);
    min-width: 445px;
    font-weight: 300;
  }

  li {
    display: block;
    padding: 20px;

    border-bottom: 1px solid rgba(0,0,0,0.1);

    &:last-child {
      border: none;
    }
  }

  strong {
    display: inline-block;
    width: 350px;
  }

  .text-field, .text-bttn-combo-field {
    width: 100%;
  }

  .text-bttn-combo-field {
    .bttn-dull {
      margin: 4px;

      i {
        pointer-events: none;
      }
    }
  }
}

.masthead-dashboard-sharing-url.disabled {
  color: #999;
}

.masthead-dashboard-sharing-toggle strong {
  font-size: 18px;
  line-height: 20px;
  vertical-align: bottom;
}
