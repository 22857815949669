@import '../../../../../src/ui/style/colors';

.ColumnList {
  padding-bottom: 10px;
}

.columnListItem {
  margin: 0 0 12px;
}

.column {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  padding: 0.5rem;
  background: $color-background-mid;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.6;
  cursor: grab;

  &.dragged {
    opacity: 0.9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(-1px);
  }

  :global(.isDragging) & {
    opacity: 0;
  }
}

.displayName {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0.25rem;

  i {
    margin-right: 10px;
  }
}

.deleteButton {
  appearance: none;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 4px;
  width: 2rem;

  &:hover {
    background: darken($color-background-dark, 5%);
  }
}
