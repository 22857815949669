@import '../../../style/colours';

.image {
  display: block;
  margin: 2.5rem auto 2rem auto;
  width: 380px;
  max-width: 100%;
}

.instructionContainer {
  padding: 1rem;
  border-radius: 5px;
  background-color: $light-grey;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
