@import '../../../src/ui/style/colors', '../../../style/colours';

.dropMenu {
  width: 100%;
}

.menuLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.list {
  background: #fff;
  border-radius: 6px;
  overflow-y: auto;
  margin-top: 1px;
  width: 100%;
  padding: 0.5rem 0;
}

.dropItem {
  background-color: #fff;
}

.innerWrapper {
  color: $color-black-100;
  padding: 6px 20px;
  white-space: normal;

  &:hover,
  &:focus {
    background-color: $light-grey !important;
  }
}

.innerIcon {
  width: 20px;
  padding-left: 5px;
}

.showMore {
  cursor: pointer;
  padding: 6px 20px;
  background-color: #fff;
  color: $mid-grey;
  font-size: 1rem;
  transition: 0.1s background-color ease-in-out;

  &:hover,
  &:focus {
    background-color: $light-grey !important;
  }
}

.noResultsMessage {
  padding: 4px 16px;
  opacity: 0.6;
  color: $text-grey;
}
