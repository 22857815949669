@import "../../../style/_colours.scss";

.container {
  background: $secondary-grey;
  color: $text-grey;
  font-size: 16px;
  height: 190px;
  line-height: 22px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 0 0;
  box-sizing: border-box;

  svg {
    height: 33px;
    width: 43px;
  }
}

.offlineText {
  margin: 14px auto 21px auto;
  width: 210px;
}
