@import "../../../../style/colours";
@import "../../../../style/typography";

.dashboardFooter {
  @include fontLegacy; // Our old font Aktiv Grotesk is still used for fixieboards
  transition: 0.2s linear background-color;
  // This component gets 'display: flex' from gecko-js, so we can positions it's children knowing that
  box-sizing: border-box;
  height: 98px;
  padding: 5px;
  @include variable(background-color, --background-color, #101214);
  @include variable(color, --dashboard-text-color, $text-colour);
  justify-content: space-between;
  align-items: center;

  a {
    border: none;
  }

  &.visible {
    display: flex;
  }
}

.portraitSupport {
  @media (orientation: portrait) {
    width: 1080px; // Width of a portrait 1080p display
  }
}

.absolute {
  position: absolute;
  bottom: 0;
}

.right, .left, .logoContainer {
  align-items: center;
  display: flex;
}

.logo {
  padding-right: 1rem;

  img {
    max-height: 50px;
    max-width: 400px;
  }
}

.title {
  font-size: 36px;
  font-weight: 500;
  padding-left: 1rem;
}

.brandingText {
  font-size: 1.125rem;
  margin-right: 1rem;
}

.brandingTextDarken {
  opacity: 0.5;
}

.footerClock {
  font-size: 36px;
  font-weight: 500;
  opacity: 0.5;
  padding-left: 1rem;
  padding-right: 1rem;
}

.brandLogo {
  width: 50px;
  height: 50px;

  &:hover {
    opacity: 0.6;
  }
}

.plan {
  &:after {
    content: '|';
    display: inline-block;
    margin-left: 15px;
  }
}
