@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

.popover {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 10002;

  cursor: pointer;

  &::after {
    transition: opacity 0.1s ease-out;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;

    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
  }

  .container {
    position: fixed;
    bottom: 0;
    z-index: 2001;
    width: 100%;
    top: 0;
    left: 0;
  }

  .scroller {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
  }

  .content {
    @include fontLight;

    display: flex;
    flex-direction: column;
    z-index: 2;
    max-width: 95vw;
    max-height: 93vh;
    position: relative;

    background-color: #ffffff;
    color: $color-black-100;
    border-radius: 6px;
    overflow: hidden;

    cursor: default;

    &:global(.content-enter),
    &:global(.content-appear) {
      transform: scale(0.8);
    }

    &:global(.content-enter-active),
    &:global(.content-appear-active) {
      transform: scale(1);
      transition-duration: 0.1s;
      transition-property: transform;
      transition-timing-function: ease-out;
    }

    &:global(.content-exit) {
      transform: scale(1);
    }

    &:global(.content-exit-active) {
      transform: scale(0);
      transition-duration: 0.2s;
      transition-property: transform;
      transition-timing-function: ease-out;
    }
  }

  .flexContainer {
    display: flex;
    height: 100%;
    overflow: auto;
    align-items: center;
    justify-content: center;
  }

  &:global(.popover-enter),
  &:global(.popover-appear) {
    opacity: 0;
  }

  &:global(.popover-enter-active),
  &:global(.popover-appear-active) {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-out;
    opacity: 1;
  }

  &:global(.popover-exit) {
    opacity: 1;
  }

  &:global(.popover-exit-active) {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-out;
    opacity: 0;
  }
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  appearance: none;
  border: none;
  background: #fff;
  font-size: 1.125rem;
  color: $color-black-100;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    background: $color-background-mid;
    outline: none;
  }
}

.section {
  max-width: 592px;
  padding: 2rem 3rem;
  box-sizing: border-box;

  line-height: 1.5;
}

.heading {
  @include fontBold;

  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
}

.text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
}
