@import "../../../style/colours.scss";

.handleWrapper {
  margin-bottom: -10px;
  margin-right: -10px;
  padding: 16px;

  cursor: nwse-resize;
}

.handle {
  position: relative;
  width: 24px;
  height: 24px;

  &:after, &:before {
    position: absolute;
    width: 0;
    height: 0;

    transform: rotate(45deg);
    border-style: solid;
    border-color: transparent;
    content: '';
  }

  &:before {
    top: 8px;
    left: 8px;

    border-width: 17px;
    @include variable(border-left-color, --widget-background-color, #2a2a2a);
  }

  &:after {
    top: 9px;
    left: 9px;

    border-width: 15px;
    @include variable(border-left-color, --text-color, $text-colour);
  }
}
