@import '../../../../src/ui/style/colors';
@import '../../../../style/vendor/font-awesome-variables';

.selectWrapper {
  position: relative;
}

.icon {
  @include fontAwesome;
  content: fa-content($fa-var-caret-down);
  font-size: 0.85rem;
  color: $color-black-100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 100%;
  height: 2.25rem;
  vertical-align: middle;
  background-color: #fff;
  cursor: pointer;
  color: $color-black-100;
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.4;
  padding: 0 2rem 0 0.5rem;

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
    border-color: $color-black-100;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.inherit {
  color: var(--text-color);

  .icon {
    color: var(--text-color);
  }
  .select {
    background-color: inherit;
    color: var(--text-color);
    border-color: var(--text-color);

    &:focus {
      outline: 1px dotted var(--text-color);
    }

    option {
      color: $color-black-100;
    }
  }
}

.isFullWidth {
  width: 100%;
}

.validation-error {
  border-color: $color-negative;
}

.asterisk {
  font-size: 0.5rem;
  color: $color-black-100;
  position: absolute;
  top: 0;
  right: 1rem;
  bottom: 0;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}
