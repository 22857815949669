@import "../../../style/colours";

.item {
  display: flex;
  flex: 1;
  margin: -0.5rem;
  cursor: grab;

  &:hover .handle {
    opacity: 1;
  }
}

.sortable {
  display: flex;
  flex: 1;
}

.innerItem {
  padding: 0.5rem;
  display: flex;
  flex: 1;
}

:global(.isDragging) .innerItem {
  background-color: $light-grey;

  * {
    visibility: hidden;
  }
}

.previewItem {
  width: 100%;
  box-shadow: 0 2px 4px rgba(0,0,0,.05);
  border-radius: 3px;
  opacity: 0.75;
  transform: none;
}
