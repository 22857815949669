/*
A row of form buttons

@deprecated .button-row, this is for compatibility with the old DynamicForm
*/
.form-actions, .button-row {
  max-width: 960px;

  text-align: right;

  .btn {
    margin-left: 20px;
  }
}
