@import 'variables';
@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';
@import '../../style/vendor/font-awesome-variables';

.drop-menu {
  position: relative;
  display: inline-block;
  outline: none;
  vertical-align: middle;

  .drop-menu-toggle,
  .drop-menu-control,
  h3 {
    display: block;
    margin: 0;

    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;

    font: inherit;
    text-decoration: none;
    outline: none;
  }

  .drop-menu-control,
  h3 {
    padding: 13px 20px;
  }

  .drop-menu-toggle {
    @include animateButtonFade;

    &:hover {
      opacity: 0.6;
    }
  }

  .drop-menu-toggle.fa::before {
    display: inline-block;
    @include fontAwesome;
    margin-right: 6px;
  }

  ul {
    padding: 0;
    margin: 0;

    list-style: none;

    color: $text-grey;
  }

  > ul {
    height: 0;
    position: absolute;
    z-index: 9999;
    left: 0;
    overflow: hidden;

    opacity: 0;
  }

  .drop-menu-control,
  h3 {
    white-space: nowrap;
    line-height: 1.8;

    color: $text-grey;
  }

  .drop-menu-control {
    @include fontLight;
    transition: 0.1s background-color ease-in-out;

    width: 100%;

    text-align: left;

    &:hover,
    &:focus {
      background-color: rgba($color-grey-10, 0.5);
      color: inherit;
    }

    &:active {
      background-color: darken($lightest-day-grey, 4%);
    }
  }

  label {
    cursor: pointer;

    strong {
      font-weight: inherit;
    }
  }

  h3 {
    margin-top: -1px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-top: 1px solid $pinstripe-grey;
    color: #8b9092;
    font-size: 0.8rem;
    font-weight: 600;
  }

  &.open > ul {
    height: auto;

    opacity: 1;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $drop-menu-border-colour;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
}
