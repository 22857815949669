@import "../../../../style/gecko-font/variables";

.icon {
  max-width: 100%;
}

.isSortable {
  cursor: pointer;

  &.right {
    &:hover {
      .icon::before {
        display: initial;
      }
    }

    .icon::before {
      font-family: GeckoFont;
      font-size: 1.25rem;
      position: absolute;
      display: none;
      margin-top: 0.15em;
      content: $gecko-var-sort;

      margin-left: -1em;
    }

    .asc::before {
      display: initial;
      content: $gecko-var-sort-up;
    }

    .desc::before {
      display: initial;
      content: $gecko-var-sort-down;
    }
  }

  &.left {
    &:hover {
      .icon::after {
        display: initial;
      }
    }

    .icon::after {
      font-family: GeckoFont;
      font-size: 1.25rem;
      position: absolute;
      display: none;
      margin-top: 0.15em;
      content: $gecko-var-sort;
    }

    .asc::after {
      display: initial;
      content: $gecko-var-sort-up;
    }

    .desc::after {
      display: initial;
      content: $gecko-var-sort-down;
    }
  }
}
