.container {
  display: flex;
}

.requiredFilter {
  flex-grow: 1;
  display: flex;
}

.dropdown {
  width: 100%;
}
