.shimmer {
  position: relative;
}
 .shimmerEffect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
   background-image: linear-gradient(90deg, rgba(255,255,255,0) 0px, #FFF 80px, rgba(255,255,255,0) 160px);
  background-repeat: no-repeat;
  animation: shimmer 1.5s linear infinite;
}
 @keyframes shimmer {
  0% { 
    background-position: -200px; 
    opacity: 1;
  }
  40%, 100% { 
    background-position: 550px; 
    opacity: 0;
  }
}
