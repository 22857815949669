@import "../../style/colours";

.loadAnimationContainer {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
}

.loaderBar {
  width: 2px;
  height: 100%;
  @include variable(background, --text-color, $text-colour);

  transform-origin: bottom;
  animation-duration: 0.8s;
  animation-name: bartransition;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;

  &:nth-child(even) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.5s;
  }

  &:nth-child(5) {
    animation-delay: 0.1s;
  }
}

@keyframes bartransition {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0.2);
  }
}
