@import "vendor/reset";
@include global-reset;
@import "typography";

a:link,
a:visited {
  text-decoration: none;
}

body {
  @include baseFont(16px);

  &.faulty-text-rendering {
    @include baseFont(16px, true);
  }

  .heading {
    font-size: 22px;
    font-weight: $regular-weight;
    line-height: 1.5;
  }

  .sub-heading {
    margin-bottom: 10px;

    font-size: inherit;
    font-weight: $regular-weight;
    line-height: 1.5;

    .fa {
      margin-right: 2px;

      vertical-align: middle;
    }
  }

  .sub-sub-heading {
    font-size: inherit;
    font-weight: $regular-weight;
  }

  p {
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  legend {
    font-weight: $light-weight;
  }

  strong {
    font-weight: $bold-weight;
  }

  em {
    font-style: italic;
    font-weight: $light-weight;
  }
}

#app {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

// Make sure that Gecko-JS views are visible
// in the account section.
.account-page #app {
  height: auto;
}

@import "gecko-font/gecko-font",
"font/visuelt/include",
"vendor/font-awesome-variables",
"mq",
"vendor/mq",
"integrations-page/index",
"colours",
"widget",
"shared/index",
"menu/index",
"masthead/index",
"dashboard/index",
"visualisation/index",
"spreadsheet-config/index",
"data-source-connector/index";
