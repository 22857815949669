@import './font-awesome-variables';
@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

$chosen-highlight-colour: darken($color-geckoboard-green, 10%);

/* @group Base */
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: inherit;
  zoom: 1;
  *display: inline;
  min-height: 40px;
  user-select: none;

  .chosen-drop {
    position: absolute;
    top: 100%;
    left: -9999px;
    padding-top: 4px;
    z-index: 1010;
    box-sizing: border-box;
    width: 100%;
    border-top: 0;
    background: #fff;
    box-shadow: 0 4px 5px rgba(#000, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.chosen-with-drop .chosen-drop {
    left: 0;
  }

  a {
    cursor: pointer;
  }
}
/* @end */

/* @group Single Chosen */
.chosen-container-single {
  .chosen-single {
    box-sizing: border-box;
    position: relative;
    display: block;
    overflow: hidden;
    padding: 7px 0 7px 8px;
    height: 40px;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    background-color: #fff;
    background-clip: padding-box;
    color: $color-grey-100;
    text-decoration: none;
    white-space: nowrap;
    line-height: 24px;
  }

  .chosen-default {
    color: #999;
  }

  .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .chosen-single-with-deselect span {
    margin-right: 38px;
  }

  .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;

    &:hover {
      background-position: -42px -10px;
    }
  }

  &.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
  }

  .chosen-single div {
    position: absolute;
    top: 10px;
    right: 4px;
    display: block;
    width: 18px;
    height: 18px;
    line-height: 12px;
    text-align: center;

    &::after {
      font-weight: $fa-weight-solid;
      @include fontAwesome;
      content: fa-content($fa-var-sort-down);
    }
  }

  .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap;

    input[type='text'] {
      box-sizing: border-box;
      margin: 1px 0;
      padding: 4px 20px 4px 10px;
      width: 100%;
      height: auto;
      outline: 0;
      border: 1px solid #ccc;
      font-size: 1em;
      line-height: normal;
      border-radius: 4px;
    }

    &::after {
      position: absolute;
      top: 4px;
      right: 10px;
      font-weight: $fa-weight-solid;
      @include fontAwesome;
      content: fa-content($fa-var-search);
      color: lighten(#3a3a3a, 30%);
    }
  }

  .chosen-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
  }

  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
  }
}
/* @end */

/* @group Results */
.chosen-container .chosen-results {
  color: $color-grey-100;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 0 0 0 4px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;

  li {
    display: none;
    margin: 0;
    padding: 5px 11px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
    border-radius: 2px;

    &.active-result {
      display: list-item;
      cursor: pointer;
    }

    &.disabled-result {
      display: list-item;
      color: #ccc;
      cursor: default;
    }

    &.highlighted {
      background-color: $color-black-100;
      color: #fff;
    }

    &.no-results {
      color: #777;
      display: list-item;
      background: #f4f4f4;
    }

    &.group-result {
      @include fontBold;
      display: list-item;
      cursor: default;
    }

    &.group-option {
      padding-left: 20px;
    }

    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
}
/* @end */

/* @group Multi Chosen */
.chosen-container-multi {
  .chosen-choices {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    height: auto !important;
    height: 1%;
    border: 1px solid #aaa;
    background-color: #fff;
    cursor: text;
  }

  .chosen-choices li {
    float: left;
    list-style: none;

    &.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      height: 28px;
      input[type='text'] {
        margin: 1px 0;
        padding: 5px 6px;
        height: 15px;
        outline: 0;
        border: 0 !important;
        background: transparent !important;
        box-shadow: none;
        box-sizing: content-box;
        color: #999;
        font-size: 100%;
        line-height: normal;
        border-radius: 0;
      }
    }

    &.search-choice {
      position: relative;
      margin: 3px 5px 3px 0;
      padding: 4px 20px 4px 5px;
      max-width: 100%;
      box-sizing: border-box;
      border-radius: 7.5px;
      background-color: #eeeeee;
      background-repeat: repeat-x;
      background-clip: padding-box;
      color: #333;
      line-height: 13px;
      cursor: default;

      span {
        word-wrap: break-word;
      }

      .search-choice-close {
        position: absolute;
        top: 4px;
        right: 3px;
        display: block;
        width: 12px;
        height: 12px;

        &::after {
          font-size: 80%;
          font-weight: $fa-weight-solid;
          @include fontAwesome;
          content: fa-content($fa-var-times);
          opacity: 0.5;
        }

        &:hover::after {
          opacity: 1;
        }
      }
    }

    &.search-choice-disabled {
      padding-right: 5px;
      border: 1px solid #ccc;
      background-color: #e4e4e4;
      color: #666;
    }

    &.search-choice-focus {
      background: #d4d4d4;
      .search-choice-close {
        background-position: -42px -10px;
      }
    }
  }

  .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
  }
}
/* @end */

/* @group Active  */
.chosen-container-active {
  .chosen-single {
    border-color: #7a7a7a;
  }

  &.chosen-with-drop {
    .chosen-single {
      -moz-border-radius-bottomright: 0;
      border-bottom-right-radius: 0;
      -moz-border-radius-bottomleft: 0;
      border-bottom-left-radius: 0;
    }

    .chosen-single div {
      transform: rotate(180deg);
    }
  }

  .chosen-choices {
    li.search-field input[type='text'] {
      color: #222 !important;
    }
  }
}
/* @end */

/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;

  .chosen-single {
    cursor: default;
  }

  .chosen-choices .search-choice .search-choice-close {
    cursor: default;
  }
}
/* @end */
