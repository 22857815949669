@import '../../../src/ui/style/colors', '../../../src/ui/style/typography',
  '../../../style/shared/animations',
  '../../../style/vendor/font-awesome-variables';

.masthead {
  @include fontBold;

  flex: 1;
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 6;
}

.widgetMasthead {
  height: 48px;
  background: #ffffff;
  border-bottom: 1px solid $color-background-dark;
  display: flex;
}

.basicCrumb {
  @include fontBold;
  line-height: 48px;
  max-width: 500px;
  padding: 0 0 0 16px;
  overflow: hidden;
  font-size: 1rem;
  border: 0;
  color: $color-black-100;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;

  transition: 300ms color $easeOutQuint;

  &:hover {
    color: $color-grey-80;
  }

  &:link {
    border: 0;
  }
}

.crumb {
  composes: basicCrumb;

  &:after {
    @include fontAwesome;
    content: fa-content($fa-var-angle-right);
    font-weight: normal;
    margin-left: 15px;
    color: $color-black-100;
  }

  &:last-child {
    &:after {
      content: none;
      margin: 0;
    }
  }
}
