@import '../../../../src/ui/style/colors';

.item {
  display: block;
  padding: 20px;

  &:hover {
    background-color: $color-background-dark;

    .icon {
      color: $color-black-100;
    }
  }
}

.selected {
  outline: 2px solid;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 1rem;
}

.subtitle {
  margin-top: 0.5rem;
  opacity: 0.7;
}

.icon {
  color: $color-black-70;
}
